import React, { useEffect, useState } from 'react';
import Meta from 'antd/es/card/Meta';
import { Button, Card, Row, Col } from 'antd';
import { useGetProductsForBookingQuery } from '../../../graphql/schema';
import Loading from '../../../components/base/Loading';
import formatAmount from '../../../helper/formatAmount';
import { PageProps } from '../lib/pageProps';
import { Page } from '../lib/pageEnum';

interface ProductCategories {
  [key: string]: {
    name: string;
    products: {
      id: number;
      title: string;
      image: string;
      description?: string | null;
      price: number;
    }[];
  };
}

const ProductPage = ({ navigate, setState, state }: PageProps) => {
  const { loading, data } = useGetProductsForBookingQuery();

  const [productCategoryFilter, setProductCategoryFilter] = useState<string>();

  const handleNext = (productId: number) => {
    return () => {
      setState({
        productId,
      });
      setTimeout(() => {
        const widgets = document.getElementsByTagName('dts-widget');
        if (widgets.length > 0) {
          widgets[0].scrollIntoView();
        }
      }, 100);
      if (state.customer?.id) {
        navigate(Page.attendance);
      } else {
        navigate(Page.email);
      }
    };
  };

  useEffect(() => {
    // Product selected via URL
    const productIdMatch = window.location.search.match(/productId=([0-9]+)/);
    const productId = productIdMatch ? parseInt(productIdMatch[1], 10) : null;

    if (productId) {
      handleNext(productId)();
      return;
    }

    // Product category selected via URL
    const productCategoryMatch = window.location.search.match(/category=([A-Za-z\-_0-9]+)/);
    const productCategory = productCategoryMatch ? productCategoryMatch[1] : null;

    if (productCategory) {
      setProductCategoryFilter(productCategory);
    }
  }, []);

  if (loading || !data) return <Loading />;

  const products = data.publicProductsForBooking
    .filter((product) => {
      if (!productCategoryFilter) return true;

      return product.category && product.category.uid === productCategoryFilter;
    })
    .sort((a, b) => {
      return a.price - b.price;
    });

  const categories: ProductCategories = {};

  products.forEach((product) => {
    const categoryUid = product.category?.uid || 'undefined';
    const categoryName = product.category?.name || '';

    if (!categories[categoryUid])
      categories[categoryUid] = {
        name: categoryName,
        products: [],
      };

    categories[categoryUid].products.push(product);
  });

  return (
    <div>
      {Object.keys(categories).map((categoryUid) => {
        const category = categories[categoryUid];

        return (
          <div key={categoryUid}>
            <h2>{category.name}</h2>
            <Row
              style={{
                marginLeft: -10,
                marginRight: -10,
                marginBottom: 25,
              }}
            >
              {category.products.map((product) => (
                <Col key={product.id} xs={24} sm={12} md={8}>
                  <Card
                    cover={<img alt={product.title} src={product.image} />}
                    style={{
                      margin: 10,
                    }}
                  >
                    <Meta title={product.title} description={product.description} />
                    <div
                      style={{
                        fontSize: 20,
                        paddingTop: 10,
                      }}
                    >
                      {formatAmount(product.price)}
                    </div>
                    <div>
                      <Button
                        type="primary"
                        style={{
                          width: '100%',
                          marginTop: 10,
                        }}
                        onClick={handleNext(product.id)}
                      >
                        Jetzt buchen
                      </Button>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        );
      })}
    </div>
  );
};
export default ProductPage;
