import React, { useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button, Card, List, Space, Tabs, message, Empty } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faTrash, faCashRegister } from '@fortawesome/pro-light-svg-icons';
import GenericForm from '../components/form/GenericForm';
import {
  useDeleteInstructorAvailabilityMutation,
  useDeleteInstructorMutation,
  useGetInstructorQuery,
  useUpdateInstructorMutation,
} from '../graphql/schema';
import Loading from '../components/base/Loading';
import useRouteParamAsNumber from '../helper/hooks/useParamAsNumber';
import confirmModal from '../helper/confirmModal';
import InstructorForm, { FormFields } from '../components/instructor/InstructorForm';
import InstructorAvailabilityDrawer from '../components/instructor/InstructorAvailabilityDrawer';

const Instructor = () => {
  const id = useRouteParamAsNumber('id');
  const navigate = useNavigate();

  const { data, loading, refetch } = useGetInstructorQuery({
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });

  const [update] = useUpdateInstructorMutation();
  const [deleteAvailability] = useDeleteInstructorAvailabilityMutation();
  const [deleteInstructor] = useDeleteInstructorMutation();

  const [createAvailabilityDrawerVisible, setCreateAvailabilityDrawerVisible] =
    useState<boolean>(false);

  const handleSave = async (values: FormFields) => {
    await update({
      variables: {
        id,
        dto: {
          name: values.name || '',
          active: values.active || false,
          color: values.color || '',
        },
      },
    });
    message.success('Die Änderungen wurden gespeichert.');
  };

  const handleDelete = async () => {
    if (
      await confirmModal({
        title: 'Lehrer löschen',
        content:
          'Bitte bestätige das Löschen. Dieser Schritt kann nicht rückgängig gemacht werden!',
        okText: 'Lehrer löschen',
        okType: 'danger',
        cancelText: 'Abbrechen',
      })
    ) {
      await deleteInstructor({
        variables: {
          id,
        },
      });
      navigate('/admin/instructors');
    }
  };

  const handleDeleteAvailability = (availabilityId: number) => {
    return async () => {
      if (
        await confirmModal({
          title: 'Verfügbarkeit löschen',
          content: 'Bitte bestätige das Löschen',
          okText: 'Verfügbarkeit löschen',
          okType: 'danger',
          cancelText: 'Abbrechen',
        })
      ) {
        await deleteAvailability({
          variables: {
            instructorId: id,
            id: availabilityId,
          },
        });
        refetch();
      }
    };
  };

  if (loading || !data) return <Loading />;

  return (
    <>
      <GenericForm
        title={data.instructor.name || ''}
        onSave={handleSave}
        onDelete={handleDelete}
        typeName="Lehrer"
        extra={[
          <Button
            key="availability"
            onClick={() => setCreateAvailabilityDrawerVisible(true)}
            icon={<FontAwesomeIcon icon={faCalendar} />}
          >
            Verfügbarkeit eintragen
          </Button>,
        ]}
        initialValues={{
          name: data.instructor.name || '',
          color: data.instructor.color || '',
          active: data.instructor.active || false,
        }}
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Stammdaten" key="1">
            <InstructorForm />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Verfügbarkeit" key="2">
            <Card>
              <List
                locale={{
                  emptyText: <Empty description="Keine Verfügbarkeiten gefunden" />,
                }}
                itemLayout="horizontal"
                dataSource={data.instructor.availabilities || []}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button key="delete" danger onClick={handleDeleteAvailability(item.id)}>
                        <Space>
                          <FontAwesomeIcon icon={faTrash} />
                          Löschen
                        </Space>
                      </Button>,
                    ]}
                  >
                    {item.comment} (
                    <Space>
                      <span>{moment(item.startDate).format('DD.MM.YYYY')}</span>
                      <span>-</span>
                      <span>{moment(item.endDate).format('DD.MM.YYYY')}</span>
                    </Space>
                    )
                  </List.Item>
                )}
              />
            </Card>
          </Tabs.TabPane>
        </Tabs>
      </GenericForm>

      <InstructorAvailabilityDrawer
        instructorId={id}
        visible={createAvailabilityDrawerVisible}
        onClose={() => setCreateAvailabilityDrawerVisible(false)}
        onSave={refetch}
      />
    </>
  );
};

export default Instructor;
