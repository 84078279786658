import React from 'react';
import { UserFragment, useDeleteUserMutation, useUpdateUserMutation } from '../../graphql/schema';
import UserForm, { UserFormFields } from './UserForm';
import GenericEditDrawer from '../drawer/GenericEditDrawer';

export interface Props {
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
  user?: UserFragment;
}

const EditUserDrawer = ({ visible, onClose, onSave, user }: Props) => {
  const [update] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  const handleSave = async (values: Partial<UserFormFields>) => {
    if (!user) return;

    await update({
      variables: {
        id: user.id,
        dto: {
          active: !!values.active,
          firstName: values.firstName || '',
          lastName: values.lastName || '',
          email: values.email || '',
          isAdmin: values.isAdmin || false,
          ...(values.password && values.password.trim() !== ''
            ? {
                password: values.password || '',
              }
            : {}),
        },
      },
    });

    onSave();
  };

  const handleDelete = async () => {
    if (!user) return;

    await deleteUser({
      variables: {
        id: user.id,
      },
    });
    onSave();
  };

  return (
    <GenericEditDrawer<UserFormFields>
      title="Nutzer bearbeiten"
      visible={visible}
      onClose={onClose}
      onSave={handleSave}
      onDelete={handleDelete}
      initialValues={user as UserFormFields}
    >
      <UserForm />
    </GenericEditDrawer>
  );
};

export default EditUserDrawer;
