import React from 'react';
import { Form, Typography, DatePicker } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { Moment } from 'moment';
import locale from 'antd/es/date-picker/locale/de_DE';

export interface AttendanceFormFields {
  attendance: [Moment, Moment];
}

interface Props {
  form: FormInstance<AttendanceFormFields>;
}

const Attendance = ({ form }: Props) => {
  const { RangePicker } = DatePicker;

  return (
    <Form layout="vertical" form={form}>
      <h1>Wann möchtest Du Deinen Kurs machen?</h1>

      <Typography.Paragraph type="secondary">
        Wassersport ist stark von Wind & Wetter abhängig, daher nenne uns bitte den Zeitraum, in dem
        Du einen Kurs bei uns machen möchtest. Die genauen Daten klären wir dann mit Dir persönlich
        ab.
      </Typography.Paragraph>

      <Form.Item
        name="attendance"
        label="Zeitraum"
        rules={[
          {
            required: true,
            message: 'Bitte gib den Zeitraum an, in dem Du einen Kurs machen möchtest',
          },
        ]}
      >
        <RangePicker
          locale={locale}
          format="DD.MM.YYYY"
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode as any;
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default Attendance;
