import React from 'react';
import { Select } from 'antd';
import { useGetSpotsForSelectQuery } from '../../../graphql/schema';

interface Props {
  value?: number;
  onChange?: (id: number) => void;
  placeholder: string;
}

const SpotSelect = ({ value, onChange, placeholder }: Props) => {
  const { data, loading } = useGetSpotsForSelectQuery();

  return (
    <Select
      placeholder={placeholder}
      onChange={onChange}
      loading={loading}
      value={value}
      options={data?.spots.items.map((spot) => {
        return {
          label: spot.name,
          value: spot.id,
        };
      })}
    />
  );
};

export default SpotSelect;
