import React from 'react';
import { Checkbox, Col, Form, Input, InputNumber, List, Row, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartCircleCheck, faEyeSlash, faTicket } from '@fortawesome/pro-light-svg-icons';
import {
  GetProductsQuery,
  ProductFragment,
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductsLazyQuery,
  useUpdateProductMutation,
} from '../graphql/schema';
import GenericCrudList from '../components/crud/GenericCrudList';
import ProductCategorySelect from '../components/form/select/ProductCategorySelect';

interface FormFields {
  title: string;
  active: boolean;
  description: string;
  image: string;
  price: number;
  categoryId: number | null;
  isVoucher: boolean;
  visibleInShop: boolean;
  visibleInCourseBooking: boolean;
  voucherPrefix: string;
}

const Products = () => {
  const [create] = useCreateProductMutation();
  const [update] = useUpdateProductMutation();
  const [remove] = useDeleteProductMutation();

  const handleCreate = async (values: Partial<FormFields>) => {
    await create({
      variables: {
        dto: {
          title: values.title || '',
          active: values.active || false,
          description: values.description || '',
          image: values.image || '',
          price: values.price || 0,
          categoryId: values.categoryId || null,
          isVoucher: values.isVoucher || false,
          visibleInShop: values.visibleInShop || false,
          visibleInCourseBooking: values.visibleInCourseBooking || false,
          voucherPrefix: values.voucherPrefix || '',
        },
      },
    });
  };

  const handleUpdate = async (id: number, values: Partial<FormFields>) => {
    await update({
      variables: {
        id,
        dto: {
          title: values.title || '',
          active: values.active || false,
          description: values.description || '',
          image: values.image || '',
          price: values.price || 0,
          categoryId: values.categoryId || null,
          isVoucher: values.isVoucher || false,
          visibleInShop: values.visibleInShop || false,
          visibleInCourseBooking: values.visibleInCourseBooking || false,
          voucherPrefix: values.voucherPrefix || '',
        },
      },
    });
  };

  const handleDelete = async (id: number) => {
    await remove({
      variables: {
        id,
      },
    });
  };

  const form = () => {
    return (
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item name="title" label="Name" rules={[{ required: true }]}>
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="description" label="Beschreibung" rules={[{ required: false }]}>
            <TextArea placeholder="Beschreibung" rows={4} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="categoryId" label="Kategorie" rules={[{ required: false }]}>
            <ProductCategorySelect placeholder="Kategorie auswählen" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="image" label="Bild URL" rules={[{ required: false }]}>
            <Input placeholder="Bild URL" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="price" label="Preis" rules={[{ required: true }]}>
            <InputNumber addonAfter="€" decimalSeparator="," precision={2} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="active" rules={[{ required: false }]} valuePropName="checked">
            <Checkbox>Produkt ist aktiv</Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="isVoucher" rules={[{ required: false }]} valuePropName="checked">
            <Checkbox>Produkt generiert einen Gutschein</Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="voucherPrefix"
            label="Präfix für Gutschein"
            rules={[{ required: false }]}
          >
            <Input placeholder="Gutschein über einen" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="visibleInCourseBooking"
            rules={[{ required: false }]}
            valuePropName="checked"
          >
            <Checkbox>Produkt kann gebucht werden</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <GenericCrudList<FormFields, ProductFragment, GetProductsQuery>
      title="Produkte"
      singleItemTitle="Produkt"
      query={useGetProductsLazyQuery}
      dataKey="products"
      editForm={form}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      showSearch
      prepareFormValues={(values) => {
        return {
          title: values.title || '',
          active: values.active || false,
          description: values.description || '',
          image: values.image || '',
          price: values.price || 0,
          categoryId: values.category?.id || null,
          isVoucher: values.isVoucher || false,
          visibleInShop: values.visibleInShop || false,
          visibleInCourseBooking: values.visibleInCourseBooking || false,
          voucherPrefix: values.voucherPrefix || '',
        };
      }}
      renderItem={(item, onSelect) => (
        <List.Item>
          <List.Item.Meta
            title={<a onClick={onSelect}>{item.title}</a>}
            description={
              <Space size={24}>
                {!item.active && (
                  <Space>
                    <FontAwesomeIcon icon={faEyeSlash} />
                    Inaktiv
                  </Space>
                )}

                {item.isVoucher && (
                  <Space>
                    <FontAwesomeIcon icon={faTicket} />
                    Gutschein
                  </Space>
                )}
                {item.visibleInCourseBooking && (
                  <Space>
                    <FontAwesomeIcon icon={faCartCircleCheck} />
                    Online buchbar
                  </Space>
                )}
              </Space>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default Products;
