import React from 'react';
import { List, Modal } from 'antd';
import formatAmount from '../../helper/formatAmount';
import { ProductFragment, useGetProductsLazyQuery } from '../../graphql/schema';
import usePagination from '../../helper/hooks/usePagination';

export interface Props {
  visible: boolean;
  onClose: () => void;
  onSelect: (product: ProductFragment) => void;
}

const ProductSelectModal = ({ visible, onClose, onSelect }: Props) => {
  const [getProducts, { data, loading }] = useGetProductsLazyQuery();
  const { pagination } = usePagination(getProducts);

  return (
    <Modal title="Produkte" visible={visible} onCancel={onClose} width={1000} footer={[]}>
      {/*
      <div
        style={{
          marginBottom: 10,
        }}
      >
        <Input key={1} prefix={<FontAwesomeIcon icon={faSearch} />} />
      </div>
      */}

      <List
        loading={loading}
        pagination={pagination}
        itemLayout="horizontal"
        dataSource={data?.products.items}
        renderItem={(item) => (
          <List.Item
            style={{
              alignItems: 'flex-start',
            }}
          >
            <List.Item.Meta
              title={
                <a onClick={() => onSelect(item)}>
                  {item.isVoucher ? `Gutschein: ${item.title}` : item.title}
                </a>
              }
            />
            <div>{formatAmount(item.price)}</div>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default ProductSelectModal;
