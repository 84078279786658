import React, { useEffect } from 'react';
import { Button, Form, Space } from 'antd';
import moment from 'moment';
import { PageProps } from '../lib/pageProps';
import { Page } from '../lib/pageEnum';
import PersonalData, {
  PersonalDataFormFields,
} from '../../../components/customerRegistration/personalData';

const CustomerDetailsPage = ({ navigate, setState, state }: PageProps) => {
  const [form] = Form.useForm<PersonalDataFormFields>();

  useEffect(() => {
    form.setFieldsValue({
      firstName: state.customer?.firstName || '',
      lastName: state.customer?.lastName || '',
      street: state.customer?.street || '',
      streetNumber: state.customer?.streetNumber || '',
      zipCode: state.customer?.zipCode || '',
      city: state.customer?.city || '',
      dateOfBirth: moment(state.customer?.dateOfBirth) || undefined,
    });
  }, []);

  const handleNext = async () => {
    try {
      const values = await form.validateFields();

      await setState({
        customer: {
          ...state.customer,
          firstName: values.firstName,
          lastName: values.lastName,
          street: values.street,
          streetNumber: values.streetNumber,
          zipCode: values.zipCode,
          city: values.city,
          dateOfBirth: values.dateOfBirth ? values.dateOfBirth.toDate() : undefined,
        },
      });
      navigate(Page.contactInfo);
    } catch (e) {
      console.log('ERRR');
      // TODO
    }
  };

  return (
    <div>
      <Space direction="vertical" size="large">
        <PersonalData form={form} />
      </Space>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Button type="primary" onClick={handleNext}>
          Weiter
        </Button>
      </div>
    </div>
  );
};
export default CustomerDetailsPage;
