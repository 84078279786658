import React, { useEffect, useMemo, useState } from 'react';
import Meta from 'antd/es/card/Meta';
import { Button, Card, Row, Col, Badge } from 'antd';
import {
  useGetProductsForBookingQuery,
  useGetProductsForVoucherShopQuery,
} from '../../../graphql/schema';
import Loading from '../../../components/base/Loading';
import formatAmount from '../../../helper/formatAmount';
import { PageProps } from '../lib/pageProps';
import { Page } from '../lib/pageEnum';

interface ProductCategories {
  [key: string]: {
    name: string;
    sorting: number;
    products: {
      id: number;
      title: string;
      image: string;
      description?: string | null;
      price: number;
    }[];
  };
}

export const ProductWrapper = ({ children, discount }: { children: any; discount?: number }) =>
  (discount || 0) > 0 ? (
    <Badge.Ribbon text={`-${discount}% Rabatt`} color="red">
      {children}
    </Badge.Ribbon>
  ) : (
    <div>{children}</div>
  );

const ProductPage = ({ navigate, setState, state }: PageProps) => {
  const { loading, data } = useGetProductsForVoucherShopQuery();

  const [productCategoryFilter, setProductCategoryFilter] = useState<string>();

  const handleNext = (productId: number) => {
    return () => {
      setState({
        productId,
      });
      setTimeout(() => {
        const widgets = document.getElementsByTagName('dts-widget');
        if (widgets.length > 0) {
          widgets[0].scrollIntoView();
        }
      }, 100);
      navigate(Page.details);
    };
  };

  useEffect(() => {
    // Product selected via URL
    const productIdMatch = window.location.search.match(/productId=([0-9]+)/);
    const productId = productIdMatch ? parseInt(productIdMatch[1], 10) : null;

    if (productId) {
      handleNext(productId)();
      return;
    }

    // Product category selected via URL
    const productCategoryMatch = window.location.search.match(/category=([A-Za-z\-_0-9]+)/);
    const productCategory = productCategoryMatch ? productCategoryMatch[1] : null;

    if (productCategory) {
      setProductCategoryFilter(productCategory);
    }
  }, []);

  const categories: ProductCategories = useMemo(() => {
    if (!data) return {};

    const products = data.publicProductsForVoucherShop
      .filter((product) => {
        if (!productCategoryFilter) return true;

        return product.category && product.category.uid === productCategoryFilter;
      })
      .sort((a, b) => {
        return a.price - b.price;
      });

    const result: ProductCategories = {};

    products.forEach((product) => {
      const categoryUid = product.category?.uid || 'undefined';
      const categoryName = product.category?.name || '';

      if (!result[categoryUid])
        result[categoryUid] = {
          name: categoryName,
          sorting: product.category?.sorting || 0,
          products: [],
        };

      result[categoryUid].products.push(product);
    });

    return result;
  }, [data]);

  if (loading || !data) return <Loading />;

  return (
    <div>
      {Object.keys(categories)
        .sort((ka, kb) => {
          const a = categories[ka].sorting;
          const b = categories[kb].sorting;
          return a - b;
        })
        .map((categoryUid) => {
          const category = categories[categoryUid];

          return (
            <div key={categoryUid}>
              <h2>{category.name}</h2>
              <Row
                style={{
                  marginLeft: -10,
                  marginRight: -10,
                  marginBottom: 25,
                }}
              >
                {category.products.map((product) => (
                  <Col key={product.id} xs={24} sm={12} md={8}>
                    <div
                      style={{
                        margin: 10,
                      }}
                    >
                      <ProductWrapper discount={data.voucherDiscount}>
                        <Card cover={<img alt={product.title} src={product.image} />}>
                          <Meta title={product.title} description={product.description} />
                          <div
                            style={{
                              fontSize: 20,
                              paddingTop: 10,
                              lineHeight: '120%',
                            }}
                          >
                            {(data.voucherDiscount || 0) > 0 && (
                              <>
                                <small
                                  style={{
                                    textDecoration: 'line-through',
                                  }}
                                >
                                  {formatAmount(product.price)}
                                </small>
                                <br />
                              </>
                            )}
                            {formatAmount(
                              product.price - (product.price * (data.voucherDiscount || 0)) / 100,
                            )}
                          </div>
                          <div>
                            <Button
                              type="primary"
                              style={{
                                width: '100%',
                                marginTop: 10,
                              }}
                              onClick={handleNext(product.id)}
                            >
                              Jetzt bestellen
                            </Button>
                          </div>
                        </Card>
                      </ProductWrapper>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          );
        })}
    </div>
  );
};
export default ProductPage;
