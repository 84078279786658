import React from 'react';
import { PageHeader, List, Input, Card, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import Page from '../components/layout/Page';
import Content from '../components/layout/Content';
import Fab from '../components/layout/Fab';
import { useGetInstructorsLazyQuery } from '../graphql/schema';
import Loading from '../components/base/Loading';
import usePagination from '../helper/hooks/usePagination';

const Instructors = () => {
  const navigate = useNavigate();
  const [getInstructors, { data, loading }] = useGetInstructorsLazyQuery();
  const { pagination } = usePagination(getInstructors);

  if (loading) return <Loading />;

  return (
    <Page>
      <Fab
        icon={faPlus}
        onClick={() => navigate('/admin/createInstructor')}
        title="Lehrer anlegen"
      />

      <PageHeader title="Lehrer" />
      <Content>
        <Card>
          <List
            pagination={pagination}
            itemLayout="horizontal"
            dataSource={data?.instructors.items}
            renderItem={(instructor) => (
              <List.Item>
                <List.Item.Meta
                  title={<Link to={`/admin/instructor/${instructor.id}`}>{instructor.name}</Link>}
                  description={
                    <Space size={24}>
                      {!instructor.active && (
                        <Space>
                          <FontAwesomeIcon icon={faEyeSlash} />
                          Inaktiv
                        </Space>
                      )}
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        </Card>
      </Content>
    </Page>
  );
};

export default Instructors;
