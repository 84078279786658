import React, { useEffect } from 'react';
import { Button, Result } from 'antd';
import { PageProps } from '../lib/pageProps';
import Loading from '../../../components/base/Loading';
import { useCheckPaymentQuery } from '../../../graphql/schema';
import { Page } from '../lib/pageEnum';

const PaymentPage = ({ navigate }: PageProps) => {
  const orderIdMatch = window.location.search.match(/orderId=([0-9]+)/);
  const orderId = orderIdMatch ? parseInt(orderIdMatch[1], 10) : 0;

  const { loading, data, refetch } = useCheckPaymentQuery({
    variables: {
      orderId,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data?.checkPayment.status === 'pending') {
      const handle = setInterval(() => {
        refetch();
      }, 1000 * 5);
      return () => {
        clearInterval(handle);
      };
    }
    return () => {};
  }, [data]);

  if (loading) return <Loading />;

  return (
    <div>
      {data?.checkPayment.status === 'paid' && (
        <Result
          status="success"
          title="Vielen Dank für Deine Bestellung!"
          subTitle={
            <div>
              Wir senden Dir in den nächsten Minuten eine E-Mail mit einem Link zu Deinem Gutschein.
              <br />
              Solltest Du keine E-Mail bekommen, oder weitere Fragen haben, melde Dich gerne
              jederzeit bei uns!
            </div>
          }
        />
      )}
      {data?.checkPayment.status === 'pending' && (
        <Result
          status="warning"
          title="Zahlung in Bearbeitung..."
          subTitle={
            <div>
              Deine Zahlung ist noch in Bearbeitung. Bitte warte kurz, bis wir Deine Zahlung
              erhalten haben.
            </div>
          }
          extra={
            <Button
              type="primary"
              onClick={() => {
                refetch();
              }}
            >
              Aktualisieren
            </Button>
          }
        />
      )}
      {data?.checkPayment.status === 'failed' && (
        <Result
          status="error"
          title="Fehler bei der Bezahlung"
          subTitle={
            <div>
              Leider ist ein Fehler bei der Bezahlung aufgetreten. Bitte versuche es noch einmal.
              <br />
              Sollte die Bezahlung weiterhin nicht klappen, melde Dich bitte bei uns.
            </div>
          }
          extra={
            <Button
              type="primary"
              onClick={() => {
                navigate(Page.checkout);
              }}
            >
              Zu Deiner Bestellung
            </Button>
          }
        />
      )}
    </div>
  );
};
export default PaymentPage;
