import { RuleObject } from 'antd/lib/form';

const checkboxValidator = (
  rule: RuleObject,
  value: unknown,
  callback: (error?: string) => void,
) => {
  if (value) {
    return callback();
  }
  return callback(rule.message as string);
};

export default checkboxValidator;
