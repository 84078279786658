import React from 'react';
import { Col, DatePicker, Form, Input, InputNumber, Row, TimePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/de_DE';
import { Moment } from 'moment';
import SpotSelect from '../form/select/SpotSelect';
import InstructorSelect from '../form/select/InstructorSelect';

export interface CourseFormFields {
  title: string;
  date: Moment;
  startTime: Moment;
  endTime: Moment;
  spotId: number;
  instructorId: number;
}

const CourseForm = () => {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <Form.Item name="title" label="Name des Kurses" rules={[{ required: true }]}>
          <Input placeholder="z.B. Grundkurs Kitesurfen" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="spotId" label="Spot" rules={[{ required: true }]}>
          <SpotSelect placeholder="Spot auswählen" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="instructorId" label="Lehrer" rules={[{ required: true }]}>
          <InstructorSelect placeholder="Lehrer auswählen" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="date" label="Datum" rules={[{ required: true }]}>
          <DatePicker
            format="DD.MM.YYYY"
            placeholder="Datum auswählen"
            locale={locale}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="startTime" label="Beginn" rules={[{ required: true }]}>
          <TimePicker
            format="HH:mm"
            placeholder="Zeit auswählen"
            locale={locale}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="endTime" label="Ende" rules={[{ required: true }]}>
          <TimePicker
            format="HH:mm"
            placeholder="Zeit auswählen"
            locale={locale}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CourseForm;
