import React, { useEffect } from 'react';
import { Button, Form, Space } from 'antd';
import { PageProps } from '../lib/pageProps';
import { Page } from '../lib/pageEnum';
import KiteLevel, { KiteLevelFormFields } from '../../../components/customerRegistration/kiteLevel';

const KiteLevelPage = ({ navigate, setState, state }: PageProps) => {
  const [form] = Form.useForm<KiteLevelFormFields>();

  useEffect(() => {
    form.setFieldsValue({
      kiteLevel: state.customer?.kiteLevel || undefined,
    });
  }, []);

  const handleNext = async () => {
    try {
      const values = await form.validateFields();

      await setState({
        customer: {
          ...state.customer,
          kiteLevel: values.kiteLevel,
        },
      });
      navigate(Page.checkout);
    } catch (e) {
      console.log('ERRR');
      // TODO
    }
  };

  return (
    <div>
      <Space direction="vertical" size="large">
        <KiteLevel form={form} />
      </Space>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Button type="primary" onClick={handleNext}>
          Weiter
        </Button>
      </div>
    </div>
  );
};
export default KiteLevelPage;
