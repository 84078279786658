import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import Page from '../components/layout/Page';

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <Result
        status="404"
        title="404"
        subTitle="Diese Seite wurde nicht gefunden"
        extra={
          <Button onClick={() => navigate('/admin')} type="primary">
            Zurück zum Start
          </Button>
        }
      />
    </Page>
  );
};

export default Error404;
