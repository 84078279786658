import React, { useState } from 'react';
import { PageHeader, List, Input, Card, Space, Row, Col, Button, Badge, Pagination } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAnchor,
  faBaby,
  faCertificate,
  faCheck,
  faMapMarker,
  faPersonSimple,
  faPlus,
  faRuler,
  faSearch,
  faShoePrints,
  faShoppingCart,
  faTimes,
  faUser,
  faWeightScale,
  faChevronRight,
  faPencil,
} from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import Page from '../components/layout/Page';
import Content from '../components/layout/Content';
import Fab from '../components/layout/Fab';
import {
  CourseFragment,
  useCreateCourseAllocationMutation,
  useDeleteCourseAllocationMutation,
  useGetFutureCoursesLazyQuery,
  useGetFutureCustomerAttendanceQuery,
} from '../graphql/schema';
import usePagination from '../helper/hooks/usePagination';
import Loading from '../components/base/Loading';
import AddCourseDrawer from '../components/course/AddCourseDrawer';
import PotentialAttendee from '../components/coursePlanning/PotentialAttendee';
import CourseAllocation from '../components/coursePlanning/CourseAllocation';
import confirmModal from '../helper/confirmModal';
import EditCourseDrawer from '../components/course/EditCourseDrawer';
import useAuth from '../auth/useAuth';

interface GroupedByDate {
  [key: string]: CourseFragment[];
}

const CoursePlanning = () => {
  const { roles } = useAuth();
  const [getCourses, { data, loading, refetch }] = useGetFutureCoursesLazyQuery();
  const { pagination } = usePagination(getCourses);

  const { data: customerAttendance, loading: customerAttendanceLoading } =
    useGetFutureCustomerAttendanceQuery();

  const [createCourseAllocation] = useCreateCourseAllocationMutation();
  const [deleteCourseAllocation] = useDeleteCourseAllocationMutation();

  const [addCourseDrawerVisible, setAddCourseDrawerVisible] = useState<boolean>(false);
  const [editCourseDrawerCourse, setEditCourseDrawerCourse] = useState<CourseFragment>();

  const handleCreateAllocation = (courseId: number, customerId: number, productId?: number) => {
    return async () => {
      await createCourseAllocation({
        variables: {
          id: courseId,
          dto: {
            customerId,
            productId,
          },
        },
      });
      refetch();
    };
  };

  const handleDeleteAllocation = (id: number) => {
    return async () => {
      if (
        await confirmModal({
          title: 'Kurszuordnung löschen',
          content: 'Sicher, dass der Kunde aus diesem Kurs entfernt werden soll?',
          okText: 'Entfernen',
          cancelText: 'Abbrechen',
        })
      ) {
        await deleteCourseAllocation({
          variables: {
            id,
          },
        });
        refetch();
      }
    };
  };

  const groupedByDates = data?.futureCourses.items.reduce<GroupedByDate>((result, course) => {
    const startDate = moment(course.startDate).format('YYYY-MM-DD');
    // eslint-disable-next-line no-param-reassign
    result[startDate] = (result[startDate] || []).concat(course);
    return result;
  }, {});

  const dates = Object.keys(groupedByDates || {});

  if (loading || customerAttendanceLoading) return <Loading />;

  return (
    <Page>
      {roles.includes('courseplanning') && (
        <Fab icon={faPlus} onClick={() => setAddCourseDrawerVisible(true)} title="Kurs anlegen" />
      )}

      <PageHeader title="Kursplanung" />
      <Content>
        {groupedByDates &&
          dates
            .sort((a, b) => {
              return moment(a).valueOf() - moment(b).valueOf();
            })
            .map((item, key) => {
              const date = moment(dates[key]);
              const courses = groupedByDates[dates[key]];

              return (
                <div
                  key={date.format('YYYY-MM-DD')}
                  style={{
                    marginBottom: 50,
                  }}
                >
                  <h3>
                    {moment(0, 'HH').diff(date, 'days') === 0
                      ? `Heute, ${date.format('DD.MM.YYYY')}`
                      : date.format('dddd, DD.MM.YYYY')}
                  </h3>

                  {courses
                    .sort(
                      (a, b) =>
                        parseInt(moment(a.startDate).format('YYYYMMDD'), 10) -
                          parseInt(moment(b.startDate).format('YYYYMMDD'), 10) || a.id - b.id,
                    )
                    .map((course) => (
                      <div
                        key={course.id}
                        style={{
                          marginBottom: 10,
                        }}
                      >
                        <h4>
                          {moment(course.startDate).format('HH:mm')} -{' '}
                          {moment(course.endDate).format('HH:mm')} Uhr
                        </h4>

                        <Card
                          title={course.title}
                          extra={[
                            <Space key="info" size={30}>
                              <Space>
                                <FontAwesomeIcon icon={faUser} />
                                {course.instructor.name}
                              </Space>
                              <Space>
                                <FontAwesomeIcon icon={faMapMarker} />
                                {course.spot.name}
                              </Space>
                              {roles.includes('courseplanning') && (
                                <Button
                                  size="small"
                                  onClick={() => setEditCourseDrawerCourse(course)}
                                >
                                  <FontAwesomeIcon icon={faPencil} />
                                </Button>
                              )}
                            </Space>,
                          ]}
                        >
                          <Row>
                            <Col span={11}>
                              <h4>Anwesend</h4>

                              {customerAttendance?.futureCustomerAttendance
                                .filter(
                                  (attendance) =>
                                    moment(attendance.endDate) >=
                                      moment(course.startDate).startOf('day') &&
                                    moment(attendance.startDate) <=
                                      moment(course.startDate).endOf('day'),
                                )
                                .filter((attendance) => {
                                  // Allocated for this course
                                  if (
                                    course.allocations.find(
                                      (allocation) =>
                                        allocation.customer?.id === attendance.customer.id,
                                    )
                                  )
                                    return false;

                                  // Allocated for other course at same time
                                  if (
                                    courses.find((c) => {
                                      return c.allocations.find(
                                        (allocation) =>
                                          allocation.customer?.id === attendance.customer.id,
                                      );
                                    })
                                  )
                                    return false;
                                  return true;
                                })
                                .sort((a, b) => Number(!!b.booking) - Number(!!a.booking))
                                .map((attendance) => (
                                  <PotentialAttendee
                                    attendance={attendance}
                                    key={`${course.id}-${attendance.id}`}
                                    onAdd={handleCreateAllocation(
                                      course.id,
                                      attendance.customer.id,
                                      attendance.desiredProduct?.id,
                                    )}
                                  />
                                ))}
                            </Col>
                            <Col span={12} offset={1}>
                              <h4>Teilnehmer</h4>

                              {course.allocations.map((allocation) => (
                                <CourseAllocation
                                  allocation={allocation}
                                  key={allocation.id}
                                  onDelete={handleDeleteAllocation(allocation.id)}
                                  onChange={refetch}
                                />
                              ))}
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    ))}
                </div>
              );
            })}

        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Pagination {...pagination} />
        </div>
      </Content>

      <AddCourseDrawer
        visible={addCourseDrawerVisible}
        onClose={() => setAddCourseDrawerVisible(false)}
        onSave={refetch}
      />

      <EditCourseDrawer
        course={editCourseDrawerCourse}
        visible={!!editCourseDrawerCourse}
        onClose={() => setEditCourseDrawerCourse(undefined)}
        onSave={refetch}
      />
    </Page>
  );
};

export default CoursePlanning;
