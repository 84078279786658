import React from 'react';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faDashboard,
  faUsers,
  faCalendar,
  faWater,
  faTicket,
  faShoppingCart,
  faLocation,
  faUserAstronaut,
  faBox,
} from '@fortawesome/pro-light-svg-icons';
import useAuth from '../../auth/useAuth';

interface Route {
  label: string;
  path: string;
  exactPath?: boolean;
  icon: IconDefinition;
  childrenTitle?: string;
  children?: Route[];
  roles?: string[];
}

interface Props {
  onSelect?: () => void;
}

const Navigation = ({ onSelect }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { roles } = useAuth();

  const routes: Route[] = [
    {
      label: 'Aktive Kunden',
      path: '/admin',
      icon: faUsers,
      exactPath: true,
      roles: ['customers'],
    },
    {
      label: 'Kursplanung',
      path: '/admin/coursePlanning',
      icon: faWater,
      roles: ['courseplanning', 'courseplanningView'],
    },
    {
      label: 'Offene Abrechnungen',
      path: '/admin/openPayments',
      icon: faUsers,
      roles: ['billing'],
    },
    {
      label: 'Kalender',
      path: '/admin/calendar',
      icon: faCalendar,
      roles: ['calendar'],
    },
    {
      label: 'Kunden',
      path: '/admin/customers',
      icon: faUsers,
      roles: ['customers'],
    },
    {
      label: 'Produkte',
      path: '/admin/products',
      icon: faBox,
      roles: ['products'],
    },
    {
      label: 'Gutscheine',
      path: '/admin/vouchers',
      icon: faTicket,
      roles: ['vouchers'],
    },
    {
      label: 'Spots',
      path: '/admin/spots',
      icon: faLocation,
      roles: ['spots'],
    },
    {
      label: 'Lehrer',
      path: '/admin/instructors',
      icon: faUserAstronaut,
      roles: ['instructors'],
    },
    {
      label: 'Nutzer',
      path: '/admin/users',
      icon: faUsers,
      roles: ['users'],
    },
  ];

  const handleSelect = (route: string) => {
    return () => {
      navigate(route);
      onSelect?.();
    };
  };

  const getSelectedKeys = () => {
    const selectedKeys: string[] = [];
    routes.forEach((route) => {
      if (
        (!route.exactPath && location.pathname.indexOf(route.path) === 0) ||
        (route.exactPath && location.pathname === route.path)
      ) {
        selectedKeys.push(route.path);
      }
    });
    return selectedKeys;
  };

  return (
    <Menu mode="inline" selectedKeys={getSelectedKeys()}>
      {routes
        .filter((route) => {
          return route.roles?.some((role) => roles.includes(role));
        })
        .map((route) =>
          !route.children ? (
            <Menu.Item
              key={route.path}
              onClick={handleSelect(route.path)}
              icon={<FontAwesomeIcon icon={route.icon} />}
            >
              {route.label}
            </Menu.Item>
          ) : (
            <Menu.SubMenu
              key={route.path}
              title={route.label}
              icon={<FontAwesomeIcon icon={route.icon} />}
            >
              <Menu.ItemGroup key="g1" title={route.childrenTitle}>
                {route.children.map((child) => (
                  <Menu.Item
                    key={`${route.path}-${child.path}`}
                    icon={<FontAwesomeIcon icon={child.icon} />}
                    onClick={handleSelect(child.path)}
                  >
                    {child.label}
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            </Menu.SubMenu>
          ),
        )}
    </Menu>
  );
};

export default Navigation;
