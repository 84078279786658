import React from 'react';
import { Checkbox, Col, Form, Input, Row } from 'antd';

export interface UserFormFields {
  active: boolean;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  isAdmin: boolean;
}

const UserForm = () => {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <Form.Item name="firstName" label="Vorname" rules={[{ required: true }]}>
          <Input placeholder="Vorname" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="lastName" label="Nachname" rules={[{ required: true }]}>
          <Input placeholder="Nachname" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="email" label="E-Mail" rules={[{ required: true }]}>
          <Input placeholder="E-Mail" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="password" label="Neues Passwort" rules={[{ required: false }]}>
          <Input placeholder="Neues Passwort" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="active" rules={[{ required: false }]} valuePropName="checked">
          <Checkbox>Aktiv</Checkbox>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="isAdmin" rules={[{ required: false }]} valuePropName="checked">
          <Checkbox>Administrator</Checkbox>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default UserForm;
