import React, { useState } from 'react';
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Space,
  Typography,
  DatePicker,
  Checkbox,
} from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';

export interface BodyInfoFormFields {
  shoeSize?: string;
  bodyHeight?: string;
  bodyWeight?: string;
  ownGear?: boolean;
}

interface Props {
  form: FormInstance<BodyInfoFormFields>;
}

const BodyInfo = ({ form }: Props) => {
  return (
    <Form layout="vertical" form={form}>
      <h1>...und noch ein paar Infos zu Dir</h1>

      <Typography.Paragraph type="secondary">
        Damit wir für Dich das passende Equipment aussuchen können, beantworte uns bitte noch ein
        paar Fragen zu Dir.
      </Typography.Paragraph>

      <Form.Item name="ownGear" rules={[{ required: false }]} valuePropName="checked">
        <Checkbox>Ich habe eigenes Kitematerial und bin komplett ausgestattet</Checkbox>
      </Form.Item>

      <Form.Item name="shoeSize" label="Schuhgröße" rules={[{ required: false }]}>
        <Input placeholder="Deine Schuhgröße (z.B. 43)" />
      </Form.Item>

      <Form.Item name="bodyHeight" label="Körpergröße" rules={[{ required: false }]}>
        <Input placeholder="Deine Größe (z.B. 182)" suffix="cm" />
      </Form.Item>

      <Form.Item name="bodyWeight" label="Körpergewicht" rules={[{ required: false }]}>
        <Input placeholder="Dein Gewicht...ungefähr" suffix="kg" />
      </Form.Item>
    </Form>
  );
};

export default BodyInfo;
