import React from 'react';
import moment from 'moment';
import { Badge, Button, Card, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTimes, faEuro } from '@fortawesome/pro-light-svg-icons';

interface Props {
  attendance: {
    id: number;
    customer: {
      firstName?: string | null;
      lastName?: string | null;
      kiteLevel?: number | null;
    };
    booking?: {
      id: number;
      title: string;
      paid: boolean;
    } | null;
    desiredProduct?: {
      id: number;
      title: string;
    } | null;
    startDate: string;
    endDate: string;
  };
  onAdd: () => void;
}

const PotentialAttendee = ({ attendance, onAdd }: Props) => {
  const getBackgroundColor = () => {
    if (attendance.booking && attendance.booking.paid) return '#caf5af';
    if (attendance.booking) return '#fff8c1';
    return 'none';
  };

  return (
    <Card
      size="small"
      style={{
        marginBottom: 10,
        backgroundColor: getBackgroundColor(),
      }}
    >
      <Card.Meta
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {attendance.customer.firstName} {attendance.customer.lastName}
              {attendance.customer.kiteLevel && (
                <Badge
                  count={attendance.customer.kiteLevel}
                  style={{
                    backgroundColor: '#ccc',
                    marginLeft: 5,
                    position: 'relative',
                    top: -2,
                  }}
                />
              )}
              {attendance.desiredProduct && (
                <Badge
                  count={attendance.desiredProduct.title}
                  style={{
                    backgroundColor: '#ccc',
                    marginLeft: 5,
                    position: 'relative',
                    top: -2,
                  }}
                />
              )}
            </span>
            <Space direction="horizontal">
              <Button size="small" onClick={onAdd}>
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </Space>
          </div>
        }
        description={`${moment(attendance.startDate).format('DD.MM.YY')} - ${moment(
          attendance.endDate,
        ).format('DD.MM.YY')}${
          attendance.booking && !attendance.booking.paid ? ' (Zahlung fehlgeschlagen)' : ''
        }`}
      />
    </Card>
  );
};

export default PotentialAttendee;
