import React from 'react';
import { Select } from 'antd';
import { useGetProductsForCourseSelectQuery } from '../../../graphql/schema';

interface Props {
  value?: number;
  onChange?: (id: number) => void;
  placeholder: string;
}

const ProductForCourseSelect = ({ value, onChange, placeholder }: Props) => {
  const { data, loading } = useGetProductsForCourseSelectQuery();

  return (
    <Select
      placeholder={placeholder}
      onChange={onChange}
      loading={loading}
      value={value}
      options={data?.products.items.map((product) => {
        return {
          label: product.title,
          value: product.id,
        };
      })}
    />
  );
};

export default ProductForCourseSelect;
