import React from 'react';
import moment from 'moment';
import { PageHeader, List, Input, Card, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBaby, faMapMarker, faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import Page from '../components/layout/Page';
import Content from '../components/layout/Content';
import Fab from '../components/layout/Fab';
import { useGetCustomersLazyQuery, CustomerFragment } from '../graphql/schema';
import Loading from '../components/base/Loading';
import usePagination from '../helper/hooks/usePagination';

const Customers = () => {
  const navigate = useNavigate();
  const [getCustomers, { data, loading }] = useGetCustomersLazyQuery();
  const { pagination, query, setQuery } = usePagination(getCustomers);

  if (loading) return <Loading />;

  return (
    <Page>
      <Fab icon={faPlus} onClick={() => navigate('/admin/createCustomer')} title="Kunden anlegen" />

      <PageHeader
        title="Kunden"
        extra={[
          <Input
            key={1}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            allowClear
          />,
        ]}
      />
      <Content>
        <Card>
          <List
            pagination={pagination}
            itemLayout="horizontal"
            dataSource={data?.customers.items}
            renderItem={(customer) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Link to={`/admin/customer/${customer.id}`}>
                      {customer.firstName} {customer.lastName}
                    </Link>
                  }
                  description={
                    <Space size={24}>
                      {customer.city && (
                        <Space>
                          <FontAwesomeIcon icon={faMapMarker} />
                          {customer.city}
                        </Space>
                      )}

                      {customer.dateOfBirth && (
                        <Space>
                          <FontAwesomeIcon icon={faBaby} />
                          {moment(customer.dateOfBirth).format('DD.MM.YYYY')}
                        </Space>
                      )}
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        </Card>
      </Content>
    </Page>
  );
};

export default Customers;
