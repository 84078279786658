import React from 'react';
import { Result } from 'antd';
import { PageProps } from '../lib/pageProps';

const LoginEmailPage = (_: PageProps) => {
  return (
    <div>
      <Result
        status="success"
        title="Du bekommst eine E-Mail von uns"
        subTitle="Schön, dass Du wieder da bist. Wir haben Dir eine E-Mail mit einem Link gesendet. Bitte klicke auf den Link und schon kann es weiter gehen."
      />
    </div>
  );
};
export default LoginEmailPage;
