export enum Page {
  product = 0,
  email = 1,
  attendance = 2,
  customerDetails = 3,
  loginMail = 4,
  contactInfo = 5,
  bodyInfo = 6,
  kiteLevel = 7,
  payment = 8,
  checkout = 9,
  confirmation = 10,
}
