import React from 'react';
import { useCreateCourseMutation } from '../../graphql/schema';
import GenericAddDrawer from '../drawer/GenericAddDrawer';
import CourseForm, { CourseFormFields } from './CourseForm';

export interface Props {
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
}

const AddCourseDrawer = ({ visible, onClose, onSave }: Props) => {
  const [create] = useCreateCourseMutation();

  const handleSave = async (values: Partial<CourseFormFields>) => {
    if (!values.date || !values.startTime || !values.endTime) return;

    const startDate = values.date.clone().set({
      hour: values.startTime.hour(),
      minute: values.startTime.minute(),
    });

    const endDate = values.date.clone().set({
      hour: values.endTime.hour(),
      minute: values.endTime.minute(),
    });

    await create({
      variables: {
        dto: {
          title: values.title || '',
          startDate,
          endDate,
          instructorId: values.instructorId,
          spotId: values.spotId,
        },
      },
    });
    onSave();
  };

  return (
    <GenericAddDrawer<CourseFormFields>
      title="Kurs anlegen"
      visible={visible}
      onClose={onClose}
      onSave={handleSave}
    >
      <CourseForm />
    </GenericAddDrawer>
  );
};

export default AddCourseDrawer;
