import React from 'react';
import moment from 'moment';
import { CourseFragment, useUpdateCourseMutation } from '../../graphql/schema';
import CourseForm, { CourseFormFields } from './CourseForm';
import GenericEditDrawer from '../drawer/GenericEditDrawer';

export interface Props {
  course?: CourseFragment;
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
}

const EditCourseDrawer = ({ visible, onClose, onSave, course }: Props) => {
  const [update] = useUpdateCourseMutation();

  const handleSave = async (values: Partial<CourseFormFields>) => {
    if (!course || !values.date || !values.startTime || !values.endTime) return;

    const startDate = values.date.clone().set({
      hour: values.startTime.hour(),
      minute: values.startTime.minute(),
    });

    const endDate = values.date.clone().set({
      hour: values.endTime.hour(),
      minute: values.endTime.minute(),
    });

    await update({
      variables: {
        id: course.id,
        dto: {
          title: values.title || '',
          startDate,
          endDate,
          instructorId: values.instructorId || 1,
          spotId: values.spotId || 1,
        },
      },
    });
    onSave();
  };

  return (
    <GenericEditDrawer<CourseFormFields>
      title="Kurs bearbeiten"
      visible={visible}
      onClose={onClose}
      onSave={handleSave}
      initialValues={
        course
          ? {
              date: moment(course.startDate),
              startTime: moment(course.startDate),
              endTime: moment(course.endDate),
              spotId: course.spot.id,
              instructorId: course.instructor.id,
              title: course.title,
            }
          : {}
      }
    >
      <CourseForm />
    </GenericEditDrawer>
  );
};

export default EditCourseDrawer;
