import React from 'react';
import { Card, Checkbox, Col, DatePicker, Form, Input, Row, Select, Space } from 'antd';

export interface FormFields {
  name?: string;
  active?: boolean;
  color?: string;
}

const InstructorForm = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col
        sm={{
          span: 24,
        }}
        style={{
          width: '100%',
        }}
      >
        <Card>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="active" rules={[{ required: false }]} valuePropName="checked">
                <Checkbox>Lehrer ist aktiv</Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="color" label="Farbe" rules={[{ required: false }]}>
                <Select
                  placeholder="Farbe"
                  options={[
                    { label: 'Blau', value: '#92B4EC' },
                    { label: 'Pink', value: '#FFC3C3' },
                    { label: 'Grün', value: '#809A6F' },
                    { label: 'Violett', value: '#9571cc' },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default InstructorForm;
