import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Result } from 'antd';
import checkboxValidator from '../helper/checkboxValidator';
import { useSendContactMessageMutation } from '../graphql/schema';

interface FormFields {
  name: string;
  email?: string;
  phone?: string;
  message: string;
}

const Contact = () => {
  const [form] = Form.useForm<FormFields>();

  const [sent, setSent] = useState<boolean>(false);

  const [send] = useSendContactMessageMutation();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      await send({
        variables: {
          dto: {
            name: values.name,
            email: values.email || '',
            phone: values.phone || '',
            message: values.message,
          },
        },
      });

      setSent(true);
    } catch (e) {
      console.error(e);
    }
  };

  if (sent) {
    return (
      <Result
        status="success"
        title="Nachricht versendet"
        subTitle="Vielen Dank für Deine Nachricht! Wir melden uns so schnell wie möglich bei Dir."
      />
    );
  }

  return (
    <div>
      <Form layout="vertical" form={form}>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Bitte gib Deinen Namen ein' }]}
        >
          <Input placeholder="Dein Name" />
        </Form.Item>

        <Form.Item
          name="email"
          label="E-Mail"
          rules={[{ required: true, message: 'Bitte gib Deine E-Mail Adresse ein' }]}
        >
          <Input placeholder="Deine E-Mail Adresse" type="email" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Handynummer"
          rules={[{ required: false, message: 'Bitte gib Deine Handynummer ein' }]}
        >
          <Input placeholder="Deine Handynummer" />
        </Form.Item>

        <Form.Item
          name="message"
          label="Deine Nachricht an uns"
          rules={[{ required: true, message: 'Bitte gib Deine Nachricht ein' }]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>

        <Form.Item
          name="terms"
          rules={[
            {
              required: true,
              message:
                'Um das Kontaktformular zu nutzen, musst Du unsere Datenschutzinformationen akzeptieren',
              validator: checkboxValidator,
            },
          ]}
          valuePropName="checked"
        >
          <Checkbox>
            Ich habe die{' '}
            <a rel="noreferrer" href="https://rueckenwind-kitesurf.de/datenschutz" target="_blank">
              Datenschutzinformationen
            </a>{' '}
            gelesen und akzeptiere sie.
          </Checkbox>
        </Form.Item>

        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button type="primary" onClick={handleSubmit}>
            Absenden
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Contact;
