import React, { useState } from 'react';
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Space,
  Typography,
  DatePicker,
  Checkbox,
} from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { RuleObject } from 'antd/lib/form';
import checkboxValidator from '../../helper/checkboxValidator';

export interface TermsFormFields {
  comment?: string;
  terms?: boolean;
  newsletter?: boolean;
}

interface Props {
  form: FormInstance<TermsFormFields>;
}

const Terms = ({ form }: Props) => {
  return (
    <Form layout="vertical" form={form}>
      <h1>Fast geschafft...</h1>

      <Typography.Paragraph type="secondary">
        Vielen Dank! Wir haben nun alle wichtigen Daten und kommen zum letzten Schritt. Falls Du uns
        noch etwas mitteilen möchtest, ist hier der richtige Ort.
      </Typography.Paragraph>

      <Form.Item name="comment" label="Deine Nachricht an uns" rules={[{ required: false }]}>
        <Input.TextArea
          placeholder="Wichtige Infos zu Dir, Einschränkungen, Wünsche, etc."
          rows={6}
        />
      </Form.Item>

      <Form.Item name="newsletter" rules={[{ required: false }]} valuePropName="checked">
        <Checkbox>
          Ich möchte per E-Mail über Neuigkeiten zu Rückenwind Kitesurf & Kiteevents informiert
          werden
        </Checkbox>
      </Form.Item>

      <Form.Item
        name="terms"
        rules={[
          { required: true, message: 'Bitte akzeptiere unsere AGB', validator: checkboxValidator },
        ]}
        valuePropName="checked"
      >
        <Checkbox>
          Ich erkläre mich mit den{' '}
          <a rel="noreferrer" href="https://rueckenwind-kitesurf.de/agb" target="_blank">
            Allgemeinen Geschäftsbedingungen
          </a>{' '}
          einverstanden
        </Checkbox>
      </Form.Item>
    </Form>
  );
};

export default Terms;
