import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { Card, Carousel, Col, Row, Typography } from 'antd';

const Reviews = () => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${
          (window as { apiURL?: string }).apiURL || process.env.REACT_APP_SERVER_URI
        }/reviews/google`,
      );
      setData(await response.json());
    })();
  }, []);

  if (!data) return <div />;

  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          marginBottom: 24,
        }}
      >
        {[...Array(Math.ceil(data.rating))].map((_, index) => (
          <FontAwesomeIcon
            key={index}
            icon={faStar as any}
            color="#F6BB05"
            size="2x"
            style={{
              width: 20,
            }}
          />
        ))}
        <div
          style={{
            marginTop: 10,
          }}
        >
          <strong>Basierend auf {data.userRatingCount} Bewertungen</strong>
        </div>
      </div>

      <div
        style={{
          padding: 25,
        }}
      >
        <Carousel
          dots={false}
          arrows
          infinite
          prevArrow={<FontAwesomeIcon icon={faChevronLeft as any} color="#aaa" size="2x" />}
          nextArrow={<FontAwesomeIcon icon={faChevronRight as any} color="#aaa" size="2x" />}
        >
          {[
            [data.reviews[0], data.reviews[1], data.reviews[2]],
            [data.reviews[3], data.reviews[4], data.reviews[0]],
          ].map((reviews, i) => (
            <div key={i}>
              <div
                style={{
                  padding: 10,
                }}
              >
                <Row gutter={24}>
                  {reviews.map((review: any) => (
                    <Col md={8} sm={8} key={review.id}>
                      <a href={review.uri} target="_blank" rel="noreferrer">
                        <Card style={{ height: '100%' }}>
                          <Card.Meta
                            title={
                              <div
                                style={{
                                  fontWeight: 'normal',
                                }}
                              >
                                <div>
                                  <strong>{review.displayName}</strong>
                                </div>
                                <div>{review.relativePublishTimeDescription}</div>
                              </div>
                            }
                            avatar={<img alt="" src={review.photo} width={40} />}
                          />
                          <div style={{ marginTop: 10 }}>
                            {[...Array(Math.ceil(review.rating))].map((_, ind) => (
                              <FontAwesomeIcon
                                key={ind}
                                icon={faStar as any}
                                color="#F6BB05"
                                style={{
                                  width: 20,
                                }}
                              />
                            ))}

                            <Typography.Paragraph
                              ellipsis={{
                                rows: 5,
                                expandable: true,
                                symbol: 'Mehr',
                                onExpand: (e) => {
                                  e.preventDefault();
                                },
                              }}
                              style={{ marginTop: 5 }}
                            >
                              {review.text}
                            </Typography.Paragraph>
                          </div>
                        </Card>
                      </a>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Reviews;
