import React from 'react';
import moment from 'moment';
import { PageHeader, List, Input, Card, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBaby, faMapMarker, faPlus, faSearch, faClock } from '@fortawesome/pro-light-svg-icons';
import Page from '../components/layout/Page';
import Content from '../components/layout/Content';
import Fab from '../components/layout/Fab';
import {
  useGetCustomersLazyQuery,
  CustomerFragment,
  useGetFutureCustomerAttendanceQuery,
} from '../graphql/schema';
import Loading from '../components/base/Loading';
import usePagination from '../helper/hooks/usePagination';

const ActiveCustomers = () => {
  const navigate = useNavigate();
  const { data, loading } = useGetFutureCustomerAttendanceQuery();

  if (loading) return <Loading />;

  return (
    <Page>
      <Fab icon={faPlus} onClick={() => navigate('/admin/createCustomer')} title="Kunden anlegen" />

      <PageHeader
        title="Aktive Kunden"
        extra={[<Input key={1} prefix={<FontAwesomeIcon icon={faSearch} />} />]}
      />
      <Content>
        <Card>
          <List
            pagination={false}
            itemLayout="horizontal"
            dataSource={data?.futureCustomerAttendance}
            renderItem={(attendance) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Link to={`/admin/customer/${attendance.customer.id}`}>
                      {attendance.customer.firstName} {attendance.customer.lastName}
                    </Link>
                  }
                  description={
                    <Space size={24}>
                      {attendance.customer.city && (
                        <Space>
                          <FontAwesomeIcon icon={faMapMarker} />
                          {attendance.customer.city}
                        </Space>
                      )}

                      {attendance.customer.dateOfBirth && (
                        <Space>
                          <FontAwesomeIcon icon={faBaby} />
                          {moment(attendance.customer.dateOfBirth).format('DD.MM.YYYY')}
                        </Space>
                      )}

                      <Space>
                        <FontAwesomeIcon icon={faClock} />
                        <span>
                          {moment(attendance.startDate).format('DD.MM.')} -{' '}
                          {moment(attendance.endDate).format('DD.MM.')}
                        </span>
                      </Space>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        </Card>
      </Content>
    </Page>
  );
};

export default ActiveCustomers;
