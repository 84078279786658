import React, { useEffect, useState } from 'react';
import { Button, Card, Result } from 'antd';
import BasicLayout from '../components/layout/BasicLayout';
import Loading from '../components/base/Loading';
import { useVerifyCustomerEmailAddressMutation } from '../graphql/schema';

const VerifyEmailAddress = () => {
  const [verify] = useVerifyCustomerEmailAddressMutation();
  const [loading, setLoading] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>('');

  useEffect(() => {
    const token = window.location.search.slice(7);
    (async () => {
      const response = await verify({
        variables: {
          token,
        },
      });
      if (response.data?.verifyCustomerEmailAddress.firstName) {
        setFirstName(response.data.verifyCustomerEmailAddress.firstName);
        setLoading(false);
      }
    })();
  }, []);

  if (loading)
    return (
      <BasicLayout>
        <Loading />
      </BasicLayout>
    );

  return (
    <BasicLayout>
      <Card>
        <div>
          <Result
            status="success"
            title={`Moin ${firstName}!`}
            subTitle="Vielen Dank, Deine E-Mail Adresse ist nun bestätigt."
            extra={
              <Button
                type="primary"
                onClick={() => {
                  window.location.href = 'https://rueckenwind-kitesurf.de';
                }}
              >
                Schau auf unserer Internetseite vorbei!
              </Button>
            }
          />
        </div>
      </Card>
    </BasicLayout>
  );
};

export default VerifyEmailAddress;
