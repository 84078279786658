import React from 'react';
import { Form, Input } from 'antd';
import { useCreateCourseMutation, useUpdateCourseAllocationMutation } from '../../graphql/schema';
import GenericEditDrawer from '../drawer/GenericEditDrawer';
import ProductForCourseSelect from '../form/select/ProductForCourseSelect';

export interface Props {
  courseAllocation: {
    id: number;
    comment?: string | null;
    product?: {
      id: number;
      title: string;
    } | null;
  };
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
}

interface CourseAllocationFormFields {
  comment: string;
  productId?: number;
}

const CourseAllocationDrawer = ({ courseAllocation, visible, onClose, onSave }: Props) => {
  const [update] = useUpdateCourseAllocationMutation();

  const handleSave = async (values: Partial<CourseAllocationFormFields>) => {
    await update({
      variables: {
        id: courseAllocation.id,
        dto: {
          comment: values.comment || '',
          productId: values.productId || null,
        },
      },
    });
    onSave();
  };

  return (
    <GenericEditDrawer<CourseAllocationFormFields>
      title="Kurszuordnung"
      visible={visible}
      onClose={onClose}
      onSave={handleSave}
      initialValues={{
        comment: courseAllocation.comment || '',
        productId: courseAllocation.product?.id || undefined,
      }}
    >
      <>
        <Form.Item name="productId" label="Produkt" rules={[{ required: false }]}>
          <ProductForCourseSelect placeholder="Produkt" />
        </Form.Item>

        <Form.Item name="comment" label="Kommentar" rules={[{ required: false }]}>
          <Input.TextArea placeholder="" rows={6} />
        </Form.Item>
      </>
    </GenericEditDrawer>
  );
};

export default CourseAllocationDrawer;
