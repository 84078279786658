import React from 'react';
import styles from './Logo.module.less';
import logoImage from '../../assets/logo-white.svg';
import primaryImage from '../../assets/logo.png';

interface Props {
  styling?: 'light' | 'dark' | 'primary';
}

const Logo = ({ styling }: Props) => {
  return (
    <div className={`${styles.logo} ${styling === 'dark' ? styles.dark : ''}`}>
      {styling === 'primary' && <img src={primaryImage} alt="Logo" />}
      {styling !== 'primary' && <img src={logoImage} alt="Logo" />}
    </div>
  );
};

export default Logo;
