import React, { useEffect } from 'react';
import { Button, DatePicker, Form, Space } from 'antd';
import moment from 'moment';
import { PageProps } from '../lib/pageProps';
import { Page } from '../lib/pageEnum';
import Attendance, {
  AttendanceFormFields,
} from '../../../components/customerRegistration/attendance';

const AttendancePage = ({ navigate, setState, state }: PageProps) => {
  const [form] = Form.useForm<AttendanceFormFields>();

  useEffect(() => {
    if (state.startDate && state.endDate) {
      form.setFieldsValue({
        attendance: [moment(state.startDate), moment(state.endDate)],
      });
    }
  }, []);

  const handleNext = async () => {
    try {
      const values = await form.validateFields();

      await setState({
        startDate: values.attendance[0].toDate(),
        endDate: values.attendance[1].toDate(),
      });

      navigate(Page.customerDetails);
    } catch (e) {
      console.log('ERRR');
      // TODO
    }
  };

  return (
    <div>
      <Space direction="vertical" size="large">
        <Attendance form={form} />
      </Space>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Button type="primary" onClick={handleNext}>
          Weiter
        </Button>
      </div>
    </div>
  );
};
export default AttendancePage;
