import React, { ReactElement, useEffect } from 'react';
import { Button, Drawer, Form, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import confirmModal from '../../helper/confirmModal';

export interface Props<FormFieldsType> {
  visible: boolean;
  onClose: () => void;
  initialValues?: Partial<FormFieldsType>;
  onSave: (values: FormFieldsType) => void;
  onDelete?: () => void;
  title: string;
  children: ReactElement;
}

function GenericEditDrawer<FormFieldsType>({
  visible,
  onClose,
  onSave,
  initialValues,
  onDelete,
  title,
  children,
}: Props<FormFieldsType>) {
  const [form] = Form.useForm<FormFieldsType>();

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
      form.setFieldsValue(initialValues as any);
    }
  }, [initialValues, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();

      await onSave(values);
      onClose();
    } catch (e) {
      notification.error({
        message: 'Fehler',
        description: 'Beim Speichern ist ein Fehler aufgetreten, bitte versuche es noch einmal.',
      });
    }
  };

  const handleDelete = async () => {
    if (!onDelete) return;

    if (
      await confirmModal({
        title: 'Löschen',
        content: 'Bitte bestätige das Löschen.',
        okText: 'Löschen',
        okType: 'danger',
        cancelText: 'Abbrechen',
      })
    ) {
      await onDelete();
      onClose();
    }
  };

  return (
    <Drawer
      title={title}
      visible={visible}
      onClose={onClose}
      extra={
        onDelete ? (
          <Button danger onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        ) : undefined
      }
    >
      <Form layout="vertical" form={form}>
        {children}
      </Form>
      <Button type="primary" onClick={handleSave}>
        Speichern
      </Button>
    </Drawer>
  );
}

export default GenericEditDrawer;
