import React from 'react';
import { Card, Space } from 'antd';
import formatAmount from '../../helper/formatAmount';
import { BillingPositionFragment } from '../../graphql/schema';

interface Props {
  customer: {
    firstName?: string;
    lastName?: string;
    street?: string;
    streetNumber?: string;
    zipCode?: string;
    city?: string;
  };
  positions: BillingPositionFragment[];
}

const InvoicePreview = ({ customer, positions }: Props) => {
  return (
    <Card>
      <Space
        direction="vertical"
        style={{
          width: '100%',
        }}
      >
        <p>
          {customer.firstName} {customer.lastName}
          <br />
          {customer.street} {customer.streetNumber}
          <br />
          {customer.zipCode} {customer.city}
        </p>
        <p>
          <strong>Rechnung</strong>
        </p>
        <table
          style={{
            width: '100%',
            textAlign: 'left',
          }}
        >
          <thead>
            <tr>
              <th>Beschreibung</th>
              <th
                style={{
                  width: 200,
                  textAlign: 'right',
                }}
              >
                Preis
              </th>
            </tr>
          </thead>
          <tbody>
            {positions.map((pos) => (
              <tr key={pos.id}>
                <td
                  style={{
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  {pos.title}
                  {pos.description && pos.description !== '' && (
                    <div
                      style={{
                        fontSize: 11,
                      }}
                    >
                      {pos.description}
                    </div>
                  )}
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    verticalAlign: 'top',
                  }}
                >
                  {formatAmount(pos.amount - (pos.amount * (pos.discount || 0)) / 100)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          style={{
            textAlign: 'right',
            fontWeight: 'bold',
          }}
        >
          {formatAmount(
            positions
              .map((pos) => {
                return pos.amount - (pos.amount * (pos.discount || 0)) / 100;
              })
              .reduce((sum, amount) => sum + amount),
          )}
        </div>
      </Space>
    </Card>
  );
};

export default InvoicePreview;
