import React from 'react';
import { useCreateVoucherMutation } from '../../graphql/schema';
import GenericAddDrawer from '../drawer/GenericAddDrawer';
import VoucherForm, { VoucherFormFields } from './VoucherForm';

export interface Props {
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
}

const AddVoucherDrawer = ({ visible, onClose, onSave }: Props) => {
  const [create] = useCreateVoucherMutation();

  const handleSave = async (values: Partial<VoucherFormFields>) => {
    await create({
      variables: {
        dto: {
          title: values.title || '',
          recipient: values.recipient || '',
          price: values.price || 0,
          text: values.text || '',
          invoiceFirstName: values.invoiceFirstName || '',
          invoiceLastName: values.invoiceLastName || '',
        },
      },
    });
    onSave();
  };

  return (
    <GenericAddDrawer<VoucherFormFields>
      title="Gutschein anlegen"
      visible={visible}
      onClose={onClose}
      onSave={handleSave}
    >
      <VoucherForm />
    </GenericAddDrawer>
  );
};

export default AddVoucherDrawer;
