import React from 'react';
import { useCreateUserMutation } from '../../graphql/schema';
import GenericAddDrawer from '../drawer/GenericAddDrawer';
import UserForm, { UserFormFields } from './UserForm';

export interface Props {
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
}

const AddUserDrawer = ({ visible, onClose, onSave }: Props) => {
  const [create] = useCreateUserMutation();

  const handleSave = async (values: Partial<UserFormFields>) => {
    await create({
      variables: {
        dto: {
          active: !!values.active,
          firstName: values.firstName || '',
          lastName: values.lastName || '',
          email: values.email || '',
          password: values.password || '',
          isAdmin: values.isAdmin || false,
        },
      },
    });
    onSave();
  };

  return (
    <GenericAddDrawer<UserFormFields>
      title="Nutzer anlegen"
      visible={visible}
      onClose={onClose}
      onSave={handleSave}
    >
      <UserForm />
    </GenericAddDrawer>
  );
};

export default AddUserDrawer;
