import React, { useState } from 'react';
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  List,
  PageHeader,
  Space,
  Statistic,
  message,
  Modal,
  Empty,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEyeSlash,
  faFileInvoice,
  faPencil,
  faPlus,
  faTimes,
  faShoppingBasket,
} from '@fortawesome/pro-light-svg-icons';
import Page from '../components/layout/Page';
import Content from '../components/layout/Content';
import Fab from '../components/layout/Fab';
import {
  BillingPositionFragment,
  CourseAllocationFragment,
  ProductFragment,
  useCreateBillingPositionMutation,
  useCreateInternalOrderMutation,
  useDeleteBillingPositionMutation,
  useGetBillingPositionsQuery,
  useGetCustomerWithOpenPaymentsQuery,
  useUpdateBillingPositionMutation,
  useUpdateOrderMutation,
} from '../graphql/schema';
import Loading from '../components/base/Loading';
import formatAmount from '../helper/formatAmount';
import useRouteParamAsNumber from '../helper/hooks/useParamAsNumber';
import GenericEditDrawer from '../components/drawer/GenericEditDrawer';
import confirmModal from '../helper/confirmModal';
import InvoicePreview from '../components/openPayment/InvoicePreview';
import ProductSelectModal from '../components/product/ProductSelectModal';
import SelectPaymentMethod from '../components/openPayment/SelectPaymentMethod';

interface BillingPositionFormFields {
  title: string;
  description: string;
  internalComment: string;
  amount: number;
  discount: number;
}

const OpenPayment = () => {
  const id = useRouteParamAsNumber('id');
  const navigate = useNavigate();

  const { data, loading, refetch } = useGetCustomerWithOpenPaymentsQuery({
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });

  const {
    data: billingPositions,
    refetch: refetchBillingPositions,
    loading: loadingBillingPositions,
  } = useGetBillingPositionsQuery({
    variables: {
      customer: id,
    },
  });

  const [createInternalOrder] = useCreateInternalOrderMutation();

  const [updateOrder] = useUpdateOrderMutation();

  const [createBillingPosition] = useCreateBillingPositionMutation();
  const [updateBillingPosition] = useUpdateBillingPositionMutation();
  const [deleteBillingPosition] = useDeleteBillingPositionMutation();

  const [productSelectVisible, setProductSelectVisible] = useState<boolean>(false);

  const [activeDrawerBillingPosition, setActiveDrawerBillingPosition] =
    useState<BillingPositionFragment>();

  const handleCreateInvoice = async () => {
    if (!billingPositions || billingPositions.billingPositions.length === 0) return;

    const sum = billingPositions.billingPositions.map((pos) => pos.amount).reduce((s, a) => s + a);

    if (sum < 0) {
      message.error(
        'Die Rechnung hat einen negativen Betrag! Wenn es ein Restguthaben gibt, erstelle zunächst einen Gutschein über den verbleibenden Betrag.',
      );
      return;
    }

    if (
      await confirmModal({
        title: 'Rechnung erstellen',
        okText: 'Rechnung erstellen',
        cancelText: 'Abbrechen',
        type: 'info',
        width: 800,
        content: (
          <InvoicePreview
            customer={{
              firstName: data?.customerWithOpenPayments.firstName || '',
              lastName: data?.customerWithOpenPayments.lastName || '',
              street: data?.customerWithOpenPayments.street || '',
              streetNumber: data?.customerWithOpenPayments.streetNumber || '',
              zipCode: data?.customerWithOpenPayments.zipCode || '',
              city: data?.customerWithOpenPayments.city || '',
            }}
            positions={billingPositions.billingPositions}
          />
        ),
      })
    ) {
      const period = billingPositions.billingPositions
        .filter((pos) => pos.courseAllocation && pos.courseAllocation.course.startDate)
        .map((pos) => pos.courseAllocation!.course.startDate)
        .sort((a, b) => (a && b ? b.valueOf() - a.valueOf() : 0));

      const periodStartDate = period.pop() || moment();
      const periodEndDate = period.shift() || periodStartDate;

      const response = await createInternalOrder({
        variables: {
          dto: {
            firstName: data?.customerWithOpenPayments.firstName || '',
            lastName: data?.customerWithOpenPayments.lastName || '',
            street: data?.customerWithOpenPayments.street || '',
            streetNumber: data?.customerWithOpenPayments.streetNumber || '',
            zipCode: data?.customerWithOpenPayments.zipCode || '',
            city: data?.customerWithOpenPayments.city || '',
            email: data?.customerWithOpenPayments.email || '',
            positions: billingPositions.billingPositions.map((pos) => {
              return {
                id: pos.id,
                title: pos.title,
                description: pos.description,
                amount: pos.amount,
                quantity: 1,
                discount: pos.discount,
                bookingId: pos.booking ? pos.booking.id : null,
                courseAllocationId: pos.courseAllocation ? pos.courseAllocation.id : null,
                productId: pos.product ? pos.product.id : null,
              };
            }),
            periodStartDate,
            periodEndDate,
          },
        },
      });

      refetch();
      refetchBillingPositions();

      const invoiceNumber = response.data?.createInternalOrder.invoiceNumber;
      const orderId = response.data?.createInternalOrder.order.id;

      if (invoiceNumber && orderId) {
        Modal.info({
          icon: false,
          title: 'Bezahlung',
          content: (
            <SelectPaymentMethod
              amount={sum}
              invoiceNumber={invoiceNumber}
              onSelect={(paymentMethod) => {
                updateOrder({
                  variables: {
                    id: orderId,
                    dto: {
                      paymentMethod,
                    },
                  },
                });
              }}
            />
          ),
          width: 600,
          okText: 'Schließen',
        });
      }
    }
  };

  const handleAddProduct = async (product: ProductFragment) => {
    await createBillingPosition({
      variables: {
        dto: {
          productId: product.id,
          title: product.isVoucher ? `Gutschein: ${product.title}` : product.title,
          description: '',
          internalComment: '',
          amount: product.price,
          customerId: id,
        },
      },
    });

    refetchBillingPositions();

    setProductSelectVisible(false);
  };

  const handleAddAllocation = (courseAllocation: CourseAllocationFragment) => {
    return async () => {
      await createBillingPosition({
        variables: {
          dto: {
            courseAllocationId: courseAllocation.id,
            productId: courseAllocation.product?.id,
            title: `${courseAllocation.course.title} (${moment(
              courseAllocation.course.startDate,
            ).format('DD.MM.YY, HH:mm')} - ${moment(courseAllocation.course.endDate).format(
              'HH:mm',
            )} Uhr)`,
            description: '',
            internalComment: courseAllocation.comment,
            amount: courseAllocation.amount,
            customerId: id,
          },
        },
      });

      refetchBillingPositions();
    };
  };

  const handleRemove = (position: BillingPositionFragment) => {
    return async () => {
      if (
        await confirmModal({
          title: 'Position löschen',
          content: 'Sicher, dass die Rechnungsposition entfernt werden soll?',
          okText: 'Entfernen',
          cancelText: 'Abbrechen',
        })
      ) {
        await deleteBillingPosition({
          variables: {
            id: position.id,
          },
        });

        refetchBillingPositions();
      }
    };
  };

  const handleSaveBillingPosition = async (values: Partial<BillingPositionFormFields>) => {
    if (!activeDrawerBillingPosition) return;

    await updateBillingPosition({
      variables: {
        id: activeDrawerBillingPosition.id,
        dto: {
          title: values.title || '',
          description: values.description,
          amount: values.amount || 0,
          discount: values.discount || 0,
          internalComment: values.internalComment,
        },
      },
    });

    refetchBillingPositions();
  };

  if (loading || !data || loadingBillingPositions || !billingPositions) return <Loading />;

  return (
    <Page>
      <Fab icon={faPlus} onClick={() => setProductSelectVisible(true)} />

      <PageHeader
        title={`${data.customerWithOpenPayments.firstName} ${data.customerWithOpenPayments.lastName}`}
        onBack={() => navigate(-1)}
        extra={[
          <Button key="invoice" type="primary" onClick={handleCreateInvoice}>
            <Space>
              <FontAwesomeIcon icon={faFileInvoice} />
              Rechnung erstellen
            </Space>
          </Button>,
        ]}
      />
      <Content>
        <h3>Abzurechnen aus Kursplanung</h3>
        <Card>
          <List
            pagination={false}
            itemLayout="horizontal"
            dataSource={data.customerWithOpenPayments.courseAllocations.filter(
              (courseAllocation) =>
                !billingPositions.billingPositions.find(
                  (pos) => pos.courseAllocation?.id === courseAllocation.id,
                ),
            )}
            renderItem={(courseAllocation) => (
              <List.Item>
                <List.Item.Meta
                  title={`${courseAllocation.course.title} (${moment(
                    courseAllocation.course.startDate,
                  ).format('DD.MM., HH:mm')} - ${moment(courseAllocation.course.endDate).format(
                    'HH:mm',
                  )} Uhr)`}
                  description={courseAllocation.comment}
                />
                <Space>
                  <div>{formatAmount(courseAllocation.amount || 0)}</div>
                  <Button onClick={handleAddAllocation(courseAllocation)}>
                    <FontAwesomeIcon icon={faShoppingBasket} />
                  </Button>
                </Space>
              </List.Item>
            )}
            locale={{
              emptyText: <Empty description="Keine Kurse gefunden" />,
            }}
          />
        </Card>

        <br />
        <h3>Neue Abrechnung</h3>
        <Card>
          <List
            pagination={false}
            itemLayout="horizontal"
            dataSource={billingPositions.billingPositions}
            renderItem={(position) => (
              <List.Item>
                <List.Item.Meta
                  title={position.title}
                  description={
                    <Space direction="vertical">
                      {position.internalComment && position.internalComment !== '' && (
                        <Space>
                          <FontAwesomeIcon icon={faEyeSlash} />
                          <span>{position.internalComment}</span>
                        </Space>
                      )}
                      {position.description && position.description !== '' && (
                        <div
                          style={{
                            color: '#000',
                          }}
                        >
                          {position.description}
                        </div>
                      )}
                    </Space>
                  }
                />
                <Space>
                  <div>
                    {formatAmount(
                      position.amount - (position.amount * (position.discount || 0)) / 100,
                    )}
                  </div>
                  <Button onClick={handleRemove(position)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                  <Button onClick={() => setActiveDrawerBillingPosition(position)}>
                    <FontAwesomeIcon icon={faPencil} />
                  </Button>
                </Space>
              </List.Item>
            )}
          />
        </Card>
      </Content>

      <GenericEditDrawer<BillingPositionFormFields>
        title="Rechnungsposition"
        visible={!!activeDrawerBillingPosition}
        onClose={() => setActiveDrawerBillingPosition(undefined)}
        onSave={handleSaveBillingPosition}
        initialValues={{
          amount: activeDrawerBillingPosition?.amount || 0,
          discount: activeDrawerBillingPosition?.discount || 0,
          description: activeDrawerBillingPosition?.description || '',
          title: activeDrawerBillingPosition?.title || '',
          internalComment: activeDrawerBillingPosition?.internalComment || '',
        }}
      >
        <>
          <Form.Item name="title" label="Titel" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="description" label="Beschreibung" rules={[{ required: false }]}>
            <Input />
          </Form.Item>

          <Form.Item name="amount" label="Preis" rules={[{ required: true }]}>
            <InputNumber addonAfter="€" decimalSeparator="," precision={2} />
          </Form.Item>

          <Form.Item name="discount" label="Rabatt" rules={[{ required: false }]}>
            <InputNumber addonAfter="%" decimalSeparator="," precision={2} />
          </Form.Item>

          <Form.Item
            name="internalComment"
            label="Interner Kommentar"
            rules={[{ required: false }]}
          >
            <Input.TextArea placeholder="" rows={6} />
          </Form.Item>
        </>
      </GenericEditDrawer>

      <ProductSelectModal
        visible={productSelectVisible}
        onClose={() => setProductSelectVisible(false)}
        onSelect={handleAddProduct}
      />
    </Page>
  );
};

export default OpenPayment;
