import React, { useState } from 'react';
import { PageHeader, List, Input, Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import Page from '../components/layout/Page';
import Content from '../components/layout/Content';
import Fab from '../components/layout/Fab';
import { useGetUsersLazyQuery, UserFragment } from '../graphql/schema';
import usePagination from '../helper/hooks/usePagination';
import Loading from '../components/base/Loading';
import EditUserDrawer from '../components/user/EditUserDrawer';
import AddUserDrawer from '../components/user/AddUserDrawer';

const Users = () => {
  const [getUsers, { data, loading, refetch }] = useGetUsersLazyQuery();
  const { pagination } = usePagination(getUsers);

  const [addDrawerVisible, setAddDrawerVisible] = useState<boolean>(false);
  const [selectedItemForEditDrawer, setSelectedItemForEditDrawer] = useState<UserFragment>();

  const handleSelect = (user: UserFragment) => {
    return () => {
      setSelectedItemForEditDrawer(user);
    };
  };

  if (loading) return <Loading />;

  return (
    <Page>
      <Fab icon={faPlus} onClick={() => setAddDrawerVisible(true)} title="Nutzer anlegen" />

      <PageHeader title="Nutzer" />
      <Content>
        <Card>
          <List
            pagination={pagination}
            itemLayout="horizontal"
            dataSource={data?.users.items}
            renderItem={(item) => (
              <List.Item
                style={{
                  alignItems: 'flex-start',
                }}
              >
                <List.Item.Meta
                  title={
                    <a onClick={handleSelect(item)}>
                      {item.firstName} {item.lastName}
                    </a>
                  }
                  description={item.email}
                />
              </List.Item>
            )}
          />
        </Card>
      </Content>

      <AddUserDrawer
        visible={addDrawerVisible}
        onClose={() => setAddDrawerVisible(false)}
        onSave={refetch}
      />

      <EditUserDrawer
        user={selectedItemForEditDrawer}
        visible={!!selectedItemForEditDrawer}
        onClose={() => setSelectedItemForEditDrawer(undefined)}
        onSave={refetch}
      />
    </Page>
  );
};

export default Users;
