import { useParams } from 'react-router-dom';

const useRouteParamAsNumber = (name: string): number => {
  const params = useParams();
  const param = params[name];
  if (!param) throw new Error(`Route param "${name}" not found`);
  return parseInt(param, 10);
};

export default useRouteParamAsNumber;
