import React, { useState } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, Space, Typography, DatePicker } from 'antd';
import { Moment } from 'moment';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import locale from 'antd/es/date-picker/locale/de_DE';
import 'moment/locale/de';

export interface PersonalDataFormFields {
  firstName?: string;
  lastName?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  city?: string;
  dateOfBirth?: Moment;
}

interface Props {
  form: FormInstance<PersonalDataFormFields>;
}

const PersonalData = ({ form }: Props) => {
  return (
    <Form layout="vertical" form={form}>
      <h1>Wer bist Du?</h1>

      <Typography.Paragraph type="secondary">
        Bitte nenne uns zunächst Deinen Namen und Deine Anschrift. Anschrift und Geburtsdatum sind
        optional, Du kannst Sie also auch zunächst leer lassen.
      </Typography.Paragraph>

      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="firstName"
            label="Vorname"
            rules={[{ required: true, message: 'Bitte gib Deinen Vornamen ein' }]}
          >
            <Input placeholder="Dein Vorname" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="lastName"
            label="Nachname"
            rules={[{ required: true, message: 'Bitte gib Deinen Nachnamen ein' }]}
          >
            <Input placeholder="Dein Nachname" />
          </Form.Item>
        </Col>
      </Row>

      <p>Anschrift</p>
      <div>
        <Row gutter={12}>
          <Col span={16}>
            <Form.Item name="street" rules={[{ required: false }]}>
              <Input placeholder="Straße" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="streetNumber" rules={[{ required: false }]}>
              <Input placeholder="Hausnr." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name="zipCode" rules={[{ required: false }]}>
              <Input placeholder="PLZ" />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name="city" rules={[{ required: false }]}>
              <Input placeholder="Ort" />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <Form.Item name="dateOfBirth" label="Geburtsdatum" rules={[{ required: false }]}>
        <DatePicker
          format="DD.MM.YYYY"
          placeholder="Datum auswählen"
          locale={locale}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode as any;
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default PersonalData;
