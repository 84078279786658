import React, { useEffect, useState } from 'react';
import { Steps } from 'antd';
import EmailPage from './pages/Email';
import ProductPage from './pages/Product';
import { Page } from './lib/pageEnum';
import { State } from './lib/state';
import LoginEmailPage from './pages/LoginEmail';
import CustomerDetailsPage from './pages/CustomerDetails';
import { useCustomerByTokenLazyQuery } from '../../graphql/schema';
import ContactInfoPage from './pages/ContactInfo';
import KiteLevelPage from './pages/KiteLevel';
import PaymentPage from './pages/Payment';
import BodyInfoPage from './pages/BodyInfo';
import AttendancePage from './pages/Attendance';
import CheckoutPage from './pages/Checkout';
import ConfirmationPage from './pages/Confirmation';

const Booking = () => {
  const checkToken = () => {
    const token = localStorage.getItem('customerToken');
    if (token) {
      const tokenParts = token.split('.');
      if (tokenParts.length === 3) {
        const tokenData: any = JSON.parse(atob(tokenParts[1]));
        if (tokenData.exp && tokenData.exp > new Date().getTime() / 1000) {
          return true;
        }
      }

      localStorage.removeItem('customerToken');
      localStorage.removeItem('booking');
      return false;
    }

    return true;
  };

  const loadState = () => {
    if (checkToken()) {
      const savedState = localStorage.getItem('booking');
      if (savedState) {
        const stateData = JSON.parse(savedState);
        if (stateData) {
          return stateData;
        }
      }
    }
    return {
      customer: {
        id: undefined,
        firstName: '',
        lastName: '',
        street: '',
        streetNumber: '',
        zipCode: '',
        city: '',
        phone: '',
        emergencyPhone: '',
        shoeSize: '',
        bodyHeight: '',
        bodyWeight: '',
        kiteLevel: undefined,
        comment: '',
        dateOfBirth: undefined,
        ownGear: false,
      },
    };
  };

  const [currentPage, setCurrentPage] = useState<Page>(Page.product);
  const [state, setState] = useState<State>(loadState());

  const [loadCustomerByToken] = useCustomerByTokenLazyQuery();

  useEffect(() => {
    const pageMatch = window.location.search.match(/page=([a-zA-Z0-9._-]+)/);
    if (pageMatch) {
      setCurrentPage(parseInt(pageMatch[1], 10) as unknown as Page);
      return;
    }

    if (window.location.search.indexOf('token=') === -1) return;

    const tokenMatch = window.location.search.match(/token=([a-zA-Z0-9._-]+)/);
    const token = tokenMatch ? tokenMatch[1] : null;

    if (!token || token === '') return;

    localStorage.setItem('customerToken', token);

    (async () => {
      // Load customer data
      const customer = await loadCustomerByToken({
        variables: {
          token,
        },
      });
      const customerByToken = customer?.data?.customerByToken;
      if (customerByToken) {
        setState((oldState) => {
          return {
            ...oldState,
            email: customerByToken.email || '',
            customer: {
              id: customerByToken.id || undefined,
              firstName: customerByToken.firstName || '',
              lastName: customerByToken.lastName || '',
              street: customerByToken.street || '',
              streetNumber: customerByToken.streetNumber || '',
              zipCode: customerByToken.zipCode || '',
              city: customerByToken.city || '',
              phone: customerByToken.phone || '',
              emergencyPhone: customerByToken.emergencyPhone || '',
              shoeSize: customerByToken.shoeSize || '',
              bodyHeight: customerByToken.bodyHeight || '',
              bodyWeight: customerByToken.bodyWeight || '',
              kiteLevel: customerByToken.kiteLevel || undefined,
              comment: customerByToken.comment || '',
              dateOfBirth: customerByToken.dateOfBirth || undefined,
              ownGear: customerByToken.ownGear || false,
            },
          };
        });

        const productIdMatch = window.location.search.match(/productId=([a-zA-Z0-9._-]+)/);
        const productId = productIdMatch ? productIdMatch[1] : null;

        if (productId) {
          setState((oldState) => {
            return {
              ...oldState,
              productId: parseInt(productId, 10),
            };
          });
          setCurrentPage(Page.attendance);
        } else if (state.productId) {
          setCurrentPage(Page.attendance);
        }
      }
    })();
  }, []);

  useEffect(() => {
    localStorage.setItem('booking', JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    setTimeout(() => {
      const widgets = document.getElementsByTagName('dts-widget');
      if (widgets.length > 0) {
        const y = widgets[0].getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 100);
  }, [currentPage]);

  const props = {
    navigate: (page: Page) => {
      setCurrentPage(page);
    },
    setState: (changes: Partial<State>) => {
      setState({
        ...state,
        ...changes,
      });
    },
    state,
  };

  const getCurrentPageIndex = () => {
    if (currentPage === Page.product) return 0;
    if (currentPage === Page.attendance) return 1;
    if (currentPage === Page.customerDetails) return 2;
    if (currentPage === Page.contactInfo) return 3;
    if (currentPage === Page.bodyInfo) return 4;
    if (currentPage === Page.kiteLevel) return 5;
    if (currentPage === Page.checkout) return 6;
    if (currentPage === Page.confirmation) return 7;
    if (currentPage === Page.email) return 1;

    return 0;
  };

  const jumpToPage = (newPage: Page) => {
    if (newPage >= currentPage) return;
    setCurrentPage(newPage);
  };

  return (
    <div>
      {(currentPage === Page.product ||
        currentPage === Page.email ||
        currentPage === Page.loginMail) && (
        <Steps
          size="small"
          current={getCurrentPageIndex()}
          style={{
            marginBottom: 25,
          }}
        >
          <Steps.Step
            title="Produktauswahl"
            stepNumber={1}
            stepIndex={0}
            onClick={() => setCurrentPage(Page.product)}
          />
          <Steps.Step
            title="Anmeldung"
            stepNumber={2}
            stepIndex={1}
            onClick={() => setCurrentPage(Page.email)}
          />
          <Steps.Step title="Buchung" stepNumber={3} stepIndex={2} />
        </Steps>
      )}
      {currentPage > Page.email && currentPage !== Page.loginMail && currentPage !== Page.payment && (
        <Steps
          size="small"
          current={getCurrentPageIndex()}
          style={{
            marginBottom: 25,
          }}
        >
          <Steps.Step
            title="Produktauswahl"
            stepNumber={1}
            stepIndex={0}
            onClick={() => jumpToPage(Page.product)}
          />
          <Steps.Step
            title="Zeitraum"
            stepNumber={2}
            stepIndex={1}
            onClick={() => jumpToPage(Page.attendance)}
          />
          <Steps.Step
            title="Persönliche Daten"
            stepNumber={3}
            stepIndex={2}
            onClick={() => jumpToPage(Page.customerDetails)}
          />
          <Steps.Step
            title="Kontakt"
            stepNumber={4}
            stepIndex={3}
            onClick={() => jumpToPage(Page.contactInfo)}
          />
          <Steps.Step
            title="Über Dich"
            stepNumber={5}
            stepIndex={4}
            onClick={() => jumpToPage(Page.bodyInfo)}
          />
          <Steps.Step
            title="Kite Level"
            stepNumber={6}
            stepIndex={5}
            onClick={() => jumpToPage(Page.kiteLevel)}
          />
          <Steps.Step
            title="Buchung"
            stepNumber={7}
            stepIndex={6}
            onClick={() => jumpToPage(Page.confirmation)}
          />
        </Steps>
      )}
      {currentPage === Page.product && <ProductPage {...props} />}
      {currentPage === Page.email && <EmailPage {...props} />}
      {currentPage === Page.loginMail && <LoginEmailPage {...props} />}
      {currentPage === Page.attendance && <AttendancePage {...props} />}
      {currentPage === Page.customerDetails && <CustomerDetailsPage {...props} />}
      {currentPage === Page.contactInfo && <ContactInfoPage {...props} />}
      {currentPage === Page.bodyInfo && <BodyInfoPage {...props} />}
      {currentPage === Page.kiteLevel && <KiteLevelPage {...props} />}
      {currentPage === Page.payment && <PaymentPage {...props} />}
      {currentPage === Page.checkout && <CheckoutPage {...props} />}
      {currentPage === Page.confirmation && <ConfirmationPage {...props} />}
    </div>
  );
};

export default Booking;
