import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../auth/useAuth';
import Loading from '../components/base/Loading';

const UseVoucher = () => {
  const { authIsLoading, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (authIsLoading) return;

    if (isAuthenticated) {
      navigate(`/admin/vouchers?code=${params.code}`);
    } else {
      window.location.href = 'https://rueckenwind-kitesurf.de';
    }
  }, [isAuthenticated, authIsLoading]);

  return <Loading />;
};

export default UseVoucher;
