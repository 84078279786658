import React from 'react';
import {
  VoucherFragment,
  useDeleteVoucherMutation,
  useUpdateVoucherMutation,
} from '../../graphql/schema';
import VoucherForm, { VoucherFormFields } from './VoucherForm';
import GenericEditDrawer from '../drawer/GenericEditDrawer';

export interface Props {
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
  voucher?: VoucherFragment;
}

const EditVoucherDrawer = ({ visible, onClose, onSave, voucher }: Props) => {
  const [update] = useUpdateVoucherMutation();
  const [deleteVoucher] = useDeleteVoucherMutation();

  const handleSave = async (values: Partial<VoucherFormFields>) => {
    if (!voucher) return;

    await update({
      variables: {
        id: voucher.id,
        dto: {
          title: values.title || '',
          recipient: values.recipient || '',
          price: values.price || 0,
          text: values.text || '',
          invoiceFirstName: values.invoiceFirstName || '',
          invoiceLastName: values.invoiceLastName || '',
        },
      },
    });

    onSave();
  };

  const handleDelete = async () => {
    if (!voucher) return;

    await deleteVoucher({
      variables: {
        id: voucher.id,
      },
    });
    onSave();
  };

  return (
    <GenericEditDrawer<VoucherFormFields>
      title="Gutschein bearbeiten"
      visible={visible}
      onClose={onClose}
      onSave={handleSave}
      onDelete={handleDelete}
      initialValues={voucher as VoucherFormFields}
    >
      <VoucherForm />
    </GenericEditDrawer>
  );
};

export default EditVoucherDrawer;
