import React, { useEffect, useState } from 'react';
import { Steps } from 'antd';
import ProductPage from './pages/Product';
import { Page } from './lib/pageEnum';
import { State } from './lib/state';
import PaymentPage from './pages/Payment';
import CheckoutPage from './pages/Checkout';
import DetailsPage from './pages/Details';

const VoucherShop = () => {
  const loadState = () => {
    const savedState = localStorage.getItem('voucherShop');
    if (savedState) {
      const stateData = JSON.parse(savedState);
      if (stateData) {
        return stateData;
      }
    }
    return {};
  };

  const [currentPage, setCurrentPage] = useState<Page>(Page.product);
  const [state, setState] = useState<State>(loadState());

  useEffect(() => {
    const pageMatch = window.location.search.match(/page=([a-zA-Z0-9._-]+)/);
    if (pageMatch) {
      setCurrentPage(parseInt(pageMatch[1], 10) as unknown as Page);
      return;
    }

    const productIdMatch = window.location.search.match(/productId=([a-zA-Z0-9._-]+)/);
    const productId = productIdMatch ? productIdMatch[1] : null;

    if (productId) {
      setState((oldState) => {
        return {
          ...oldState,
          productId: parseInt(productId, 10),
        };
      });
      setCurrentPage(Page.checkout);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('voucherShop', JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    if (currentPage === Page.product) return;

    setTimeout(() => {
      const widgets = document.getElementsByTagName('dts-widget');
      if (widgets.length > 0) {
        const y = widgets[0].getBoundingClientRect().top + window.scrollY - 140;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 100);
  }, [currentPage]);

  const props = {
    navigate: (page: Page) => {
      setCurrentPage(page);
    },
    setState: (changes: Partial<State>) => {
      setState({
        ...state,
        ...changes,
      });
    },
    state,
  };

  const getCurrentPageIndex = () => {
    if (currentPage === Page.product) return 0;
    if (currentPage === Page.details) return 1;
    if (currentPage === Page.checkout) return 2;
    if (currentPage === Page.payment) return 3;

    return 0;
  };

  const jumpToPage = (newPage: Page) => {
    if (newPage >= currentPage) return;
    setCurrentPage(newPage);
  };

  return (
    <div>
      {[Page.product, Page.details, Page.checkout].some((p) => p === currentPage) && (
        <Steps
          size="small"
          current={getCurrentPageIndex()}
          style={{
            marginBottom: 25,
          }}
        >
          <Steps.Step
            title="Produktauswahl"
            stepNumber={1}
            stepIndex={0}
            onClick={() => setCurrentPage(Page.product)}
          />
          <Steps.Step
            title="Details"
            stepNumber={2}
            stepIndex={1}
            onClick={() => setCurrentPage(Page.details)}
          />
          <Steps.Step
            title="Bestellung"
            stepNumber={3}
            stepIndex={2}
            onClick={() => setCurrentPage(Page.checkout)}
          />
        </Steps>
      )}
      {currentPage === Page.product && <ProductPage {...props} />}
      {currentPage === Page.details && <DetailsPage {...props} />}
      {currentPage === Page.checkout && <CheckoutPage {...props} />}
      {currentPage === Page.payment && <PaymentPage {...props} />}
    </div>
  );
};

export default VoucherShop;
