import React, { useState } from 'react';
import { Button, Card, Form, Result, Space } from 'antd';
import BasicLayout from '../components/layout/BasicLayout';
import PersonalData, {
  PersonalDataFormFields,
} from '../components/customerRegistration/personalData';
import ContactInfo, { ContactInfoFormFields } from '../components/customerRegistration/contactInfo';
import BodyInfo, { BodyInfoFormFields } from '../components/customerRegistration/bodyInfo';
import KiteLevel, { KiteLevelFormFields } from '../components/customerRegistration/kiteLevel';
import Terms, { TermsFormFields } from '../components/customerRegistration/terms';
import { useRegisterCustomerMutation } from '../graphql/schema';
import Loading from '../components/base/Loading';

interface Data
  extends PersonalDataFormFields,
    ContactInfoFormFields,
    BodyInfoFormFields,
    KiteLevelFormFields,
    TermsFormFields {}

const RegisterCustomer = () => {
  const [step, setStep] = useState<number>(0);

  const [register] = useRegisterCustomerMutation();

  const [data, setData] = useState<Data>({});
  const [loading, setLoading] = useState<boolean>(false);

  const [personalDataForm] = Form.useForm<PersonalDataFormFields>();
  const [contactInfoForm] = Form.useForm<ContactInfoFormFields>();
  const [bodyInfoForm] = Form.useForm<BodyInfoFormFields>();
  const [kiteLevelForm] = Form.useForm<KiteLevelFormFields>();
  const [termsForm] = Form.useForm<TermsFormFields>();

  const getCurrentForm = () => {
    if (step === 1) return personalDataForm;
    if (step === 2) return contactInfoForm;
    if (step === 3) return bodyInfoForm;
    if (step === 4) return kiteLevelForm;
    if (step === 5) return termsForm;

    return undefined;
  };

  const save = async (newData: Data) => {
    setData(newData);

    if (step < 5) {
      setStep(step + 1);
      return;
    }

    setLoading(true);

    if (newData.firstName && newData.lastName && newData.email && newData.phone && newData.terms) {
      const result = await register({
        variables: {
          dto: {
            bodyHeight: newData.bodyHeight,
            bodyWeight: newData.bodyWeight,
            city: newData.city,
            comment: newData.comment,
            dateOfBirth: newData.dateOfBirth,
            email: newData.email,
            emergencyPhone: newData.emergencyPhone,
            firstName: newData.firstName,
            kiteLevel: newData.kiteLevel,
            lastName: newData.lastName,
            newsletter: !!newData.newsletter,
            ownGear: !!newData.ownGear,
            phone: newData.phone,
            shoeSize: newData.shoeSize,
            street: newData.street,
            streetNumber: newData.streetNumber,
            zipCode: newData.zipCode,
          },
        },
      });
      if (!result.data) throw new Error('Unable to register');
    }
  };

  const handleNext = async () => {
    const currentForm = getCurrentForm();
    if (currentForm) {
      try {
        const values = await currentForm.validateFields();

        await save({
          ...data,
          ...values,
        });

        setStep(step + 1);
      } catch (e) {
        console.log('ERRR');
      }
      setLoading(false);
    } else {
      setStep(step + 1);
    }
  };

  if (loading)
    return (
      <BasicLayout>
        <Loading />
      </BasicLayout>
    );

  return (
    <BasicLayout>
      <Card>
        <div>
          {step === 0 && (
            <div>
              <h1>Moin!</h1>
              <p>
                Es freut uns sehr, dass Du mit uns auf&apos;s Wasser möchtest!
                <br />
                Um Deinen Kurs möglichst gut vorzubereiten, benötigen wir ein paar Informationen von
                Dir - das geht ganz schnell.
              </p>
            </div>
          )}
          {step === 1 && (
            <Space direction="vertical" size="large">
              <PersonalData form={personalDataForm} />
            </Space>
          )}
          {step === 2 && (
            <Space direction="vertical" size="large">
              <ContactInfo form={contactInfoForm} />
            </Space>
          )}
          {step === 3 && (
            <Space direction="vertical" size="large">
              <BodyInfo form={bodyInfoForm} />
            </Space>
          )}
          {step === 4 && <KiteLevel form={kiteLevelForm} />}
          {step === 5 && <Terms form={termsForm} />}
          {step === 6 && (
            <Result
              status="success"
              title="Willkommen an Bord!"
              subTitle="Vielen Dank für Deine Anmeldung!"
              extra={
                <Button
                  type="primary"
                  onClick={() => {
                    window.location.href = 'https://rueckenwind-kitesurf.de';
                  }}
                >
                  Schau auf unserer Internetseite vorbei!
                </Button>
              }
            />
          )}
        </div>
        {step < 6 && (
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button type="primary" onClick={handleNext}>
              Weiter
            </Button>
          </div>
        )}
      </Card>
    </BasicLayout>
  );
};

export default RegisterCustomer;
