import React from 'react';
import { Select } from 'antd';
import { useGetPublicProductCategoriesQuery } from '../../../graphql/schema';

interface Props {
  value?: number | null;
  onChange?: (id: number | null) => void;
  placeholder: string;
}

interface Option {
  label: string;
  value: number | null;
}

const ProductCategorySelect = ({ value, onChange, placeholder }: Props) => {
  const { data, loading } = useGetPublicProductCategoriesQuery();

  const options: Option[] =
    data?.publicProductCategories.map((category) => {
      return {
        label: category.name,
        value: category.id,
      };
    }) || [];

  options.unshift({
    label: 'Keine',
    value: null,
  });

  return (
    <Select
      placeholder={placeholder}
      onChange={onChange}
      loading={loading}
      value={value}
      options={options}
    />
  );
};

export default ProductCategorySelect;
