import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type Attendance = {
  __typename?: 'Attendance';
  booking?: Maybe<Booking>;
  customer: Customer;
  desiredProduct?: Maybe<Product>;
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  startDate: Scalars['DateTime'];
};

export type BillingPosition = {
  __typename?: 'BillingPosition';
  amount: Scalars['Float'];
  booking?: Maybe<Booking>;
  courseAllocation?: Maybe<CourseAllocation>;
  customer: Customer;
  description?: Maybe<Scalars['String']>;
  discount: Scalars['Float'];
  id: Scalars['Int'];
  internalComment?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  title: Scalars['String'];
};

export type Booking = {
  __typename?: 'Booking';
  amount: Scalars['Float'];
  cleared: Scalars['Boolean'];
  customer: Customer;
  id: Scalars['Int'];
  paid: Scalars['Boolean'];
  paymentId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ContactInput = {
  email?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type Course = {
  __typename?: 'Course';
  allocations: Array<CourseAllocation>;
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  instructor: Instructor;
  spot: Spot;
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
};

export type CourseAllocation = {
  __typename?: 'CourseAllocation';
  amount?: Maybe<Scalars['Float']>;
  cleared: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  course: Course;
  customer: Customer;
  id: Scalars['Int'];
  product?: Maybe<Product>;
};

export type CreateBillingPositionInput = {
  amount?: InputMaybe<Scalars['Float']>;
  bookingId?: InputMaybe<Scalars['Int']>;
  courseAllocationId?: InputMaybe<Scalars['Int']>;
  customerId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Float']>;
  internalComment?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateCourseAllocationInput = {
  comment?: InputMaybe<Scalars['String']>;
  customerId: Scalars['Float'];
  productId?: InputMaybe<Scalars['Float']>;
};

export type CreateCourseInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  instructorId?: InputMaybe<Scalars['Float']>;
  spotId?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerAttendanceInput = {
  desiredProductId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type CreateCustomerInput = {
  attendance?: InputMaybe<CreateCustomerAttendanceInput>;
  bodyHeight?: InputMaybe<Scalars['String']>;
  bodyWeight?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createBooking?: InputMaybe<Scalars['Boolean']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emergencyPhone?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  internalComment?: InputMaybe<Scalars['String']>;
  kiteLevel?: InputMaybe<Scalars['Float']>;
  lastName?: InputMaybe<Scalars['String']>;
  newsletter?: InputMaybe<Scalars['Boolean']>;
  ownGear?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  redirectURL?: InputMaybe<Scalars['String']>;
  shoeSize?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CreateInstructorAvailabilityInput = {
  comment?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateInstructorInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateInternalOrderInput = {
  city: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  periodEndDate: Scalars['DateTime'];
  periodStartDate: Scalars['DateTime'];
  positions: Array<InternalOrderPositionInput>;
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CreateInternalOrderResponse = {
  __typename?: 'CreateInternalOrderResponse';
  invoiceNumber: Scalars['String'];
  order: Order;
};

export type CreateOrderInput = {
  city: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  positions: Array<OrderPositionInput>;
  redirectURL?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CreateOrderResponse = {
  __typename?: 'CreateOrderResponse';
  orderId: Scalars['Float'];
  paymentId?: Maybe<Scalars['String']>;
  paymentLink?: Maybe<Scalars['String']>;
};

export type CreateProductCategoryInput = {
  name?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

export type CreateProductInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  isVoucher?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  visibleInCourseBooking?: InputMaybe<Scalars['Boolean']>;
  visibleInShop?: InputMaybe<Scalars['Boolean']>;
  voucherPrefix?: InputMaybe<Scalars['String']>;
};

export type CreateSpotInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  directions?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateUserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type CreateVoucherInput = {
  invoiceFirstName?: InputMaybe<Scalars['String']>;
  invoiceLastName?: InputMaybe<Scalars['String']>;
  price: Scalars['Float'];
  recipient: Scalars['String'];
  text: Scalars['String'];
  title: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  attendances: Array<Attendance>;
  bodyHeight?: Maybe<Scalars['String']>;
  bodyWeight?: Maybe<Scalars['String']>;
  bookings: Array<Booking>;
  city?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  courseAllocations: Array<CourseAllocation>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emergencyPhone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  internalComment?: Maybe<Scalars['String']>;
  kiteLevel?: Maybe<Scalars['Float']>;
  lastBookingAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Scalars['Boolean']>;
  ownGear?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  shoeSize?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type FilterInput = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortType>;
};

export type FilteredCourses = {
  __typename?: 'FilteredCourses';
  items: Array<Course>;
  itemsPerPage: Scalars['Int'];
  numberOfItems: Scalars['Int'];
  page: Scalars['Int'];
};

export type FilteredCustomers = {
  __typename?: 'FilteredCustomers';
  items: Array<Customer>;
  itemsPerPage: Scalars['Int'];
  numberOfItems: Scalars['Int'];
  page: Scalars['Int'];
};

export type FilteredInstructors = {
  __typename?: 'FilteredInstructors';
  items: Array<Instructor>;
  itemsPerPage: Scalars['Int'];
  numberOfItems: Scalars['Int'];
  page: Scalars['Int'];
};

export type FilteredProductCategories = {
  __typename?: 'FilteredProductCategories';
  items: Array<ProductCategory>;
  itemsPerPage: Scalars['Int'];
  numberOfItems: Scalars['Int'];
  page: Scalars['Int'];
};

export type FilteredProducts = {
  __typename?: 'FilteredProducts';
  items: Array<Product>;
  itemsPerPage: Scalars['Int'];
  numberOfItems: Scalars['Int'];
  page: Scalars['Int'];
};

export type FilteredSpots = {
  __typename?: 'FilteredSpots';
  items: Array<Spot>;
  itemsPerPage: Scalars['Int'];
  numberOfItems: Scalars['Int'];
  page: Scalars['Int'];
};

export type FilteredUsers = {
  __typename?: 'FilteredUsers';
  items: Array<User>;
  itemsPerPage: Scalars['Int'];
  numberOfItems: Scalars['Int'];
  page: Scalars['Int'];
};

export type FilteredVouchers = {
  __typename?: 'FilteredVouchers';
  items: Array<Voucher>;
  itemsPerPage: Scalars['Int'];
  numberOfItems: Scalars['Int'];
  page: Scalars['Int'];
};

export type Instructor = {
  __typename?: 'Instructor';
  active: Scalars['Boolean'];
  availabilities?: Maybe<Array<InstructorAvailability>>;
  color?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type InstructorAvailability = {
  __typename?: 'InstructorAvailability';
  comment: Scalars['String'];
  endDate: Scalars['String'];
  id: Scalars['Int'];
  instructor: Instructor;
  startDate: Scalars['String'];
  type: Scalars['String'];
};

export type InternalOrderPositionInput = {
  amount: Scalars['Float'];
  bookingId?: InputMaybe<Scalars['Int']>;
  courseAllocationId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Float']>;
  productId?: InputMaybe<Scalars['Int']>;
  quantity: Scalars['Float'];
  title: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  access_token: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createBillingPosition: BillingPosition;
  createCourse: Course;
  createCourseAllocation: Course;
  createCustomer: Customer;
  createCustomerAttendance: Customer;
  createInstructor: Instructor;
  createInstructorAvailability: Instructor;
  createInternalOrder: CreateInternalOrderResponse;
  createInvoiceForVoucher: Voucher;
  createOrder: CreateOrderResponse;
  createProduct: Product;
  createProductCategory: ProductCategory;
  createSpot: Spot;
  createUser: User;
  createVoucher: Voucher;
  deleteBillingPosition: BillingPosition;
  deleteCourse: Course;
  deleteCourseAllocation: Course;
  deleteCustomer: Customer;
  deleteCustomerAttendance: Customer;
  deleteInstructor: Instructor;
  deleteInstructorAvailability: Instructor;
  deleteProduct: Product;
  deleteProductCategory: ProductCategory;
  deleteSpot: Spot;
  deleteUser: User;
  deleteVoucher: Voucher;
  login: LoginResponse;
  registerCustomer: RegisterCustomerResponse;
  sendContactMessage: Scalars['Boolean'];
  sendCustomerLoginMail: Scalars['Boolean'];
  updateBillingPosition: BillingPosition;
  updateCourse: Course;
  updateCourseAllocation: Course;
  updateCustomer: Customer;
  updateCustomerWithToken: UpdateCustomerWithTokenResponse;
  updateInstructor: Instructor;
  updateOrder: Order;
  updateProduct: Product;
  updateProductCategory: ProductCategory;
  updateSpot: Spot;
  updateUser: User;
  updateVoucher: Voucher;
  useVoucher: Voucher;
  verifyCustomerEmailAddress: VerifyCustomerEmailAddressResponse;
};


export type MutationCreateBillingPositionArgs = {
  dto: CreateBillingPositionInput;
};


export type MutationCreateCourseArgs = {
  dto: CreateCourseInput;
};


export type MutationCreateCourseAllocationArgs = {
  courseId: Scalars['Int'];
  dto: CreateCourseAllocationInput;
};


export type MutationCreateCustomerArgs = {
  dto: CreateCustomerInput;
};


export type MutationCreateCustomerAttendanceArgs = {
  customerId: Scalars['Int'];
  dto: CreateCustomerAttendanceInput;
};


export type MutationCreateInstructorArgs = {
  dto: CreateInstructorInput;
};


export type MutationCreateInstructorAvailabilityArgs = {
  dto: CreateInstructorAvailabilityInput;
  instructorId: Scalars['Int'];
};


export type MutationCreateInternalOrderArgs = {
  dto: CreateInternalOrderInput;
};


export type MutationCreateInvoiceForVoucherArgs = {
  id: Scalars['Int'];
};


export type MutationCreateOrderArgs = {
  dto: CreateOrderInput;
};


export type MutationCreateProductArgs = {
  dto: CreateProductInput;
};


export type MutationCreateProductCategoryArgs = {
  dto: CreateProductCategoryInput;
};


export type MutationCreateSpotArgs = {
  dto: CreateSpotInput;
};


export type MutationCreateUserArgs = {
  dto: CreateUserInput;
};


export type MutationCreateVoucherArgs = {
  dto: CreateVoucherInput;
};


export type MutationDeleteBillingPositionArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCourseArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCourseAllocationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCustomerAttendanceArgs = {
  customerId: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationDeleteInstructorArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteInstructorAvailabilityArgs = {
  id: Scalars['Int'];
  instructorId: Scalars['Int'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteProductCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSpotArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteVoucherArgs = {
  id: Scalars['Int'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRegisterCustomerArgs = {
  dto: RegisterCustomerInput;
};


export type MutationSendContactMessageArgs = {
  dto: ContactInput;
};


export type MutationSendCustomerLoginMailArgs = {
  callbackURL: Scalars['String'];
  email: Scalars['String'];
};


export type MutationUpdateBillingPositionArgs = {
  dto: UpdateBillingPositionInput;
  id: Scalars['Int'];
};


export type MutationUpdateCourseArgs = {
  dto: UpdateCourseInput;
  id: Scalars['Int'];
};


export type MutationUpdateCourseAllocationArgs = {
  dto: UpdateCourseAllocationInput;
  id: Scalars['Int'];
};


export type MutationUpdateCustomerArgs = {
  dto: UpdateCustomerInput;
  id: Scalars['Int'];
};


export type MutationUpdateCustomerWithTokenArgs = {
  dto: UpdateCustomerWithTokenInput;
  token: Scalars['String'];
};


export type MutationUpdateInstructorArgs = {
  dto: UpdateInstructorInput;
  id: Scalars['Int'];
};


export type MutationUpdateOrderArgs = {
  dto: UpdateOrderInput;
  id: Scalars['Int'];
};


export type MutationUpdateProductArgs = {
  dto: UpdateProductInput;
  id: Scalars['Int'];
};


export type MutationUpdateProductCategoryArgs = {
  dto: UpdateProductCategoryInput;
  id: Scalars['Int'];
};


export type MutationUpdateSpotArgs = {
  dto: UpdateSpotInput;
  id: Scalars['Int'];
};


export type MutationUpdateUserArgs = {
  dto: UpdateUserInput;
  id: Scalars['Int'];
};


export type MutationUpdateVoucherArgs = {
  dto: UpdateVoucherInput;
  id: Scalars['Int'];
};


export type MutationUseVoucherArgs = {
  id: Scalars['Int'];
};


export type MutationVerifyCustomerEmailAddressArgs = {
  token: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  amount: Scalars['Float'];
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invoiceNumber?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  periodEndDate: Scalars['DateTime'];
  periodStartDate: Scalars['DateTime'];
  positions: Array<OrderPosition>;
  status: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OrderPosition = {
  __typename?: 'OrderPosition';
  amount: Scalars['Float'];
  booking?: Maybe<Booking>;
  courseAllocation?: Maybe<CourseAllocation>;
  data?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discount: Scalars['Float'];
  id: Scalars['Int'];
  order: Order;
  product?: Maybe<Product>;
  quantity: Scalars['Float'];
  title: Scalars['String'];
};

export type OrderPositionInput = {
  data?: InputMaybe<Scalars['String']>;
  productId: Scalars['Float'];
  quantity: Scalars['Float'];
};

export type PaymentStatusResponse = {
  __typename?: 'PaymentStatusResponse';
  status: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  active: Scalars['Boolean'];
  category?: Maybe<ProductCategory>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image: Scalars['String'];
  isVoucher: Scalars['Boolean'];
  price: Scalars['Float'];
  title: Scalars['String'];
  visibleInCourseBooking: Scalars['Boolean'];
  visibleInShop: Scalars['Boolean'];
  voucherPrefix?: Maybe<Scalars['String']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['Int'];
  name: Scalars['String'];
  sorting: Scalars['Int'];
  uid: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  billingPosition: BillingPosition;
  billingPositions: Array<BillingPosition>;
  checkPayment: PaymentStatusResponse;
  course: Course;
  courses: FilteredCourses;
  coursesInDateRange: Array<Course>;
  customer: Customer;
  customerAttendances: Array<Attendance>;
  customerByToken: Customer;
  customerExists: Scalars['Boolean'];
  customerWithOpenPayments: Customer;
  customers: FilteredCustomers;
  customersWithOpenPayments: Array<Customer>;
  futureCourses: FilteredCourses;
  futureCustomerAttendance: Array<Attendance>;
  instructor: Instructor;
  instructorAvailabilities: Array<InstructorAvailability>;
  instructors: FilteredInstructors;
  order: Order;
  orders: Array<Order>;
  ownUser: User;
  product: Product;
  productCategories: FilteredProductCategories;
  productCategory: ProductCategory;
  products: FilteredProducts;
  publicProductCategories: Array<ProductCategory>;
  publicProducts: FilteredProducts;
  publicProductsForBooking: Array<Product>;
  publicProductsForVoucherShop: Array<Product>;
  spot: Spot;
  spots: FilteredSpots;
  user: User;
  users: FilteredUsers;
  voucher: Voucher;
  voucherDiscount: Scalars['Int'];
  vouchers: FilteredVouchers;
};


export type QueryBillingPositionArgs = {
  id: Scalars['Int'];
};


export type QueryBillingPositionsArgs = {
  customer: Scalars['Int'];
};


export type QueryCheckPaymentArgs = {
  orderId: Scalars['Int'];
};


export type QueryCourseArgs = {
  id: Scalars['Int'];
};


export type QueryCoursesArgs = {
  filter?: InputMaybe<FilterInput>;
};


export type QueryCoursesInDateRangeArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryCustomerArgs = {
  id: Scalars['Int'];
};


export type QueryCustomerAttendancesArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryCustomerByTokenArgs = {
  token: Scalars['String'];
};


export type QueryCustomerExistsArgs = {
  email: Scalars['String'];
};


export type QueryCustomerWithOpenPaymentsArgs = {
  id: Scalars['Int'];
};


export type QueryCustomersArgs = {
  filter?: InputMaybe<FilterInput>;
};


export type QueryFutureCoursesArgs = {
  filter?: InputMaybe<FilterInput>;
};


export type QueryInstructorArgs = {
  id: Scalars['Int'];
};


export type QueryInstructorAvailabilitiesArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryInstructorsArgs = {
  filter?: InputMaybe<FilterInput>;
};


export type QueryOrderArgs = {
  id: Scalars['Int'];
};


export type QueryProductArgs = {
  id: Scalars['Int'];
};


export type QueryProductCategoriesArgs = {
  filter?: InputMaybe<FilterInput>;
};


export type QueryProductCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryProductsArgs = {
  filter?: InputMaybe<FilterInput>;
};


export type QueryPublicProductsArgs = {
  filter?: InputMaybe<FilterInput>;
};


export type QuerySpotArgs = {
  id: Scalars['Int'];
};


export type QuerySpotsArgs = {
  filter?: InputMaybe<FilterInput>;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<FilterInput>;
};


export type QueryVoucherArgs = {
  id: Scalars['Int'];
};


export type QueryVouchersArgs = {
  filter?: InputMaybe<FilterInput>;
};

export type RegisterCustomerInput = {
  attendance?: InputMaybe<CreateCustomerAttendanceInput>;
  bodyHeight?: InputMaybe<Scalars['String']>;
  bodyWeight?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createBooking?: InputMaybe<Scalars['Boolean']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emergencyPhone?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  kiteLevel?: InputMaybe<Scalars['Float']>;
  lastName?: InputMaybe<Scalars['String']>;
  newsletter?: InputMaybe<Scalars['Boolean']>;
  ownGear?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  redirectURL?: InputMaybe<Scalars['String']>;
  shoeSize?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type RegisterCustomerResponse = {
  __typename?: 'RegisterCustomerResponse';
  customerId: Scalars['Float'];
  customerToken?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  paymentLink?: Maybe<Scalars['String']>;
};

export type SortType = {
  direction: Scalars['String'];
  field: Scalars['String'];
};

export type Spot = {
  __typename?: 'Spot';
  active: Scalars['Boolean'];
  address?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type UpdateBillingPositionInput = {
  amount: Scalars['Float'];
  bookingId?: InputMaybe<Scalars['Int']>;
  courseAllocationId?: InputMaybe<Scalars['Int']>;
  customerId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  discount: Scalars['Float'];
  internalComment?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type UpdateCourseAllocationInput = {
  comment: Scalars['String'];
  productId?: InputMaybe<Scalars['Float']>;
};

export type UpdateCourseInput = {
  endDate: Scalars['DateTime'];
  instructorId: Scalars['Float'];
  spotId: Scalars['Float'];
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
};

export type UpdateCustomerInput = {
  attendance?: InputMaybe<CreateCustomerAttendanceInput>;
  bodyHeight?: InputMaybe<Scalars['String']>;
  bodyWeight?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createBooking?: InputMaybe<Scalars['Boolean']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emergencyPhone?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  internalComment?: InputMaybe<Scalars['String']>;
  kiteLevel?: InputMaybe<Scalars['Float']>;
  lastName?: InputMaybe<Scalars['String']>;
  newsletter?: InputMaybe<Scalars['Boolean']>;
  ownGear?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  redirectURL?: InputMaybe<Scalars['String']>;
  shoeSize?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerWithTokenInput = {
  attendance?: InputMaybe<CreateCustomerAttendanceInput>;
  bodyHeight?: InputMaybe<Scalars['String']>;
  bodyWeight?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createBooking?: InputMaybe<Scalars['Boolean']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  emergencyPhone?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  newsletter?: InputMaybe<Scalars['Boolean']>;
  ownGear?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  redirectURL?: InputMaybe<Scalars['String']>;
  shoeSize?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerWithTokenResponse = {
  __typename?: 'UpdateCustomerWithTokenResponse';
  customerId: Scalars['Float'];
  paymentId?: Maybe<Scalars['String']>;
  paymentLink?: Maybe<Scalars['String']>;
};

export type UpdateInstructorInput = {
  active: Scalars['Boolean'];
  color?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UpdateOrderInput = {
  paymentMethod: Scalars['String'];
};

export type UpdateProductCategoryInput = {
  name: Scalars['String'];
  uid: Scalars['String'];
};

export type UpdateProductInput = {
  active: Scalars['Boolean'];
  categoryId?: InputMaybe<Scalars['Float']>;
  description: Scalars['String'];
  image: Scalars['String'];
  isVoucher: Scalars['Boolean'];
  price: Scalars['Float'];
  title: Scalars['String'];
  visibleInCourseBooking: Scalars['Boolean'];
  visibleInShop: Scalars['Boolean'];
  voucherPrefix?: InputMaybe<Scalars['String']>;
};

export type UpdateSpotInput = {
  active: Scalars['Boolean'];
  address: Scalars['String'];
  comment: Scalars['String'];
  directions: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  name: Scalars['String'];
};

export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type UpdateVoucherInput = {
  invoiceFirstName?: InputMaybe<Scalars['String']>;
  invoiceLastName?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  recipient?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
  lastName: Scalars['String'];
};

export type VerifyCustomerEmailAddressResponse = {
  __typename?: 'VerifyCustomerEmailAddressResponse';
  firstName: Scalars['String'];
};

export type Voucher = {
  __typename?: 'Voucher';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  invoiceCreated?: Maybe<Scalars['Boolean']>;
  invoiceFirstName?: Maybe<Scalars['String']>;
  invoiceLastName?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  recipient: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  used: Scalars['Boolean'];
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', access_token: string } };

export type BillingPositionFragment = { __typename?: 'BillingPosition', id: number, description?: string | null, amount: number, discount: number, title: string, internalComment?: string | null, product?: { __typename?: 'Product', id: number } | null, courseAllocation?: { __typename?: 'CourseAllocation', id: number, course: { __typename?: 'Course', startDate: any } } | null, booking?: { __typename?: 'Booking', id: number } | null };

export type CreateBillingPositionMutationVariables = Exact<{
  dto: CreateBillingPositionInput;
}>;


export type CreateBillingPositionMutation = { __typename?: 'Mutation', createBillingPosition: { __typename?: 'BillingPosition', id: number, description?: string | null, amount: number, discount: number, title: string, internalComment?: string | null, product?: { __typename?: 'Product', id: number } | null, courseAllocation?: { __typename?: 'CourseAllocation', id: number, course: { __typename?: 'Course', startDate: any } } | null, booking?: { __typename?: 'Booking', id: number } | null } };

export type DeleteBillingPositionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteBillingPositionMutation = { __typename?: 'Mutation', deleteBillingPosition: { __typename?: 'BillingPosition', id: number } };

export type UpdateBillingPositionMutationVariables = Exact<{
  id: Scalars['Int'];
  dto: UpdateBillingPositionInput;
}>;


export type UpdateBillingPositionMutation = { __typename?: 'Mutation', updateBillingPosition: { __typename?: 'BillingPosition', id: number } };

export type GetBillingPositionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetBillingPositionQuery = { __typename?: 'Query', billingPosition: { __typename?: 'BillingPosition', id: number, description?: string | null, amount: number, discount: number, title: string, internalComment?: string | null, product?: { __typename?: 'Product', id: number } | null, courseAllocation?: { __typename?: 'CourseAllocation', id: number, course: { __typename?: 'Course', startDate: any } } | null, booking?: { __typename?: 'Booking', id: number } | null } };

export type GetBillingPositionsQueryVariables = Exact<{
  customer: Scalars['Int'];
}>;


export type GetBillingPositionsQuery = { __typename?: 'Query', billingPositions: Array<{ __typename?: 'BillingPosition', id: number, description?: string | null, amount: number, discount: number, title: string, internalComment?: string | null, product?: { __typename?: 'Product', id: number } | null, courseAllocation?: { __typename?: 'CourseAllocation', id: number, course: { __typename?: 'Course', startDate: any } } | null, booking?: { __typename?: 'Booking', id: number } | null }> };

export type SendContactMessageMutationVariables = Exact<{
  dto: ContactInput;
}>;


export type SendContactMessageMutation = { __typename?: 'Mutation', sendContactMessage: boolean };

export type CourseFragment = { __typename?: 'Course', id: number, title: string, startDate: any, endDate: any, spot: { __typename?: 'Spot', id: number, name: string }, instructor: { __typename?: 'Instructor', id: number, name: string }, allocations: Array<{ __typename?: 'CourseAllocation', comment?: string | null, id: number, customer: { __typename?: 'Customer', id: number, firstName?: string | null, lastName?: string | null, shoeSize?: string | null, bodyHeight?: string | null, bodyWeight?: string | null, kiteLevel?: number | null, comment?: string | null, internalComment?: string | null, dateOfBirth?: any | null, ownGear?: boolean | null }, product?: { __typename?: 'Product', id: number, title: string } | null }> };

export type CreateCourseMutationVariables = Exact<{
  dto: CreateCourseInput;
}>;


export type CreateCourseMutation = { __typename?: 'Mutation', createCourse: { __typename?: 'Course', id: number } };

export type CreateCourseAllocationMutationVariables = Exact<{
  id: Scalars['Int'];
  dto: CreateCourseAllocationInput;
}>;


export type CreateCourseAllocationMutation = { __typename?: 'Mutation', createCourseAllocation: { __typename?: 'Course', id: number } };

export type DeleteCourseAllocationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCourseAllocationMutation = { __typename?: 'Mutation', deleteCourseAllocation: { __typename?: 'Course', id: number } };

export type UpdateCourseMutationVariables = Exact<{
  id: Scalars['Int'];
  dto: UpdateCourseInput;
}>;


export type UpdateCourseMutation = { __typename?: 'Mutation', updateCourse: { __typename?: 'Course', id: number } };

export type UpdateCourseAllocationMutationVariables = Exact<{
  id: Scalars['Int'];
  dto: UpdateCourseAllocationInput;
}>;


export type UpdateCourseAllocationMutation = { __typename?: 'Mutation', updateCourseAllocation: { __typename?: 'Course', id: number } };

export type GetCoursesInDateRangeQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetCoursesInDateRangeQuery = { __typename?: 'Query', coursesInDateRange: Array<{ __typename?: 'Course', id: number, title: string, startDate: any, endDate: any }> };

export type GetFutureCoursesQueryVariables = Exact<{
  filter?: InputMaybe<FilterInput>;
}>;


export type GetFutureCoursesQuery = { __typename?: 'Query', futureCourses: { __typename?: 'FilteredCourses', page: number, numberOfItems: number, items: Array<{ __typename?: 'Course', id: number, title: string, startDate: any, endDate: any, spot: { __typename?: 'Spot', id: number, name: string }, instructor: { __typename?: 'Instructor', id: number, name: string }, allocations: Array<{ __typename?: 'CourseAllocation', comment?: string | null, id: number, customer: { __typename?: 'Customer', id: number, firstName?: string | null, lastName?: string | null, shoeSize?: string | null, bodyHeight?: string | null, bodyWeight?: string | null, kiteLevel?: number | null, comment?: string | null, internalComment?: string | null, dateOfBirth?: any | null, ownGear?: boolean | null }, product?: { __typename?: 'Product', id: number, title: string } | null }> }> } };

export type AttendanceFragment = { __typename?: 'Attendance', id: number, startDate: any, endDate: any };

export type CourseAllocationFragment = { __typename?: 'CourseAllocation', id: number, amount?: number | null, comment?: string | null, product?: { __typename?: 'Product', id: number, title: string } | null, course: { __typename?: 'Course', title: string, startDate: any, endDate: any, instructor: { __typename?: 'Instructor', name: string }, spot: { __typename?: 'Spot', name: string } } };

export type CustomerFragment = { __typename?: 'Customer', id: number, firstName?: string | null, lastName?: string | null, street?: string | null, streetNumber?: string | null, zipCode?: string | null, city?: string | null, email?: string | null, phone?: string | null, emergencyPhone?: string | null, shoeSize?: string | null, bodyHeight?: string | null, bodyWeight?: string | null, kiteLevel?: number | null, lastBookingAt?: any | null, comment?: string | null, internalComment?: string | null, dateOfBirth?: any | null, ownGear?: boolean | null, newsletter?: boolean | null, attendances: Array<{ __typename?: 'Attendance', id: number, startDate: any, endDate: any }>, courseAllocations: Array<{ __typename?: 'CourseAllocation', id: number, comment?: string | null, course: { __typename?: 'Course', startDate: any, endDate: any, title: string, instructor: { __typename?: 'Instructor', name: string }, spot: { __typename?: 'Spot', name: string } } }> };

export type CreateCustomerMutationVariables = Exact<{
  dto: CreateCustomerInput;
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', createCustomer: { __typename?: 'Customer', id: number, firstName?: string | null, lastName?: string | null, street?: string | null, streetNumber?: string | null, zipCode?: string | null, city?: string | null, email?: string | null, phone?: string | null, emergencyPhone?: string | null, shoeSize?: string | null, bodyHeight?: string | null, bodyWeight?: string | null, kiteLevel?: number | null, lastBookingAt?: any | null, comment?: string | null, internalComment?: string | null, dateOfBirth?: any | null, ownGear?: boolean | null, newsletter?: boolean | null, attendances: Array<{ __typename?: 'Attendance', id: number, startDate: any, endDate: any }>, courseAllocations: Array<{ __typename?: 'CourseAllocation', id: number, comment?: string | null, course: { __typename?: 'Course', startDate: any, endDate: any, title: string, instructor: { __typename?: 'Instructor', name: string }, spot: { __typename?: 'Spot', name: string } } }> } };

export type CreateCustomerAttendanceMutationVariables = Exact<{
  id: Scalars['Int'];
  dto: CreateCustomerAttendanceInput;
}>;


export type CreateCustomerAttendanceMutation = { __typename?: 'Mutation', createCustomerAttendance: { __typename?: 'Customer', id: number } };

export type DeleteCustomerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCustomerMutation = { __typename?: 'Mutation', deleteCustomer: { __typename?: 'Customer', id: number } };

export type DeleteCustomerAttendanceMutationVariables = Exact<{
  id: Scalars['Int'];
  customerId: Scalars['Int'];
}>;


export type DeleteCustomerAttendanceMutation = { __typename?: 'Mutation', deleteCustomerAttendance: { __typename?: 'Customer', id: number } };

export type RegisterCustomerMutationVariables = Exact<{
  dto: RegisterCustomerInput;
}>;


export type RegisterCustomerMutation = { __typename?: 'Mutation', registerCustomer: { __typename?: 'RegisterCustomerResponse', paymentLink?: string | null, paymentId?: string | null, customerToken?: string | null, customerId: number } };

export type SendCustomerLoginMailMutationVariables = Exact<{
  email: Scalars['String'];
  callbackURL: Scalars['String'];
}>;


export type SendCustomerLoginMailMutation = { __typename?: 'Mutation', sendCustomerLoginMail: boolean };

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars['Int'];
  dto: UpdateCustomerInput;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomer: { __typename?: 'Customer', id: number, firstName?: string | null, lastName?: string | null, street?: string | null, streetNumber?: string | null, zipCode?: string | null, city?: string | null, email?: string | null, phone?: string | null, emergencyPhone?: string | null, shoeSize?: string | null, bodyHeight?: string | null, bodyWeight?: string | null, kiteLevel?: number | null, lastBookingAt?: any | null, comment?: string | null, internalComment?: string | null, dateOfBirth?: any | null, ownGear?: boolean | null, newsletter?: boolean | null, attendances: Array<{ __typename?: 'Attendance', id: number, startDate: any, endDate: any }>, courseAllocations: Array<{ __typename?: 'CourseAllocation', id: number, comment?: string | null, course: { __typename?: 'Course', startDate: any, endDate: any, title: string, instructor: { __typename?: 'Instructor', name: string }, spot: { __typename?: 'Spot', name: string } } }> } };

export type UpdateCustomerWithTokenMutationVariables = Exact<{
  dto: UpdateCustomerWithTokenInput;
  token: Scalars['String'];
}>;


export type UpdateCustomerWithTokenMutation = { __typename?: 'Mutation', updateCustomerWithToken: { __typename?: 'UpdateCustomerWithTokenResponse', paymentLink?: string | null, paymentId?: string | null } };

export type VerifyCustomerEmailAddressMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyCustomerEmailAddressMutation = { __typename?: 'Mutation', verifyCustomerEmailAddress: { __typename?: 'VerifyCustomerEmailAddressResponse', firstName: string } };

export type CustomerByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type CustomerByTokenQuery = { __typename?: 'Query', customerByToken: { __typename?: 'Customer', id: number, firstName?: string | null, lastName?: string | null, street?: string | null, streetNumber?: string | null, zipCode?: string | null, city?: string | null, email?: string | null, phone?: string | null, emergencyPhone?: string | null, shoeSize?: string | null, bodyHeight?: string | null, bodyWeight?: string | null, kiteLevel?: number | null, comment?: string | null, dateOfBirth?: any | null, ownGear?: boolean | null } };

export type CustomerExistsQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CustomerExistsQuery = { __typename?: 'Query', customerExists: boolean };

export type GetCustomerQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCustomerQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: number, firstName?: string | null, lastName?: string | null, street?: string | null, streetNumber?: string | null, zipCode?: string | null, city?: string | null, email?: string | null, phone?: string | null, emergencyPhone?: string | null, shoeSize?: string | null, bodyHeight?: string | null, bodyWeight?: string | null, kiteLevel?: number | null, lastBookingAt?: any | null, comment?: string | null, internalComment?: string | null, dateOfBirth?: any | null, ownGear?: boolean | null, newsletter?: boolean | null, attendances: Array<{ __typename?: 'Attendance', id: number, startDate: any, endDate: any }>, courseAllocations: Array<{ __typename?: 'CourseAllocation', id: number, comment?: string | null, course: { __typename?: 'Course', startDate: any, endDate: any, title: string, instructor: { __typename?: 'Instructor', name: string }, spot: { __typename?: 'Spot', name: string } } }> } };

export type GetCustomerAttendancesQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetCustomerAttendancesQuery = { __typename?: 'Query', customerAttendances: Array<{ __typename?: 'Attendance', id: number, startDate: any, endDate: any, booking?: { __typename?: 'Booking', id: number, paid: boolean } | null, desiredProduct?: { __typename?: 'Product', title: string } | null, customer: { __typename?: 'Customer', firstName?: string | null, lastName?: string | null } }> };

export type GetCustomerWithOpenPaymentsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCustomerWithOpenPaymentsQuery = { __typename?: 'Query', customerWithOpenPayments: { __typename?: 'Customer', id: number, firstName?: string | null, lastName?: string | null, street?: string | null, streetNumber?: string | null, zipCode?: string | null, city?: string | null, email?: string | null, courseAllocations: Array<{ __typename?: 'CourseAllocation', id: number, amount?: number | null, comment?: string | null, product?: { __typename?: 'Product', id: number, title: string } | null, course: { __typename?: 'Course', title: string, startDate: any, endDate: any, instructor: { __typename?: 'Instructor', name: string }, spot: { __typename?: 'Spot', name: string } } }>, bookings: Array<{ __typename?: 'Booking', id: number, title: string, amount: number, paid: boolean }> } };

export type GetCustomersQueryVariables = Exact<{
  filter?: InputMaybe<FilterInput>;
}>;


export type GetCustomersQuery = { __typename?: 'Query', customers: { __typename?: 'FilteredCustomers', page: number, numberOfItems: number, items: Array<{ __typename?: 'Customer', id: number, firstName?: string | null, lastName?: string | null, dateOfBirth?: any | null, city?: string | null }> } };

export type GetCustomersWithOpenPaymentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomersWithOpenPaymentsQuery = { __typename?: 'Query', customersWithOpenPayments: Array<{ __typename?: 'Customer', id: number, firstName?: string | null, lastName?: string | null, courseAllocations: Array<{ __typename?: 'CourseAllocation', id: number, amount?: number | null, comment?: string | null, product?: { __typename?: 'Product', title: string } | null, course: { __typename?: 'Course', title: string, startDate: any, endDate: any, instructor: { __typename?: 'Instructor', name: string }, spot: { __typename?: 'Spot', name: string } } }>, bookings: Array<{ __typename?: 'Booking', id: number, title: string, amount: number, paid: boolean }> }> };

export type GetFutureCustomerAttendanceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFutureCustomerAttendanceQuery = { __typename?: 'Query', futureCustomerAttendance: Array<{ __typename?: 'Attendance', id: number, startDate: any, endDate: any, customer: { __typename?: 'Customer', id: number, firstName?: string | null, lastName?: string | null, city?: string | null, kiteLevel?: number | null, dateOfBirth?: any | null }, booking?: { __typename?: 'Booking', id: number, title: string, paid: boolean } | null, desiredProduct?: { __typename?: 'Product', id: number, title: string } | null }> };

export type InstructorFragment = { __typename?: 'Instructor', id: number, active: boolean, name: string, color?: string | null, availabilities?: Array<{ __typename?: 'InstructorAvailability', id: number, startDate: string, endDate: string, comment: string, type: string }> | null };

export type InstructorAvailabilityFragment = { __typename?: 'InstructorAvailability', id: number, startDate: string, endDate: string, comment: string, type: string };

export type CreateInstructorMutationVariables = Exact<{
  dto: CreateInstructorInput;
}>;


export type CreateInstructorMutation = { __typename?: 'Mutation', createInstructor: { __typename?: 'Instructor', id: number } };

export type CreateInstructorAvailabilityMutationVariables = Exact<{
  instructorId: Scalars['Int'];
  dto: CreateInstructorAvailabilityInput;
}>;


export type CreateInstructorAvailabilityMutation = { __typename?: 'Mutation', createInstructorAvailability: { __typename?: 'Instructor', id: number } };

export type DeleteInstructorMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInstructorMutation = { __typename?: 'Mutation', deleteInstructor: { __typename?: 'Instructor', id: number, active: boolean, name: string, color?: string | null, availabilities?: Array<{ __typename?: 'InstructorAvailability', id: number, startDate: string, endDate: string, comment: string, type: string }> | null } };

export type DeleteInstructorAvailabilityMutationVariables = Exact<{
  instructorId: Scalars['Int'];
  id: Scalars['Int'];
}>;


export type DeleteInstructorAvailabilityMutation = { __typename?: 'Mutation', deleteInstructorAvailability: { __typename?: 'Instructor', id: number } };

export type UpdateInstructorMutationVariables = Exact<{
  id: Scalars['Int'];
  dto: UpdateInstructorInput;
}>;


export type UpdateInstructorMutation = { __typename?: 'Mutation', updateInstructor: { __typename?: 'Instructor', id: number, active: boolean, name: string, color?: string | null, availabilities?: Array<{ __typename?: 'InstructorAvailability', id: number, startDate: string, endDate: string, comment: string, type: string }> | null } };

export type GetInstructorQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetInstructorQuery = { __typename?: 'Query', instructor: { __typename?: 'Instructor', id: number, active: boolean, name: string, color?: string | null, availabilities?: Array<{ __typename?: 'InstructorAvailability', id: number, startDate: string, endDate: string, comment: string, type: string }> | null } };

export type GetInstructorAvailabilitiesQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetInstructorAvailabilitiesQuery = { __typename?: 'Query', instructorAvailabilities: Array<{ __typename?: 'InstructorAvailability', id: number, startDate: string, endDate: string, comment: string, type: string, instructor: { __typename?: 'Instructor', id: number, active: boolean, name: string, color?: string | null, availabilities?: Array<{ __typename?: 'InstructorAvailability', id: number, startDate: string, endDate: string, comment: string, type: string }> | null } }> };

export type GetInstructorsQueryVariables = Exact<{
  filter?: InputMaybe<FilterInput>;
}>;


export type GetInstructorsQuery = { __typename?: 'Query', instructors: { __typename?: 'FilteredInstructors', page: number, numberOfItems: number, items: Array<{ __typename?: 'Instructor', id: number, active: boolean, name: string, color?: string | null, availabilities?: Array<{ __typename?: 'InstructorAvailability', id: number, startDate: string, endDate: string, comment: string, type: string }> | null }> } };

export type GetInstructorsForSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInstructorsForSelectQuery = { __typename?: 'Query', instructors: { __typename?: 'FilteredInstructors', items: Array<{ __typename?: 'Instructor', id: number, name: string }> } };

export type CreateInternalOrderMutationVariables = Exact<{
  dto: CreateInternalOrderInput;
}>;


export type CreateInternalOrderMutation = { __typename?: 'Mutation', createInternalOrder: { __typename?: 'CreateInternalOrderResponse', invoiceNumber: string, order: { __typename?: 'Order', id: number } } };

export type CreateOrderMutationVariables = Exact<{
  dto: CreateOrderInput;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'CreateOrderResponse', paymentId?: string | null, orderId: number, paymentLink?: string | null } };

export type UpdateOrderMutationVariables = Exact<{
  id: Scalars['Int'];
  dto: UpdateOrderInput;
}>;


export type UpdateOrderMutation = { __typename?: 'Mutation', updateOrder: { __typename?: 'Order', id: number } };

export type CheckPaymentQueryVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type CheckPaymentQuery = { __typename?: 'Query', checkPayment: { __typename?: 'PaymentStatusResponse', status: string } };

export type ProductFragment = { __typename?: 'Product', id: number, active: boolean, title: string, description?: string | null, price: number, isVoucher: boolean, visibleInShop: boolean, visibleInCourseBooking: boolean, image: string, voucherPrefix?: string | null, category?: { __typename?: 'ProductCategory', id: number, name: string, uid: string } | null };

export type CreateProductMutationVariables = Exact<{
  dto: CreateProductInput;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct: { __typename?: 'Product', id: number } };

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductMutation = { __typename?: 'Mutation', deleteProduct: { __typename?: 'Product', id: number, active: boolean, title: string, description?: string | null, price: number, isVoucher: boolean, visibleInShop: boolean, visibleInCourseBooking: boolean, image: string, voucherPrefix?: string | null, category?: { __typename?: 'ProductCategory', id: number, name: string, uid: string } | null } };

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['Int'];
  dto: UpdateProductInput;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct: { __typename?: 'Product', id: number, active: boolean, title: string, description?: string | null, price: number, isVoucher: boolean, visibleInShop: boolean, visibleInCourseBooking: boolean, image: string, voucherPrefix?: string | null, category?: { __typename?: 'ProductCategory', id: number, name: string, uid: string } | null } };

export type GetProductsQueryVariables = Exact<{
  filter?: InputMaybe<FilterInput>;
}>;


export type GetProductsQuery = { __typename?: 'Query', products: { __typename?: 'FilteredProducts', page: number, numberOfItems: number, items: Array<{ __typename?: 'Product', id: number, active: boolean, title: string, description?: string | null, price: number, isVoucher: boolean, visibleInShop: boolean, visibleInCourseBooking: boolean, image: string, voucherPrefix?: string | null, category?: { __typename?: 'ProductCategory', id: number, name: string, uid: string } | null }> } };

export type GetProductsForBookingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductsForBookingQuery = { __typename?: 'Query', publicProductsForBooking: Array<{ __typename?: 'Product', id: number, title: string, price: number, description?: string | null, image: string, category?: { __typename?: 'ProductCategory', id: number, name: string, uid: string } | null }> };

export type GetProductsForCourseSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductsForCourseSelectQuery = { __typename?: 'Query', products: { __typename?: 'FilteredProducts', items: Array<{ __typename?: 'Product', id: number, title: string }> } };

export type GetProductsForVoucherShopQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductsForVoucherShopQuery = { __typename?: 'Query', voucherDiscount: number, publicProductsForVoucherShop: Array<{ __typename?: 'Product', id: number, title: string, price: number, description?: string | null, image: string, category?: { __typename?: 'ProductCategory', id: number, name: string, uid: string, sorting: number } | null }> };

export type ProductCategoryFragment = { __typename?: 'ProductCategory', id: number, name: string, uid: string };

export type CreateProductCategoryMutationVariables = Exact<{
  dto: CreateProductCategoryInput;
}>;


export type CreateProductCategoryMutation = { __typename?: 'Mutation', createProductCategory: { __typename?: 'ProductCategory', id: number } };

export type DeleteProductCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductCategoryMutation = { __typename?: 'Mutation', deleteProductCategory: { __typename?: 'ProductCategory', id: number, name: string, uid: string } };

export type UpdateProductCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  dto: UpdateProductCategoryInput;
}>;


export type UpdateProductCategoryMutation = { __typename?: 'Mutation', updateProductCategory: { __typename?: 'ProductCategory', id: number, name: string, uid: string } };

export type GetProductCategoriesQueryVariables = Exact<{
  filter?: InputMaybe<FilterInput>;
}>;


export type GetProductCategoriesQuery = { __typename?: 'Query', productCategories: { __typename?: 'FilteredProductCategories', page: number, numberOfItems: number, items: Array<{ __typename?: 'ProductCategory', id: number, name: string, uid: string }> } };

export type GetPublicProductCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPublicProductCategoriesQuery = { __typename?: 'Query', publicProductCategories: Array<{ __typename?: 'ProductCategory', id: number, name: string, uid: string }> };

export type SpotFragment = { __typename?: 'Spot', id: number, name: string, active: boolean, comment?: string | null, address?: string | null, directions?: string | null, lat?: number | null, lng?: number | null };

export type CreateSpotMutationVariables = Exact<{
  dto: CreateSpotInput;
}>;


export type CreateSpotMutation = { __typename?: 'Mutation', createSpot: { __typename?: 'Spot', id: number } };

export type DeleteSpotMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSpotMutation = { __typename?: 'Mutation', deleteSpot: { __typename?: 'Spot', id: number, name: string, active: boolean, comment?: string | null, address?: string | null, directions?: string | null, lat?: number | null, lng?: number | null } };

export type UpdateSpotMutationVariables = Exact<{
  id: Scalars['Int'];
  dto: UpdateSpotInput;
}>;


export type UpdateSpotMutation = { __typename?: 'Mutation', updateSpot: { __typename?: 'Spot', id: number, name: string, active: boolean, comment?: string | null, address?: string | null, directions?: string | null, lat?: number | null, lng?: number | null } };

export type GetSpotsQueryVariables = Exact<{
  filter?: InputMaybe<FilterInput>;
}>;


export type GetSpotsQuery = { __typename?: 'Query', spots: { __typename?: 'FilteredSpots', page: number, numberOfItems: number, items: Array<{ __typename?: 'Spot', id: number, name: string, active: boolean, comment?: string | null, address?: string | null, directions?: string | null, lat?: number | null, lng?: number | null }> } };

export type GetSpotsForSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSpotsForSelectQuery = { __typename?: 'Query', spots: { __typename?: 'FilteredSpots', items: Array<{ __typename?: 'Spot', id: number, name: string }> } };

export type UserFragment = { __typename?: 'User', id: number, email: string, firstName: string, lastName: string, active: boolean, isAdmin: boolean };

export type CreateUserMutationVariables = Exact<{
  dto: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: number, email: string, firstName: string, lastName: string, active: boolean, isAdmin: boolean } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: number, email: string, firstName: string, lastName: string, active: boolean, isAdmin: boolean } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Int'];
  dto: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: number, email: string, firstName: string, lastName: string, active: boolean, isAdmin: boolean } };

export type GetOwnUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOwnUserQuery = { __typename?: 'Query', ownUser: { __typename?: 'User', id: number, active: boolean, firstName: string, lastName: string, email: string } };

export type GetUsersQueryVariables = Exact<{
  filter?: InputMaybe<FilterInput>;
}>;


export type GetUsersQuery = { __typename?: 'Query', users: { __typename?: 'FilteredUsers', page: number, numberOfItems: number, items: Array<{ __typename?: 'User', id: number, email: string, firstName: string, lastName: string, active: boolean, isAdmin: boolean }> } };

export type VoucherFragment = { __typename?: 'Voucher', id: number, title: string, text?: string | null, price: number, code: string, createdAt: any, recipient: string, used: boolean, usedAt?: any | null, invoiceFirstName?: string | null, invoiceLastName?: string | null, invoiceCreated?: boolean | null };

export type CreateInvoiceForVoucherMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CreateInvoiceForVoucherMutation = { __typename?: 'Mutation', createInvoiceForVoucher: { __typename?: 'Voucher', id: number, title: string, text?: string | null, price: number, code: string, createdAt: any, recipient: string, used: boolean, usedAt?: any | null, invoiceFirstName?: string | null, invoiceLastName?: string | null, invoiceCreated?: boolean | null } };

export type CreateVoucherMutationVariables = Exact<{
  dto: CreateVoucherInput;
}>;


export type CreateVoucherMutation = { __typename?: 'Mutation', createVoucher: { __typename?: 'Voucher', id: number, title: string, text?: string | null, price: number, code: string, createdAt: any, recipient: string, used: boolean, usedAt?: any | null, invoiceFirstName?: string | null, invoiceLastName?: string | null, invoiceCreated?: boolean | null } };

export type DeleteVoucherMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteVoucherMutation = { __typename?: 'Mutation', deleteVoucher: { __typename?: 'Voucher', id: number, title: string, text?: string | null, price: number, code: string, createdAt: any, recipient: string, used: boolean, usedAt?: any | null, invoiceFirstName?: string | null, invoiceLastName?: string | null, invoiceCreated?: boolean | null } };

export type UpdateVoucherMutationVariables = Exact<{
  id: Scalars['Int'];
  dto: UpdateVoucherInput;
}>;


export type UpdateVoucherMutation = { __typename?: 'Mutation', updateVoucher: { __typename?: 'Voucher', id: number, title: string, text?: string | null, price: number, code: string, createdAt: any, recipient: string, used: boolean, usedAt?: any | null, invoiceFirstName?: string | null, invoiceLastName?: string | null, invoiceCreated?: boolean | null } };

export type UseVoucherMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UseVoucherMutation = { __typename?: 'Mutation', useVoucher: { __typename?: 'Voucher', id: number } };

export type GetVouchersQueryVariables = Exact<{
  filter?: InputMaybe<FilterInput>;
}>;


export type GetVouchersQuery = { __typename?: 'Query', vouchers: { __typename?: 'FilteredVouchers', page: number, numberOfItems: number, items: Array<{ __typename?: 'Voucher', id: number, title: string, text?: string | null, price: number, code: string, createdAt: any, recipient: string, used: boolean, usedAt?: any | null, invoiceFirstName?: string | null, invoiceLastName?: string | null, invoiceCreated?: boolean | null }> } };

export const BillingPositionFragmentDoc = gql`
    fragment BillingPosition on BillingPosition {
  id
  description
  amount
  discount
  title
  internalComment
  product {
    id
  }
  courseAllocation {
    id
    course {
      startDate
    }
  }
  booking {
    id
  }
}
    `;
export const CourseFragmentDoc = gql`
    fragment Course on Course {
  id
  title
  spot {
    id
    name
  }
  instructor {
    id
    name
  }
  startDate
  endDate
  allocations {
    customer {
      id
      firstName
      lastName
      shoeSize
      bodyHeight
      bodyWeight
      kiteLevel
      comment
      internalComment
      dateOfBirth
      ownGear
    }
    comment
    id
    product {
      id
      title
    }
  }
}
    `;
export const CourseAllocationFragmentDoc = gql`
    fragment CourseAllocation on CourseAllocation {
  id
  amount
  product {
    id
    title
  }
  course {
    title
    startDate
    endDate
    instructor {
      name
    }
    spot {
      name
    }
  }
  comment
}
    `;
export const AttendanceFragmentDoc = gql`
    fragment Attendance on Attendance {
  id
  startDate
  endDate
}
    `;
export const CustomerFragmentDoc = gql`
    fragment Customer on Customer {
  id
  firstName
  lastName
  street
  streetNumber
  zipCode
  city
  email
  phone
  emergencyPhone
  shoeSize
  bodyHeight
  bodyWeight
  kiteLevel
  lastBookingAt
  comment
  internalComment
  dateOfBirth
  ownGear
  newsletter
  attendances {
    ...Attendance
  }
  courseAllocations {
    id
    comment
    course {
      startDate
      endDate
      title
      instructor {
        name
      }
      spot {
        name
      }
    }
  }
}
    ${AttendanceFragmentDoc}`;
export const InstructorAvailabilityFragmentDoc = gql`
    fragment InstructorAvailability on InstructorAvailability {
  id
  startDate
  endDate
  comment
  type
}
    `;
export const InstructorFragmentDoc = gql`
    fragment Instructor on Instructor {
  id
  active
  name
  color
  availabilities {
    ...InstructorAvailability
  }
}
    ${InstructorAvailabilityFragmentDoc}`;
export const ProductCategoryFragmentDoc = gql`
    fragment ProductCategory on ProductCategory {
  id
  name
  uid
}
    `;
export const ProductFragmentDoc = gql`
    fragment Product on Product {
  id
  active
  title
  description
  price
  isVoucher
  visibleInShop
  visibleInCourseBooking
  image
  category {
    ...ProductCategory
  }
  voucherPrefix
}
    ${ProductCategoryFragmentDoc}`;
export const SpotFragmentDoc = gql`
    fragment Spot on Spot {
  id
  name
  active
  comment
  address
  directions
  lat
  lng
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
  firstName
  lastName
  active
  isAdmin
}
    `;
export const VoucherFragmentDoc = gql`
    fragment Voucher on Voucher {
  id
  title
  text
  price
  code
  createdAt
  recipient
  used
  usedAt
  invoiceFirstName
  invoiceLastName
  invoiceCreated
}
    `;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    access_token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CreateBillingPositionDocument = gql`
    mutation CreateBillingPosition($dto: CreateBillingPositionInput!) {
  createBillingPosition(dto: $dto) {
    ...BillingPosition
  }
}
    ${BillingPositionFragmentDoc}`;
export type CreateBillingPositionMutationFn = Apollo.MutationFunction<CreateBillingPositionMutation, CreateBillingPositionMutationVariables>;

/**
 * __useCreateBillingPositionMutation__
 *
 * To run a mutation, you first call `useCreateBillingPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingPositionMutation, { data, loading, error }] = useCreateBillingPositionMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateBillingPositionMutation(baseOptions?: Apollo.MutationHookOptions<CreateBillingPositionMutation, CreateBillingPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBillingPositionMutation, CreateBillingPositionMutationVariables>(CreateBillingPositionDocument, options);
      }
export type CreateBillingPositionMutationHookResult = ReturnType<typeof useCreateBillingPositionMutation>;
export type CreateBillingPositionMutationResult = Apollo.MutationResult<CreateBillingPositionMutation>;
export type CreateBillingPositionMutationOptions = Apollo.BaseMutationOptions<CreateBillingPositionMutation, CreateBillingPositionMutationVariables>;
export const DeleteBillingPositionDocument = gql`
    mutation DeleteBillingPosition($id: Int!) {
  deleteBillingPosition(id: $id) {
    id
  }
}
    `;
export type DeleteBillingPositionMutationFn = Apollo.MutationFunction<DeleteBillingPositionMutation, DeleteBillingPositionMutationVariables>;

/**
 * __useDeleteBillingPositionMutation__
 *
 * To run a mutation, you first call `useDeleteBillingPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillingPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillingPositionMutation, { data, loading, error }] = useDeleteBillingPositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBillingPositionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBillingPositionMutation, DeleteBillingPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBillingPositionMutation, DeleteBillingPositionMutationVariables>(DeleteBillingPositionDocument, options);
      }
export type DeleteBillingPositionMutationHookResult = ReturnType<typeof useDeleteBillingPositionMutation>;
export type DeleteBillingPositionMutationResult = Apollo.MutationResult<DeleteBillingPositionMutation>;
export type DeleteBillingPositionMutationOptions = Apollo.BaseMutationOptions<DeleteBillingPositionMutation, DeleteBillingPositionMutationVariables>;
export const UpdateBillingPositionDocument = gql`
    mutation UpdateBillingPosition($id: Int!, $dto: UpdateBillingPositionInput!) {
  updateBillingPosition(dto: $dto, id: $id) {
    id
  }
}
    `;
export type UpdateBillingPositionMutationFn = Apollo.MutationFunction<UpdateBillingPositionMutation, UpdateBillingPositionMutationVariables>;

/**
 * __useUpdateBillingPositionMutation__
 *
 * To run a mutation, you first call `useUpdateBillingPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingPositionMutation, { data, loading, error }] = useUpdateBillingPositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateBillingPositionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillingPositionMutation, UpdateBillingPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBillingPositionMutation, UpdateBillingPositionMutationVariables>(UpdateBillingPositionDocument, options);
      }
export type UpdateBillingPositionMutationHookResult = ReturnType<typeof useUpdateBillingPositionMutation>;
export type UpdateBillingPositionMutationResult = Apollo.MutationResult<UpdateBillingPositionMutation>;
export type UpdateBillingPositionMutationOptions = Apollo.BaseMutationOptions<UpdateBillingPositionMutation, UpdateBillingPositionMutationVariables>;
export const GetBillingPositionDocument = gql`
    query GetBillingPosition($id: Int!) {
  billingPosition(id: $id) {
    ...BillingPosition
  }
}
    ${BillingPositionFragmentDoc}`;

/**
 * __useGetBillingPositionQuery__
 *
 * To run a query within a React component, call `useGetBillingPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingPositionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBillingPositionQuery(baseOptions: Apollo.QueryHookOptions<GetBillingPositionQuery, GetBillingPositionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillingPositionQuery, GetBillingPositionQueryVariables>(GetBillingPositionDocument, options);
      }
export function useGetBillingPositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingPositionQuery, GetBillingPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillingPositionQuery, GetBillingPositionQueryVariables>(GetBillingPositionDocument, options);
        }
export type GetBillingPositionQueryHookResult = ReturnType<typeof useGetBillingPositionQuery>;
export type GetBillingPositionLazyQueryHookResult = ReturnType<typeof useGetBillingPositionLazyQuery>;
export type GetBillingPositionQueryResult = Apollo.QueryResult<GetBillingPositionQuery, GetBillingPositionQueryVariables>;
export const GetBillingPositionsDocument = gql`
    query GetBillingPositions($customer: Int!) {
  billingPositions(customer: $customer) {
    ...BillingPosition
  }
}
    ${BillingPositionFragmentDoc}`;

/**
 * __useGetBillingPositionsQuery__
 *
 * To run a query within a React component, call `useGetBillingPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingPositionsQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useGetBillingPositionsQuery(baseOptions: Apollo.QueryHookOptions<GetBillingPositionsQuery, GetBillingPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillingPositionsQuery, GetBillingPositionsQueryVariables>(GetBillingPositionsDocument, options);
      }
export function useGetBillingPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingPositionsQuery, GetBillingPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillingPositionsQuery, GetBillingPositionsQueryVariables>(GetBillingPositionsDocument, options);
        }
export type GetBillingPositionsQueryHookResult = ReturnType<typeof useGetBillingPositionsQuery>;
export type GetBillingPositionsLazyQueryHookResult = ReturnType<typeof useGetBillingPositionsLazyQuery>;
export type GetBillingPositionsQueryResult = Apollo.QueryResult<GetBillingPositionsQuery, GetBillingPositionsQueryVariables>;
export const SendContactMessageDocument = gql`
    mutation SendContactMessage($dto: ContactInput!) {
  sendContactMessage(dto: $dto)
}
    `;
export type SendContactMessageMutationFn = Apollo.MutationFunction<SendContactMessageMutation, SendContactMessageMutationVariables>;

/**
 * __useSendContactMessageMutation__
 *
 * To run a mutation, you first call `useSendContactMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContactMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContactMessageMutation, { data, loading, error }] = useSendContactMessageMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSendContactMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendContactMessageMutation, SendContactMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendContactMessageMutation, SendContactMessageMutationVariables>(SendContactMessageDocument, options);
      }
export type SendContactMessageMutationHookResult = ReturnType<typeof useSendContactMessageMutation>;
export type SendContactMessageMutationResult = Apollo.MutationResult<SendContactMessageMutation>;
export type SendContactMessageMutationOptions = Apollo.BaseMutationOptions<SendContactMessageMutation, SendContactMessageMutationVariables>;
export const CreateCourseDocument = gql`
    mutation CreateCourse($dto: CreateCourseInput!) {
  createCourse(dto: $dto) {
    id
  }
}
    `;
export type CreateCourseMutationFn = Apollo.MutationFunction<CreateCourseMutation, CreateCourseMutationVariables>;

/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateCourseMutation(baseOptions?: Apollo.MutationHookOptions<CreateCourseMutation, CreateCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCourseMutation, CreateCourseMutationVariables>(CreateCourseDocument, options);
      }
export type CreateCourseMutationHookResult = ReturnType<typeof useCreateCourseMutation>;
export type CreateCourseMutationResult = Apollo.MutationResult<CreateCourseMutation>;
export type CreateCourseMutationOptions = Apollo.BaseMutationOptions<CreateCourseMutation, CreateCourseMutationVariables>;
export const CreateCourseAllocationDocument = gql`
    mutation CreateCourseAllocation($id: Int!, $dto: CreateCourseAllocationInput!) {
  createCourseAllocation(courseId: $id, dto: $dto) {
    id
  }
}
    `;
export type CreateCourseAllocationMutationFn = Apollo.MutationFunction<CreateCourseAllocationMutation, CreateCourseAllocationMutationVariables>;

/**
 * __useCreateCourseAllocationMutation__
 *
 * To run a mutation, you first call `useCreateCourseAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseAllocationMutation, { data, loading, error }] = useCreateCourseAllocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateCourseAllocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateCourseAllocationMutation, CreateCourseAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCourseAllocationMutation, CreateCourseAllocationMutationVariables>(CreateCourseAllocationDocument, options);
      }
export type CreateCourseAllocationMutationHookResult = ReturnType<typeof useCreateCourseAllocationMutation>;
export type CreateCourseAllocationMutationResult = Apollo.MutationResult<CreateCourseAllocationMutation>;
export type CreateCourseAllocationMutationOptions = Apollo.BaseMutationOptions<CreateCourseAllocationMutation, CreateCourseAllocationMutationVariables>;
export const DeleteCourseAllocationDocument = gql`
    mutation DeleteCourseAllocation($id: Int!) {
  deleteCourseAllocation(id: $id) {
    id
  }
}
    `;
export type DeleteCourseAllocationMutationFn = Apollo.MutationFunction<DeleteCourseAllocationMutation, DeleteCourseAllocationMutationVariables>;

/**
 * __useDeleteCourseAllocationMutation__
 *
 * To run a mutation, you first call `useDeleteCourseAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseAllocationMutation, { data, loading, error }] = useDeleteCourseAllocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCourseAllocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCourseAllocationMutation, DeleteCourseAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCourseAllocationMutation, DeleteCourseAllocationMutationVariables>(DeleteCourseAllocationDocument, options);
      }
export type DeleteCourseAllocationMutationHookResult = ReturnType<typeof useDeleteCourseAllocationMutation>;
export type DeleteCourseAllocationMutationResult = Apollo.MutationResult<DeleteCourseAllocationMutation>;
export type DeleteCourseAllocationMutationOptions = Apollo.BaseMutationOptions<DeleteCourseAllocationMutation, DeleteCourseAllocationMutationVariables>;
export const UpdateCourseDocument = gql`
    mutation UpdateCourse($id: Int!, $dto: UpdateCourseInput!) {
  updateCourse(id: $id, dto: $dto) {
    id
  }
}
    `;
export type UpdateCourseMutationFn = Apollo.MutationFunction<UpdateCourseMutation, UpdateCourseMutationVariables>;

/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateCourseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourseMutation, UpdateCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(UpdateCourseDocument, options);
      }
export type UpdateCourseMutationHookResult = ReturnType<typeof useUpdateCourseMutation>;
export type UpdateCourseMutationResult = Apollo.MutationResult<UpdateCourseMutation>;
export type UpdateCourseMutationOptions = Apollo.BaseMutationOptions<UpdateCourseMutation, UpdateCourseMutationVariables>;
export const UpdateCourseAllocationDocument = gql`
    mutation UpdateCourseAllocation($id: Int!, $dto: UpdateCourseAllocationInput!) {
  updateCourseAllocation(id: $id, dto: $dto) {
    id
  }
}
    `;
export type UpdateCourseAllocationMutationFn = Apollo.MutationFunction<UpdateCourseAllocationMutation, UpdateCourseAllocationMutationVariables>;

/**
 * __useUpdateCourseAllocationMutation__
 *
 * To run a mutation, you first call `useUpdateCourseAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseAllocationMutation, { data, loading, error }] = useUpdateCourseAllocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateCourseAllocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourseAllocationMutation, UpdateCourseAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCourseAllocationMutation, UpdateCourseAllocationMutationVariables>(UpdateCourseAllocationDocument, options);
      }
export type UpdateCourseAllocationMutationHookResult = ReturnType<typeof useUpdateCourseAllocationMutation>;
export type UpdateCourseAllocationMutationResult = Apollo.MutationResult<UpdateCourseAllocationMutation>;
export type UpdateCourseAllocationMutationOptions = Apollo.BaseMutationOptions<UpdateCourseAllocationMutation, UpdateCourseAllocationMutationVariables>;
export const GetCoursesInDateRangeDocument = gql`
    query GetCoursesInDateRange($startDate: String!, $endDate: String!) {
  coursesInDateRange(startDate: $startDate, endDate: $endDate) {
    id
    title
    startDate
    endDate
  }
}
    `;

/**
 * __useGetCoursesInDateRangeQuery__
 *
 * To run a query within a React component, call `useGetCoursesInDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesInDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesInDateRangeQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetCoursesInDateRangeQuery(baseOptions: Apollo.QueryHookOptions<GetCoursesInDateRangeQuery, GetCoursesInDateRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoursesInDateRangeQuery, GetCoursesInDateRangeQueryVariables>(GetCoursesInDateRangeDocument, options);
      }
export function useGetCoursesInDateRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoursesInDateRangeQuery, GetCoursesInDateRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoursesInDateRangeQuery, GetCoursesInDateRangeQueryVariables>(GetCoursesInDateRangeDocument, options);
        }
export type GetCoursesInDateRangeQueryHookResult = ReturnType<typeof useGetCoursesInDateRangeQuery>;
export type GetCoursesInDateRangeLazyQueryHookResult = ReturnType<typeof useGetCoursesInDateRangeLazyQuery>;
export type GetCoursesInDateRangeQueryResult = Apollo.QueryResult<GetCoursesInDateRangeQuery, GetCoursesInDateRangeQueryVariables>;
export const GetFutureCoursesDocument = gql`
    query GetFutureCourses($filter: FilterInput) {
  futureCourses(filter: $filter) {
    items {
      ...Course
    }
    page
    numberOfItems
  }
}
    ${CourseFragmentDoc}`;

/**
 * __useGetFutureCoursesQuery__
 *
 * To run a query within a React component, call `useGetFutureCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFutureCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFutureCoursesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFutureCoursesQuery(baseOptions?: Apollo.QueryHookOptions<GetFutureCoursesQuery, GetFutureCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFutureCoursesQuery, GetFutureCoursesQueryVariables>(GetFutureCoursesDocument, options);
      }
export function useGetFutureCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFutureCoursesQuery, GetFutureCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFutureCoursesQuery, GetFutureCoursesQueryVariables>(GetFutureCoursesDocument, options);
        }
export type GetFutureCoursesQueryHookResult = ReturnType<typeof useGetFutureCoursesQuery>;
export type GetFutureCoursesLazyQueryHookResult = ReturnType<typeof useGetFutureCoursesLazyQuery>;
export type GetFutureCoursesQueryResult = Apollo.QueryResult<GetFutureCoursesQuery, GetFutureCoursesQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($dto: CreateCustomerInput!) {
  createCustomer(dto: $dto) {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const CreateCustomerAttendanceDocument = gql`
    mutation CreateCustomerAttendance($id: Int!, $dto: CreateCustomerAttendanceInput!) {
  createCustomerAttendance(customerId: $id, dto: $dto) {
    id
  }
}
    `;
export type CreateCustomerAttendanceMutationFn = Apollo.MutationFunction<CreateCustomerAttendanceMutation, CreateCustomerAttendanceMutationVariables>;

/**
 * __useCreateCustomerAttendanceMutation__
 *
 * To run a mutation, you first call `useCreateCustomerAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerAttendanceMutation, { data, loading, error }] = useCreateCustomerAttendanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateCustomerAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerAttendanceMutation, CreateCustomerAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerAttendanceMutation, CreateCustomerAttendanceMutationVariables>(CreateCustomerAttendanceDocument, options);
      }
export type CreateCustomerAttendanceMutationHookResult = ReturnType<typeof useCreateCustomerAttendanceMutation>;
export type CreateCustomerAttendanceMutationResult = Apollo.MutationResult<CreateCustomerAttendanceMutation>;
export type CreateCustomerAttendanceMutationOptions = Apollo.BaseMutationOptions<CreateCustomerAttendanceMutation, CreateCustomerAttendanceMutationVariables>;
export const DeleteCustomerDocument = gql`
    mutation DeleteCustomer($id: Int!) {
  deleteCustomer(id: $id) {
    id
  }
}
    `;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, options);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const DeleteCustomerAttendanceDocument = gql`
    mutation DeleteCustomerAttendance($id: Int!, $customerId: Int!) {
  deleteCustomerAttendance(customerId: $customerId, id: $id) {
    id
  }
}
    `;
export type DeleteCustomerAttendanceMutationFn = Apollo.MutationFunction<DeleteCustomerAttendanceMutation, DeleteCustomerAttendanceMutationVariables>;

/**
 * __useDeleteCustomerAttendanceMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerAttendanceMutation, { data, loading, error }] = useDeleteCustomerAttendanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useDeleteCustomerAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerAttendanceMutation, DeleteCustomerAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerAttendanceMutation, DeleteCustomerAttendanceMutationVariables>(DeleteCustomerAttendanceDocument, options);
      }
export type DeleteCustomerAttendanceMutationHookResult = ReturnType<typeof useDeleteCustomerAttendanceMutation>;
export type DeleteCustomerAttendanceMutationResult = Apollo.MutationResult<DeleteCustomerAttendanceMutation>;
export type DeleteCustomerAttendanceMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerAttendanceMutation, DeleteCustomerAttendanceMutationVariables>;
export const RegisterCustomerDocument = gql`
    mutation RegisterCustomer($dto: RegisterCustomerInput!) {
  registerCustomer(dto: $dto) {
    paymentLink
    paymentId
    customerToken
    customerId
  }
}
    `;
export type RegisterCustomerMutationFn = Apollo.MutationFunction<RegisterCustomerMutation, RegisterCustomerMutationVariables>;

/**
 * __useRegisterCustomerMutation__
 *
 * To run a mutation, you first call `useRegisterCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCustomerMutation, { data, loading, error }] = useRegisterCustomerMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useRegisterCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RegisterCustomerMutation, RegisterCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterCustomerMutation, RegisterCustomerMutationVariables>(RegisterCustomerDocument, options);
      }
export type RegisterCustomerMutationHookResult = ReturnType<typeof useRegisterCustomerMutation>;
export type RegisterCustomerMutationResult = Apollo.MutationResult<RegisterCustomerMutation>;
export type RegisterCustomerMutationOptions = Apollo.BaseMutationOptions<RegisterCustomerMutation, RegisterCustomerMutationVariables>;
export const SendCustomerLoginMailDocument = gql`
    mutation SendCustomerLoginMail($email: String!, $callbackURL: String!) {
  sendCustomerLoginMail(email: $email, callbackURL: $callbackURL)
}
    `;
export type SendCustomerLoginMailMutationFn = Apollo.MutationFunction<SendCustomerLoginMailMutation, SendCustomerLoginMailMutationVariables>;

/**
 * __useSendCustomerLoginMailMutation__
 *
 * To run a mutation, you first call `useSendCustomerLoginMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCustomerLoginMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCustomerLoginMailMutation, { data, loading, error }] = useSendCustomerLoginMailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      callbackURL: // value for 'callbackURL'
 *   },
 * });
 */
export function useSendCustomerLoginMailMutation(baseOptions?: Apollo.MutationHookOptions<SendCustomerLoginMailMutation, SendCustomerLoginMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCustomerLoginMailMutation, SendCustomerLoginMailMutationVariables>(SendCustomerLoginMailDocument, options);
      }
export type SendCustomerLoginMailMutationHookResult = ReturnType<typeof useSendCustomerLoginMailMutation>;
export type SendCustomerLoginMailMutationResult = Apollo.MutationResult<SendCustomerLoginMailMutation>;
export type SendCustomerLoginMailMutationOptions = Apollo.BaseMutationOptions<SendCustomerLoginMailMutation, SendCustomerLoginMailMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($id: Int!, $dto: UpdateCustomerInput!) {
  updateCustomer(id: $id, dto: $dto) {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const UpdateCustomerWithTokenDocument = gql`
    mutation UpdateCustomerWithToken($dto: UpdateCustomerWithTokenInput!, $token: String!) {
  updateCustomerWithToken(dto: $dto, token: $token) {
    paymentLink
    paymentId
  }
}
    `;
export type UpdateCustomerWithTokenMutationFn = Apollo.MutationFunction<UpdateCustomerWithTokenMutation, UpdateCustomerWithTokenMutationVariables>;

/**
 * __useUpdateCustomerWithTokenMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerWithTokenMutation, { data, loading, error }] = useUpdateCustomerWithTokenMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpdateCustomerWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerWithTokenMutation, UpdateCustomerWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerWithTokenMutation, UpdateCustomerWithTokenMutationVariables>(UpdateCustomerWithTokenDocument, options);
      }
export type UpdateCustomerWithTokenMutationHookResult = ReturnType<typeof useUpdateCustomerWithTokenMutation>;
export type UpdateCustomerWithTokenMutationResult = Apollo.MutationResult<UpdateCustomerWithTokenMutation>;
export type UpdateCustomerWithTokenMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerWithTokenMutation, UpdateCustomerWithTokenMutationVariables>;
export const VerifyCustomerEmailAddressDocument = gql`
    mutation VerifyCustomerEmailAddress($token: String!) {
  verifyCustomerEmailAddress(token: $token) {
    firstName
  }
}
    `;
export type VerifyCustomerEmailAddressMutationFn = Apollo.MutationFunction<VerifyCustomerEmailAddressMutation, VerifyCustomerEmailAddressMutationVariables>;

/**
 * __useVerifyCustomerEmailAddressMutation__
 *
 * To run a mutation, you first call `useVerifyCustomerEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCustomerEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCustomerEmailAddressMutation, { data, loading, error }] = useVerifyCustomerEmailAddressMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyCustomerEmailAddressMutation(baseOptions?: Apollo.MutationHookOptions<VerifyCustomerEmailAddressMutation, VerifyCustomerEmailAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyCustomerEmailAddressMutation, VerifyCustomerEmailAddressMutationVariables>(VerifyCustomerEmailAddressDocument, options);
      }
export type VerifyCustomerEmailAddressMutationHookResult = ReturnType<typeof useVerifyCustomerEmailAddressMutation>;
export type VerifyCustomerEmailAddressMutationResult = Apollo.MutationResult<VerifyCustomerEmailAddressMutation>;
export type VerifyCustomerEmailAddressMutationOptions = Apollo.BaseMutationOptions<VerifyCustomerEmailAddressMutation, VerifyCustomerEmailAddressMutationVariables>;
export const CustomerByTokenDocument = gql`
    query CustomerByToken($token: String!) {
  customerByToken(token: $token) {
    id
    firstName
    lastName
    street
    streetNumber
    zipCode
    city
    email
    phone
    emergencyPhone
    shoeSize
    bodyHeight
    bodyWeight
    kiteLevel
    comment
    dateOfBirth
    ownGear
  }
}
    `;

/**
 * __useCustomerByTokenQuery__
 *
 * To run a query within a React component, call `useCustomerByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerByTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCustomerByTokenQuery(baseOptions: Apollo.QueryHookOptions<CustomerByTokenQuery, CustomerByTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerByTokenQuery, CustomerByTokenQueryVariables>(CustomerByTokenDocument, options);
      }
export function useCustomerByTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerByTokenQuery, CustomerByTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerByTokenQuery, CustomerByTokenQueryVariables>(CustomerByTokenDocument, options);
        }
export type CustomerByTokenQueryHookResult = ReturnType<typeof useCustomerByTokenQuery>;
export type CustomerByTokenLazyQueryHookResult = ReturnType<typeof useCustomerByTokenLazyQuery>;
export type CustomerByTokenQueryResult = Apollo.QueryResult<CustomerByTokenQuery, CustomerByTokenQueryVariables>;
export const CustomerExistsDocument = gql`
    query CustomerExists($email: String!) {
  customerExists(email: $email)
}
    `;

/**
 * __useCustomerExistsQuery__
 *
 * To run a query within a React component, call `useCustomerExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerExistsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCustomerExistsQuery(baseOptions: Apollo.QueryHookOptions<CustomerExistsQuery, CustomerExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerExistsQuery, CustomerExistsQueryVariables>(CustomerExistsDocument, options);
      }
export function useCustomerExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerExistsQuery, CustomerExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerExistsQuery, CustomerExistsQueryVariables>(CustomerExistsDocument, options);
        }
export type CustomerExistsQueryHookResult = ReturnType<typeof useCustomerExistsQuery>;
export type CustomerExistsLazyQueryHookResult = ReturnType<typeof useCustomerExistsLazyQuery>;
export type CustomerExistsQueryResult = Apollo.QueryResult<CustomerExistsQuery, CustomerExistsQueryVariables>;
export const GetCustomerDocument = gql`
    query GetCustomer($id: Int!) {
  customer(id: $id) {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const GetCustomerAttendancesDocument = gql`
    query GetCustomerAttendances($startDate: String!, $endDate: String!) {
  customerAttendances(startDate: $startDate, endDate: $endDate) {
    ...Attendance
    booking {
      id
      paid
    }
    desiredProduct {
      title
    }
    customer {
      firstName
      lastName
    }
  }
}
    ${AttendanceFragmentDoc}`;

/**
 * __useGetCustomerAttendancesQuery__
 *
 * To run a query within a React component, call `useGetCustomerAttendancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAttendancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAttendancesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetCustomerAttendancesQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerAttendancesQuery, GetCustomerAttendancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAttendancesQuery, GetCustomerAttendancesQueryVariables>(GetCustomerAttendancesDocument, options);
      }
export function useGetCustomerAttendancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAttendancesQuery, GetCustomerAttendancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAttendancesQuery, GetCustomerAttendancesQueryVariables>(GetCustomerAttendancesDocument, options);
        }
export type GetCustomerAttendancesQueryHookResult = ReturnType<typeof useGetCustomerAttendancesQuery>;
export type GetCustomerAttendancesLazyQueryHookResult = ReturnType<typeof useGetCustomerAttendancesLazyQuery>;
export type GetCustomerAttendancesQueryResult = Apollo.QueryResult<GetCustomerAttendancesQuery, GetCustomerAttendancesQueryVariables>;
export const GetCustomerWithOpenPaymentsDocument = gql`
    query GetCustomerWithOpenPayments($id: Int!) {
  customerWithOpenPayments(id: $id) {
    id
    firstName
    lastName
    street
    streetNumber
    zipCode
    city
    email
    courseAllocations {
      ...CourseAllocation
    }
    bookings {
      id
      title
      amount
      paid
    }
  }
}
    ${CourseAllocationFragmentDoc}`;

/**
 * __useGetCustomerWithOpenPaymentsQuery__
 *
 * To run a query within a React component, call `useGetCustomerWithOpenPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerWithOpenPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerWithOpenPaymentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerWithOpenPaymentsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerWithOpenPaymentsQuery, GetCustomerWithOpenPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerWithOpenPaymentsQuery, GetCustomerWithOpenPaymentsQueryVariables>(GetCustomerWithOpenPaymentsDocument, options);
      }
export function useGetCustomerWithOpenPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerWithOpenPaymentsQuery, GetCustomerWithOpenPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerWithOpenPaymentsQuery, GetCustomerWithOpenPaymentsQueryVariables>(GetCustomerWithOpenPaymentsDocument, options);
        }
export type GetCustomerWithOpenPaymentsQueryHookResult = ReturnType<typeof useGetCustomerWithOpenPaymentsQuery>;
export type GetCustomerWithOpenPaymentsLazyQueryHookResult = ReturnType<typeof useGetCustomerWithOpenPaymentsLazyQuery>;
export type GetCustomerWithOpenPaymentsQueryResult = Apollo.QueryResult<GetCustomerWithOpenPaymentsQuery, GetCustomerWithOpenPaymentsQueryVariables>;
export const GetCustomersDocument = gql`
    query GetCustomers($filter: FilterInput) {
  customers(filter: $filter) {
    items {
      id
      firstName
      lastName
      dateOfBirth
      city
    }
    page
    numberOfItems
  }
}
    `;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
      }
export function useGetCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
        }
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
export const GetCustomersWithOpenPaymentsDocument = gql`
    query GetCustomersWithOpenPayments {
  customersWithOpenPayments {
    id
    firstName
    lastName
    courseAllocations {
      id
      amount
      product {
        title
      }
      course {
        title
        startDate
        endDate
        instructor {
          name
        }
        spot {
          name
        }
      }
      comment
    }
    bookings {
      id
      title
      amount
      paid
    }
  }
}
    `;

/**
 * __useGetCustomersWithOpenPaymentsQuery__
 *
 * To run a query within a React component, call `useGetCustomersWithOpenPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersWithOpenPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersWithOpenPaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomersWithOpenPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersWithOpenPaymentsQuery, GetCustomersWithOpenPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersWithOpenPaymentsQuery, GetCustomersWithOpenPaymentsQueryVariables>(GetCustomersWithOpenPaymentsDocument, options);
      }
export function useGetCustomersWithOpenPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersWithOpenPaymentsQuery, GetCustomersWithOpenPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersWithOpenPaymentsQuery, GetCustomersWithOpenPaymentsQueryVariables>(GetCustomersWithOpenPaymentsDocument, options);
        }
export type GetCustomersWithOpenPaymentsQueryHookResult = ReturnType<typeof useGetCustomersWithOpenPaymentsQuery>;
export type GetCustomersWithOpenPaymentsLazyQueryHookResult = ReturnType<typeof useGetCustomersWithOpenPaymentsLazyQuery>;
export type GetCustomersWithOpenPaymentsQueryResult = Apollo.QueryResult<GetCustomersWithOpenPaymentsQuery, GetCustomersWithOpenPaymentsQueryVariables>;
export const GetFutureCustomerAttendanceDocument = gql`
    query GetFutureCustomerAttendance {
  futureCustomerAttendance {
    id
    customer {
      id
      firstName
      lastName
      city
      kiteLevel
      dateOfBirth
    }
    startDate
    endDate
    booking {
      id
      title
      paid
    }
    desiredProduct {
      id
      title
    }
  }
}
    `;

/**
 * __useGetFutureCustomerAttendanceQuery__
 *
 * To run a query within a React component, call `useGetFutureCustomerAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFutureCustomerAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFutureCustomerAttendanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFutureCustomerAttendanceQuery(baseOptions?: Apollo.QueryHookOptions<GetFutureCustomerAttendanceQuery, GetFutureCustomerAttendanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFutureCustomerAttendanceQuery, GetFutureCustomerAttendanceQueryVariables>(GetFutureCustomerAttendanceDocument, options);
      }
export function useGetFutureCustomerAttendanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFutureCustomerAttendanceQuery, GetFutureCustomerAttendanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFutureCustomerAttendanceQuery, GetFutureCustomerAttendanceQueryVariables>(GetFutureCustomerAttendanceDocument, options);
        }
export type GetFutureCustomerAttendanceQueryHookResult = ReturnType<typeof useGetFutureCustomerAttendanceQuery>;
export type GetFutureCustomerAttendanceLazyQueryHookResult = ReturnType<typeof useGetFutureCustomerAttendanceLazyQuery>;
export type GetFutureCustomerAttendanceQueryResult = Apollo.QueryResult<GetFutureCustomerAttendanceQuery, GetFutureCustomerAttendanceQueryVariables>;
export const CreateInstructorDocument = gql`
    mutation CreateInstructor($dto: CreateInstructorInput!) {
  createInstructor(dto: $dto) {
    id
  }
}
    `;
export type CreateInstructorMutationFn = Apollo.MutationFunction<CreateInstructorMutation, CreateInstructorMutationVariables>;

/**
 * __useCreateInstructorMutation__
 *
 * To run a mutation, you first call `useCreateInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstructorMutation, { data, loading, error }] = useCreateInstructorMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateInstructorMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstructorMutation, CreateInstructorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInstructorMutation, CreateInstructorMutationVariables>(CreateInstructorDocument, options);
      }
export type CreateInstructorMutationHookResult = ReturnType<typeof useCreateInstructorMutation>;
export type CreateInstructorMutationResult = Apollo.MutationResult<CreateInstructorMutation>;
export type CreateInstructorMutationOptions = Apollo.BaseMutationOptions<CreateInstructorMutation, CreateInstructorMutationVariables>;
export const CreateInstructorAvailabilityDocument = gql`
    mutation CreateInstructorAvailability($instructorId: Int!, $dto: CreateInstructorAvailabilityInput!) {
  createInstructorAvailability(instructorId: $instructorId, dto: $dto) {
    id
  }
}
    `;
export type CreateInstructorAvailabilityMutationFn = Apollo.MutationFunction<CreateInstructorAvailabilityMutation, CreateInstructorAvailabilityMutationVariables>;

/**
 * __useCreateInstructorAvailabilityMutation__
 *
 * To run a mutation, you first call `useCreateInstructorAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstructorAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstructorAvailabilityMutation, { data, loading, error }] = useCreateInstructorAvailabilityMutation({
 *   variables: {
 *      instructorId: // value for 'instructorId'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateInstructorAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstructorAvailabilityMutation, CreateInstructorAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInstructorAvailabilityMutation, CreateInstructorAvailabilityMutationVariables>(CreateInstructorAvailabilityDocument, options);
      }
export type CreateInstructorAvailabilityMutationHookResult = ReturnType<typeof useCreateInstructorAvailabilityMutation>;
export type CreateInstructorAvailabilityMutationResult = Apollo.MutationResult<CreateInstructorAvailabilityMutation>;
export type CreateInstructorAvailabilityMutationOptions = Apollo.BaseMutationOptions<CreateInstructorAvailabilityMutation, CreateInstructorAvailabilityMutationVariables>;
export const DeleteInstructorDocument = gql`
    mutation DeleteInstructor($id: Int!) {
  deleteInstructor(id: $id) {
    ...Instructor
  }
}
    ${InstructorFragmentDoc}`;
export type DeleteInstructorMutationFn = Apollo.MutationFunction<DeleteInstructorMutation, DeleteInstructorMutationVariables>;

/**
 * __useDeleteInstructorMutation__
 *
 * To run a mutation, you first call `useDeleteInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstructorMutation, { data, loading, error }] = useDeleteInstructorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInstructorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInstructorMutation, DeleteInstructorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInstructorMutation, DeleteInstructorMutationVariables>(DeleteInstructorDocument, options);
      }
export type DeleteInstructorMutationHookResult = ReturnType<typeof useDeleteInstructorMutation>;
export type DeleteInstructorMutationResult = Apollo.MutationResult<DeleteInstructorMutation>;
export type DeleteInstructorMutationOptions = Apollo.BaseMutationOptions<DeleteInstructorMutation, DeleteInstructorMutationVariables>;
export const DeleteInstructorAvailabilityDocument = gql`
    mutation DeleteInstructorAvailability($instructorId: Int!, $id: Int!) {
  deleteInstructorAvailability(instructorId: $instructorId, id: $id) {
    id
  }
}
    `;
export type DeleteInstructorAvailabilityMutationFn = Apollo.MutationFunction<DeleteInstructorAvailabilityMutation, DeleteInstructorAvailabilityMutationVariables>;

/**
 * __useDeleteInstructorAvailabilityMutation__
 *
 * To run a mutation, you first call `useDeleteInstructorAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstructorAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstructorAvailabilityMutation, { data, loading, error }] = useDeleteInstructorAvailabilityMutation({
 *   variables: {
 *      instructorId: // value for 'instructorId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInstructorAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInstructorAvailabilityMutation, DeleteInstructorAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInstructorAvailabilityMutation, DeleteInstructorAvailabilityMutationVariables>(DeleteInstructorAvailabilityDocument, options);
      }
export type DeleteInstructorAvailabilityMutationHookResult = ReturnType<typeof useDeleteInstructorAvailabilityMutation>;
export type DeleteInstructorAvailabilityMutationResult = Apollo.MutationResult<DeleteInstructorAvailabilityMutation>;
export type DeleteInstructorAvailabilityMutationOptions = Apollo.BaseMutationOptions<DeleteInstructorAvailabilityMutation, DeleteInstructorAvailabilityMutationVariables>;
export const UpdateInstructorDocument = gql`
    mutation UpdateInstructor($id: Int!, $dto: UpdateInstructorInput!) {
  updateInstructor(id: $id, dto: $dto) {
    ...Instructor
  }
}
    ${InstructorFragmentDoc}`;
export type UpdateInstructorMutationFn = Apollo.MutationFunction<UpdateInstructorMutation, UpdateInstructorMutationVariables>;

/**
 * __useUpdateInstructorMutation__
 *
 * To run a mutation, you first call `useUpdateInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstructorMutation, { data, loading, error }] = useUpdateInstructorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateInstructorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstructorMutation, UpdateInstructorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstructorMutation, UpdateInstructorMutationVariables>(UpdateInstructorDocument, options);
      }
export type UpdateInstructorMutationHookResult = ReturnType<typeof useUpdateInstructorMutation>;
export type UpdateInstructorMutationResult = Apollo.MutationResult<UpdateInstructorMutation>;
export type UpdateInstructorMutationOptions = Apollo.BaseMutationOptions<UpdateInstructorMutation, UpdateInstructorMutationVariables>;
export const GetInstructorDocument = gql`
    query GetInstructor($id: Int!) {
  instructor(id: $id) {
    ...Instructor
  }
}
    ${InstructorFragmentDoc}`;

/**
 * __useGetInstructorQuery__
 *
 * To run a query within a React component, call `useGetInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInstructorQuery(baseOptions: Apollo.QueryHookOptions<GetInstructorQuery, GetInstructorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstructorQuery, GetInstructorQueryVariables>(GetInstructorDocument, options);
      }
export function useGetInstructorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstructorQuery, GetInstructorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstructorQuery, GetInstructorQueryVariables>(GetInstructorDocument, options);
        }
export type GetInstructorQueryHookResult = ReturnType<typeof useGetInstructorQuery>;
export type GetInstructorLazyQueryHookResult = ReturnType<typeof useGetInstructorLazyQuery>;
export type GetInstructorQueryResult = Apollo.QueryResult<GetInstructorQuery, GetInstructorQueryVariables>;
export const GetInstructorAvailabilitiesDocument = gql`
    query GetInstructorAvailabilities($startDate: String!, $endDate: String!) {
  instructorAvailabilities(startDate: $startDate, endDate: $endDate) {
    ...InstructorAvailability
    instructor {
      ...Instructor
    }
  }
}
    ${InstructorAvailabilityFragmentDoc}
${InstructorFragmentDoc}`;

/**
 * __useGetInstructorAvailabilitiesQuery__
 *
 * To run a query within a React component, call `useGetInstructorAvailabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorAvailabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorAvailabilitiesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetInstructorAvailabilitiesQuery(baseOptions: Apollo.QueryHookOptions<GetInstructorAvailabilitiesQuery, GetInstructorAvailabilitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstructorAvailabilitiesQuery, GetInstructorAvailabilitiesQueryVariables>(GetInstructorAvailabilitiesDocument, options);
      }
export function useGetInstructorAvailabilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstructorAvailabilitiesQuery, GetInstructorAvailabilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstructorAvailabilitiesQuery, GetInstructorAvailabilitiesQueryVariables>(GetInstructorAvailabilitiesDocument, options);
        }
export type GetInstructorAvailabilitiesQueryHookResult = ReturnType<typeof useGetInstructorAvailabilitiesQuery>;
export type GetInstructorAvailabilitiesLazyQueryHookResult = ReturnType<typeof useGetInstructorAvailabilitiesLazyQuery>;
export type GetInstructorAvailabilitiesQueryResult = Apollo.QueryResult<GetInstructorAvailabilitiesQuery, GetInstructorAvailabilitiesQueryVariables>;
export const GetInstructorsDocument = gql`
    query GetInstructors($filter: FilterInput) {
  instructors(filter: $filter) {
    items {
      ...Instructor
    }
    page
    numberOfItems
  }
}
    ${InstructorFragmentDoc}`;

/**
 * __useGetInstructorsQuery__
 *
 * To run a query within a React component, call `useGetInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetInstructorsQuery(baseOptions?: Apollo.QueryHookOptions<GetInstructorsQuery, GetInstructorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstructorsQuery, GetInstructorsQueryVariables>(GetInstructorsDocument, options);
      }
export function useGetInstructorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstructorsQuery, GetInstructorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstructorsQuery, GetInstructorsQueryVariables>(GetInstructorsDocument, options);
        }
export type GetInstructorsQueryHookResult = ReturnType<typeof useGetInstructorsQuery>;
export type GetInstructorsLazyQueryHookResult = ReturnType<typeof useGetInstructorsLazyQuery>;
export type GetInstructorsQueryResult = Apollo.QueryResult<GetInstructorsQuery, GetInstructorsQueryVariables>;
export const GetInstructorsForSelectDocument = gql`
    query GetInstructorsForSelect {
  instructors(filter: {itemsPerPage: 10000, page: 1}) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useGetInstructorsForSelectQuery__
 *
 * To run a query within a React component, call `useGetInstructorsForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorsForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorsForSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInstructorsForSelectQuery(baseOptions?: Apollo.QueryHookOptions<GetInstructorsForSelectQuery, GetInstructorsForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstructorsForSelectQuery, GetInstructorsForSelectQueryVariables>(GetInstructorsForSelectDocument, options);
      }
export function useGetInstructorsForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstructorsForSelectQuery, GetInstructorsForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstructorsForSelectQuery, GetInstructorsForSelectQueryVariables>(GetInstructorsForSelectDocument, options);
        }
export type GetInstructorsForSelectQueryHookResult = ReturnType<typeof useGetInstructorsForSelectQuery>;
export type GetInstructorsForSelectLazyQueryHookResult = ReturnType<typeof useGetInstructorsForSelectLazyQuery>;
export type GetInstructorsForSelectQueryResult = Apollo.QueryResult<GetInstructorsForSelectQuery, GetInstructorsForSelectQueryVariables>;
export const CreateInternalOrderDocument = gql`
    mutation CreateInternalOrder($dto: CreateInternalOrderInput!) {
  createInternalOrder(dto: $dto) {
    invoiceNumber
    order {
      id
    }
  }
}
    `;
export type CreateInternalOrderMutationFn = Apollo.MutationFunction<CreateInternalOrderMutation, CreateInternalOrderMutationVariables>;

/**
 * __useCreateInternalOrderMutation__
 *
 * To run a mutation, you first call `useCreateInternalOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInternalOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInternalOrderMutation, { data, loading, error }] = useCreateInternalOrderMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateInternalOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateInternalOrderMutation, CreateInternalOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInternalOrderMutation, CreateInternalOrderMutationVariables>(CreateInternalOrderDocument, options);
      }
export type CreateInternalOrderMutationHookResult = ReturnType<typeof useCreateInternalOrderMutation>;
export type CreateInternalOrderMutationResult = Apollo.MutationResult<CreateInternalOrderMutation>;
export type CreateInternalOrderMutationOptions = Apollo.BaseMutationOptions<CreateInternalOrderMutation, CreateInternalOrderMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($dto: CreateOrderInput!) {
  createOrder(dto: $dto) {
    paymentId
    orderId
    paymentLink
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($id: Int!, $dto: UpdateOrderInput!) {
  updateOrder(id: $id, dto: $dto) {
    id
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const CheckPaymentDocument = gql`
    query CheckPayment($orderId: Int!) {
  checkPayment(orderId: $orderId) {
    status
  }
}
    `;

/**
 * __useCheckPaymentQuery__
 *
 * To run a query within a React component, call `useCheckPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPaymentQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCheckPaymentQuery(baseOptions: Apollo.QueryHookOptions<CheckPaymentQuery, CheckPaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckPaymentQuery, CheckPaymentQueryVariables>(CheckPaymentDocument, options);
      }
export function useCheckPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckPaymentQuery, CheckPaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckPaymentQuery, CheckPaymentQueryVariables>(CheckPaymentDocument, options);
        }
export type CheckPaymentQueryHookResult = ReturnType<typeof useCheckPaymentQuery>;
export type CheckPaymentLazyQueryHookResult = ReturnType<typeof useCheckPaymentLazyQuery>;
export type CheckPaymentQueryResult = Apollo.QueryResult<CheckPaymentQuery, CheckPaymentQueryVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($dto: CreateProductInput!) {
  createProduct(dto: $dto) {
    id
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($id: Int!) {
  deleteProduct(id: $id) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($id: Int!, $dto: UpdateProductInput!) {
  updateProduct(id: $id, dto: $dto) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const GetProductsDocument = gql`
    query GetProducts($filter: FilterInput) {
  products(filter: $filter) {
    items {
      ...Product
    }
    page
    numberOfItems
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const GetProductsForBookingDocument = gql`
    query GetProductsForBooking {
  publicProductsForBooking {
    id
    title
    price
    description
    image
    category {
      id
      name
      uid
    }
  }
}
    `;

/**
 * __useGetProductsForBookingQuery__
 *
 * To run a query within a React component, call `useGetProductsForBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsForBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsForBookingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsForBookingQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsForBookingQuery, GetProductsForBookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsForBookingQuery, GetProductsForBookingQueryVariables>(GetProductsForBookingDocument, options);
      }
export function useGetProductsForBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsForBookingQuery, GetProductsForBookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsForBookingQuery, GetProductsForBookingQueryVariables>(GetProductsForBookingDocument, options);
        }
export type GetProductsForBookingQueryHookResult = ReturnType<typeof useGetProductsForBookingQuery>;
export type GetProductsForBookingLazyQueryHookResult = ReturnType<typeof useGetProductsForBookingLazyQuery>;
export type GetProductsForBookingQueryResult = Apollo.QueryResult<GetProductsForBookingQuery, GetProductsForBookingQueryVariables>;
export const GetProductsForCourseSelectDocument = gql`
    query GetProductsForCourseSelect {
  products(filter: {itemsPerPage: 10000, page: 1}) {
    items {
      id
      title
    }
  }
}
    `;

/**
 * __useGetProductsForCourseSelectQuery__
 *
 * To run a query within a React component, call `useGetProductsForCourseSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsForCourseSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsForCourseSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsForCourseSelectQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsForCourseSelectQuery, GetProductsForCourseSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsForCourseSelectQuery, GetProductsForCourseSelectQueryVariables>(GetProductsForCourseSelectDocument, options);
      }
export function useGetProductsForCourseSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsForCourseSelectQuery, GetProductsForCourseSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsForCourseSelectQuery, GetProductsForCourseSelectQueryVariables>(GetProductsForCourseSelectDocument, options);
        }
export type GetProductsForCourseSelectQueryHookResult = ReturnType<typeof useGetProductsForCourseSelectQuery>;
export type GetProductsForCourseSelectLazyQueryHookResult = ReturnType<typeof useGetProductsForCourseSelectLazyQuery>;
export type GetProductsForCourseSelectQueryResult = Apollo.QueryResult<GetProductsForCourseSelectQuery, GetProductsForCourseSelectQueryVariables>;
export const GetProductsForVoucherShopDocument = gql`
    query GetProductsForVoucherShop {
  publicProductsForVoucherShop {
    id
    title
    price
    description
    image
    category {
      id
      name
      uid
      sorting
    }
  }
  voucherDiscount
}
    `;

/**
 * __useGetProductsForVoucherShopQuery__
 *
 * To run a query within a React component, call `useGetProductsForVoucherShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsForVoucherShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsForVoucherShopQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsForVoucherShopQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsForVoucherShopQuery, GetProductsForVoucherShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsForVoucherShopQuery, GetProductsForVoucherShopQueryVariables>(GetProductsForVoucherShopDocument, options);
      }
export function useGetProductsForVoucherShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsForVoucherShopQuery, GetProductsForVoucherShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsForVoucherShopQuery, GetProductsForVoucherShopQueryVariables>(GetProductsForVoucherShopDocument, options);
        }
export type GetProductsForVoucherShopQueryHookResult = ReturnType<typeof useGetProductsForVoucherShopQuery>;
export type GetProductsForVoucherShopLazyQueryHookResult = ReturnType<typeof useGetProductsForVoucherShopLazyQuery>;
export type GetProductsForVoucherShopQueryResult = Apollo.QueryResult<GetProductsForVoucherShopQuery, GetProductsForVoucherShopQueryVariables>;
export const CreateProductCategoryDocument = gql`
    mutation CreateProductCategory($dto: CreateProductCategoryInput!) {
  createProductCategory(dto: $dto) {
    id
  }
}
    `;
export type CreateProductCategoryMutationFn = Apollo.MutationFunction<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;

/**
 * __useCreateProductCategoryMutation__
 *
 * To run a mutation, you first call `useCreateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductCategoryMutation, { data, loading, error }] = useCreateProductCategoryMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>(CreateProductCategoryDocument, options);
      }
export type CreateProductCategoryMutationHookResult = ReturnType<typeof useCreateProductCategoryMutation>;
export type CreateProductCategoryMutationResult = Apollo.MutationResult<CreateProductCategoryMutation>;
export type CreateProductCategoryMutationOptions = Apollo.BaseMutationOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;
export const DeleteProductCategoryDocument = gql`
    mutation DeleteProductCategory($id: Int!) {
  deleteProductCategory(id: $id) {
    ...ProductCategory
  }
}
    ${ProductCategoryFragmentDoc}`;
export type DeleteProductCategoryMutationFn = Apollo.MutationFunction<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>;

/**
 * __useDeleteProductCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductCategoryMutation, { data, loading, error }] = useDeleteProductCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>(DeleteProductCategoryDocument, options);
      }
export type DeleteProductCategoryMutationHookResult = ReturnType<typeof useDeleteProductCategoryMutation>;
export type DeleteProductCategoryMutationResult = Apollo.MutationResult<DeleteProductCategoryMutation>;
export type DeleteProductCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>;
export const UpdateProductCategoryDocument = gql`
    mutation UpdateProductCategory($id: Int!, $dto: UpdateProductCategoryInput!) {
  updateProductCategory(id: $id, dto: $dto) {
    ...ProductCategory
  }
}
    ${ProductCategoryFragmentDoc}`;
export type UpdateProductCategoryMutationFn = Apollo.MutationFunction<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>;

/**
 * __useUpdateProductCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCategoryMutation, { data, loading, error }] = useUpdateProductCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>(UpdateProductCategoryDocument, options);
      }
export type UpdateProductCategoryMutationHookResult = ReturnType<typeof useUpdateProductCategoryMutation>;
export type UpdateProductCategoryMutationResult = Apollo.MutationResult<UpdateProductCategoryMutation>;
export type UpdateProductCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>;
export const GetProductCategoriesDocument = gql`
    query GetProductCategories($filter: FilterInput) {
  productCategories(filter: $filter) {
    items {
      id
      name
      uid
    }
    page
    numberOfItems
  }
}
    `;

/**
 * __useGetProductCategoriesQuery__
 *
 * To run a query within a React component, call `useGetProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(GetProductCategoriesDocument, options);
      }
export function useGetProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(GetProductCategoriesDocument, options);
        }
export type GetProductCategoriesQueryHookResult = ReturnType<typeof useGetProductCategoriesQuery>;
export type GetProductCategoriesLazyQueryHookResult = ReturnType<typeof useGetProductCategoriesLazyQuery>;
export type GetProductCategoriesQueryResult = Apollo.QueryResult<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>;
export const GetPublicProductCategoriesDocument = gql`
    query GetPublicProductCategories {
  publicProductCategories {
    id
    name
    uid
  }
}
    `;

/**
 * __useGetPublicProductCategoriesQuery__
 *
 * To run a query within a React component, call `useGetPublicProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicProductCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublicProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetPublicProductCategoriesQuery, GetPublicProductCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicProductCategoriesQuery, GetPublicProductCategoriesQueryVariables>(GetPublicProductCategoriesDocument, options);
      }
export function useGetPublicProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicProductCategoriesQuery, GetPublicProductCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicProductCategoriesQuery, GetPublicProductCategoriesQueryVariables>(GetPublicProductCategoriesDocument, options);
        }
export type GetPublicProductCategoriesQueryHookResult = ReturnType<typeof useGetPublicProductCategoriesQuery>;
export type GetPublicProductCategoriesLazyQueryHookResult = ReturnType<typeof useGetPublicProductCategoriesLazyQuery>;
export type GetPublicProductCategoriesQueryResult = Apollo.QueryResult<GetPublicProductCategoriesQuery, GetPublicProductCategoriesQueryVariables>;
export const CreateSpotDocument = gql`
    mutation CreateSpot($dto: CreateSpotInput!) {
  createSpot(dto: $dto) {
    id
  }
}
    `;
export type CreateSpotMutationFn = Apollo.MutationFunction<CreateSpotMutation, CreateSpotMutationVariables>;

/**
 * __useCreateSpotMutation__
 *
 * To run a mutation, you first call `useCreateSpotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpotMutation, { data, loading, error }] = useCreateSpotMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateSpotMutation(baseOptions?: Apollo.MutationHookOptions<CreateSpotMutation, CreateSpotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSpotMutation, CreateSpotMutationVariables>(CreateSpotDocument, options);
      }
export type CreateSpotMutationHookResult = ReturnType<typeof useCreateSpotMutation>;
export type CreateSpotMutationResult = Apollo.MutationResult<CreateSpotMutation>;
export type CreateSpotMutationOptions = Apollo.BaseMutationOptions<CreateSpotMutation, CreateSpotMutationVariables>;
export const DeleteSpotDocument = gql`
    mutation DeleteSpot($id: Int!) {
  deleteSpot(id: $id) {
    ...Spot
  }
}
    ${SpotFragmentDoc}`;
export type DeleteSpotMutationFn = Apollo.MutationFunction<DeleteSpotMutation, DeleteSpotMutationVariables>;

/**
 * __useDeleteSpotMutation__
 *
 * To run a mutation, you first call `useDeleteSpotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpotMutation, { data, loading, error }] = useDeleteSpotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSpotMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSpotMutation, DeleteSpotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSpotMutation, DeleteSpotMutationVariables>(DeleteSpotDocument, options);
      }
export type DeleteSpotMutationHookResult = ReturnType<typeof useDeleteSpotMutation>;
export type DeleteSpotMutationResult = Apollo.MutationResult<DeleteSpotMutation>;
export type DeleteSpotMutationOptions = Apollo.BaseMutationOptions<DeleteSpotMutation, DeleteSpotMutationVariables>;
export const UpdateSpotDocument = gql`
    mutation UpdateSpot($id: Int!, $dto: UpdateSpotInput!) {
  updateSpot(id: $id, dto: $dto) {
    ...Spot
  }
}
    ${SpotFragmentDoc}`;
export type UpdateSpotMutationFn = Apollo.MutationFunction<UpdateSpotMutation, UpdateSpotMutationVariables>;

/**
 * __useUpdateSpotMutation__
 *
 * To run a mutation, you first call `useUpdateSpotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpotMutation, { data, loading, error }] = useUpdateSpotMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateSpotMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpotMutation, UpdateSpotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpotMutation, UpdateSpotMutationVariables>(UpdateSpotDocument, options);
      }
export type UpdateSpotMutationHookResult = ReturnType<typeof useUpdateSpotMutation>;
export type UpdateSpotMutationResult = Apollo.MutationResult<UpdateSpotMutation>;
export type UpdateSpotMutationOptions = Apollo.BaseMutationOptions<UpdateSpotMutation, UpdateSpotMutationVariables>;
export const GetSpotsDocument = gql`
    query GetSpots($filter: FilterInput) {
  spots(filter: $filter) {
    items {
      ...Spot
    }
    page
    numberOfItems
  }
}
    ${SpotFragmentDoc}`;

/**
 * __useGetSpotsQuery__
 *
 * To run a query within a React component, call `useGetSpotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSpotsQuery(baseOptions?: Apollo.QueryHookOptions<GetSpotsQuery, GetSpotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpotsQuery, GetSpotsQueryVariables>(GetSpotsDocument, options);
      }
export function useGetSpotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpotsQuery, GetSpotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpotsQuery, GetSpotsQueryVariables>(GetSpotsDocument, options);
        }
export type GetSpotsQueryHookResult = ReturnType<typeof useGetSpotsQuery>;
export type GetSpotsLazyQueryHookResult = ReturnType<typeof useGetSpotsLazyQuery>;
export type GetSpotsQueryResult = Apollo.QueryResult<GetSpotsQuery, GetSpotsQueryVariables>;
export const GetSpotsForSelectDocument = gql`
    query GetSpotsForSelect {
  spots(filter: {itemsPerPage: 10000, page: 1}) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useGetSpotsForSelectQuery__
 *
 * To run a query within a React component, call `useGetSpotsForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotsForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotsForSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpotsForSelectQuery(baseOptions?: Apollo.QueryHookOptions<GetSpotsForSelectQuery, GetSpotsForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpotsForSelectQuery, GetSpotsForSelectQueryVariables>(GetSpotsForSelectDocument, options);
      }
export function useGetSpotsForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpotsForSelectQuery, GetSpotsForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpotsForSelectQuery, GetSpotsForSelectQueryVariables>(GetSpotsForSelectDocument, options);
        }
export type GetSpotsForSelectQueryHookResult = ReturnType<typeof useGetSpotsForSelectQuery>;
export type GetSpotsForSelectLazyQueryHookResult = ReturnType<typeof useGetSpotsForSelectLazyQuery>;
export type GetSpotsForSelectQueryResult = Apollo.QueryResult<GetSpotsForSelectQuery, GetSpotsForSelectQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($dto: CreateUserInput!) {
  createUser(dto: $dto) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: Int!) {
  deleteUser(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: Int!, $dto: UpdateUserInput!) {
  updateUser(id: $id, dto: $dto) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetOwnUserDocument = gql`
    query GetOwnUser {
  ownUser {
    id
    active
    firstName
    lastName
    email
  }
}
    `;

/**
 * __useGetOwnUserQuery__
 *
 * To run a query within a React component, call `useGetOwnUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOwnUserQuery(baseOptions?: Apollo.QueryHookOptions<GetOwnUserQuery, GetOwnUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwnUserQuery, GetOwnUserQueryVariables>(GetOwnUserDocument, options);
      }
export function useGetOwnUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnUserQuery, GetOwnUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwnUserQuery, GetOwnUserQueryVariables>(GetOwnUserDocument, options);
        }
export type GetOwnUserQueryHookResult = ReturnType<typeof useGetOwnUserQuery>;
export type GetOwnUserLazyQueryHookResult = ReturnType<typeof useGetOwnUserLazyQuery>;
export type GetOwnUserQueryResult = Apollo.QueryResult<GetOwnUserQuery, GetOwnUserQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($filter: FilterInput) {
  users(filter: $filter) {
    items {
      ...User
    }
    page
    numberOfItems
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const CreateInvoiceForVoucherDocument = gql`
    mutation CreateInvoiceForVoucher($id: Int!) {
  createInvoiceForVoucher(id: $id) {
    ...Voucher
  }
}
    ${VoucherFragmentDoc}`;
export type CreateInvoiceForVoucherMutationFn = Apollo.MutationFunction<CreateInvoiceForVoucherMutation, CreateInvoiceForVoucherMutationVariables>;

/**
 * __useCreateInvoiceForVoucherMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceForVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceForVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceForVoucherMutation, { data, loading, error }] = useCreateInvoiceForVoucherMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateInvoiceForVoucherMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceForVoucherMutation, CreateInvoiceForVoucherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceForVoucherMutation, CreateInvoiceForVoucherMutationVariables>(CreateInvoiceForVoucherDocument, options);
      }
export type CreateInvoiceForVoucherMutationHookResult = ReturnType<typeof useCreateInvoiceForVoucherMutation>;
export type CreateInvoiceForVoucherMutationResult = Apollo.MutationResult<CreateInvoiceForVoucherMutation>;
export type CreateInvoiceForVoucherMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceForVoucherMutation, CreateInvoiceForVoucherMutationVariables>;
export const CreateVoucherDocument = gql`
    mutation CreateVoucher($dto: CreateVoucherInput!) {
  createVoucher(dto: $dto) {
    ...Voucher
  }
}
    ${VoucherFragmentDoc}`;
export type CreateVoucherMutationFn = Apollo.MutationFunction<CreateVoucherMutation, CreateVoucherMutationVariables>;

/**
 * __useCreateVoucherMutation__
 *
 * To run a mutation, you first call `useCreateVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVoucherMutation, { data, loading, error }] = useCreateVoucherMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCreateVoucherMutation(baseOptions?: Apollo.MutationHookOptions<CreateVoucherMutation, CreateVoucherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVoucherMutation, CreateVoucherMutationVariables>(CreateVoucherDocument, options);
      }
export type CreateVoucherMutationHookResult = ReturnType<typeof useCreateVoucherMutation>;
export type CreateVoucherMutationResult = Apollo.MutationResult<CreateVoucherMutation>;
export type CreateVoucherMutationOptions = Apollo.BaseMutationOptions<CreateVoucherMutation, CreateVoucherMutationVariables>;
export const DeleteVoucherDocument = gql`
    mutation DeleteVoucher($id: Int!) {
  deleteVoucher(id: $id) {
    ...Voucher
  }
}
    ${VoucherFragmentDoc}`;
export type DeleteVoucherMutationFn = Apollo.MutationFunction<DeleteVoucherMutation, DeleteVoucherMutationVariables>;

/**
 * __useDeleteVoucherMutation__
 *
 * To run a mutation, you first call `useDeleteVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVoucherMutation, { data, loading, error }] = useDeleteVoucherMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVoucherMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVoucherMutation, DeleteVoucherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVoucherMutation, DeleteVoucherMutationVariables>(DeleteVoucherDocument, options);
      }
export type DeleteVoucherMutationHookResult = ReturnType<typeof useDeleteVoucherMutation>;
export type DeleteVoucherMutationResult = Apollo.MutationResult<DeleteVoucherMutation>;
export type DeleteVoucherMutationOptions = Apollo.BaseMutationOptions<DeleteVoucherMutation, DeleteVoucherMutationVariables>;
export const UpdateVoucherDocument = gql`
    mutation UpdateVoucher($id: Int!, $dto: UpdateVoucherInput!) {
  updateVoucher(id: $id, dto: $dto) {
    ...Voucher
  }
}
    ${VoucherFragmentDoc}`;
export type UpdateVoucherMutationFn = Apollo.MutationFunction<UpdateVoucherMutation, UpdateVoucherMutationVariables>;

/**
 * __useUpdateVoucherMutation__
 *
 * To run a mutation, you first call `useUpdateVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVoucherMutation, { data, loading, error }] = useUpdateVoucherMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateVoucherMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVoucherMutation, UpdateVoucherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVoucherMutation, UpdateVoucherMutationVariables>(UpdateVoucherDocument, options);
      }
export type UpdateVoucherMutationHookResult = ReturnType<typeof useUpdateVoucherMutation>;
export type UpdateVoucherMutationResult = Apollo.MutationResult<UpdateVoucherMutation>;
export type UpdateVoucherMutationOptions = Apollo.BaseMutationOptions<UpdateVoucherMutation, UpdateVoucherMutationVariables>;
export const UseVoucherDocument = gql`
    mutation UseVoucher($id: Int!) {
  useVoucher(id: $id) {
    id
  }
}
    `;
export type UseVoucherMutationFn = Apollo.MutationFunction<UseVoucherMutation, UseVoucherMutationVariables>;

/**
 * __useUseVoucherMutation__
 *
 * To run a mutation, you first call `useUseVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useVoucherMutation, { data, loading, error }] = useUseVoucherMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUseVoucherMutation(baseOptions?: Apollo.MutationHookOptions<UseVoucherMutation, UseVoucherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UseVoucherMutation, UseVoucherMutationVariables>(UseVoucherDocument, options);
      }
export type UseVoucherMutationHookResult = ReturnType<typeof useUseVoucherMutation>;
export type UseVoucherMutationResult = Apollo.MutationResult<UseVoucherMutation>;
export type UseVoucherMutationOptions = Apollo.BaseMutationOptions<UseVoucherMutation, UseVoucherMutationVariables>;
export const GetVouchersDocument = gql`
    query GetVouchers($filter: FilterInput) {
  vouchers(filter: $filter) {
    items {
      ...Voucher
    }
    page
    numberOfItems
  }
}
    ${VoucherFragmentDoc}`;

/**
 * __useGetVouchersQuery__
 *
 * To run a query within a React component, call `useGetVouchersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVouchersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVouchersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetVouchersQuery(baseOptions?: Apollo.QueryHookOptions<GetVouchersQuery, GetVouchersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVouchersQuery, GetVouchersQueryVariables>(GetVouchersDocument, options);
      }
export function useGetVouchersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVouchersQuery, GetVouchersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVouchersQuery, GetVouchersQueryVariables>(GetVouchersDocument, options);
        }
export type GetVouchersQueryHookResult = ReturnType<typeof useGetVouchersQuery>;
export type GetVouchersLazyQueryHookResult = ReturnType<typeof useGetVouchersLazyQuery>;
export type GetVouchersQueryResult = Apollo.QueryResult<GetVouchersQuery, GetVouchersQueryVariables>;