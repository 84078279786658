import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { PageProps } from '../lib/pageProps';
import {
  useCustomerExistsLazyQuery,
  useSendCustomerLoginMailMutation,
} from '../../../graphql/schema';
import { Page } from '../lib/pageEnum';

interface FormFields {
  email?: string;
}

const EmailPage = ({ navigate, setState, state }: PageProps) => {
  const [form] = Form.useForm<FormFields>();

  const [customerExists] = useCustomerExistsLazyQuery();
  const [sendLoginMail] = useSendCustomerLoginMailMutation();

  useEffect(() => {
    form.setFieldsValue({
      email: state.email || '',
    });
  }, []);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (!values.email || values.email.trim() === '') return;

      const exists = await customerExists({
        variables: {
          email: values.email,
        },
      });

      if (exists?.data?.customerExists) {
        // Send verification mail to proceed
        await sendLoginMail({
          variables: {
            email: values.email,
            callbackURL: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}?productId=${state.productId}`,
          },
        });
        navigate(Page.loginMail);
      } else {
        setState({
          email: values.email,
        });
        navigate(Page.attendance);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <Form layout="vertical" form={form}>
        <h1>Wie können wir Dich erreichen?</h1>

        <p>
          Bitte gib Deine E-Mail Adresse ein, um mit der Buchung zu starten. Du hast schon einmal
          bei uns gebucht? In dem Fall schicken wir Dir gleich eine E-Mail und übernehmen alle
          wichtigen Daten aus Deiner letzten Buchung.
        </p>

        <Input.Group compact>
          <Form.Item
            name="email"
            rules={[{ required: false, message: 'Bitte gib Deine E-Mail Adresse ein' }]}
          >
            <Input
              placeholder="Deine E-Mail Adresse"
              type="email"
              style={{
                minWidth: 300,
              }}
            />
          </Form.Item>
          <Button type="primary" onClick={handleSubmit}>
            Weiter
          </Button>
        </Input.Group>
      </Form>
    </div>
  );
};
export default EmailPage;
