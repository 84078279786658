import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import 'antd/dist/antd.less';
import './index.less';
import App from './App';
import Shop from './widgets/Shop';
import AuthProvider from './auth/authProvider';
import Contact from './widgets/Contact';
import Booking from './widgets/Booking';
import VoucherShop from './widgets/VoucherShop';
import Reviews from './widgets/Reviews';

interface WidgetParameters {
  containerElement: unknown;
  widget: string;
}

// Init GraphQL connection
const graphClient = new ApolloClient({
  uri: `${(window as { apiURL?: string }).apiURL || process.env.REACT_APP_SERVER_URI}/graphql`,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

const widgetRoot = document.getElementById('dtsWidgetScript');
const widget = widgetRoot || process.env.REACT_APP_WIDGET;
if (widget) {
  // Load widget
  const win = window as any;

  const getWidgetComponent = (name: string, parameters: any) => {
    if (name === 'shop') return <Shop />;
    if (name === 'contact') return <Contact />;
    if (name === 'booking') return <Booking />;
    if (name === 'voucherShop') return <VoucherShop />;
    if (name === 'reviews') return <Reviews />;

    return undefined;
  };

  if (widgetRoot) {
    // Load widget parameters
    const queue = win.dtsWidget.q;
    win.dtsWidget = (parameters: WidgetParameters) => {
      const component = getWidgetComponent(parameters.widget, parameters);
      if (component) {
        ReactDOM.render(
          <React.StrictMode>
            <ApolloProvider client={graphClient}>{component}</ApolloProvider>
          </React.StrictMode>,
          parameters.containerElement as any,
        );
      }
    };
    queue.forEach((parameters: [WidgetParameters]) => {
      win.dtsWidget(parameters[0]);
    });
  } else if (process.env.REACT_APP_WIDGET) {
    // Dev mode --> Render widget in root element
    const component = getWidgetComponent(process.env.REACT_APP_WIDGET, {});
    if (component) {
      ReactDOM.render(
        <React.StrictMode>
          <ApolloProvider client={graphClient}>{component}</ApolloProvider>
        </React.StrictMode>,
        document.getElementById('root'),
      );
    }
  }
} else {
  // Load client

  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={graphClient}>
        <AuthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
