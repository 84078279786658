import { Badge, Button, Card, Col, Row, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAnchor,
  faCertificate,
  faRuler,
  faShoePrints,
  faTimes,
  faWeightScale,
  faPencil,
  faShoppingCart,
} from '@fortawesome/pro-light-svg-icons';
import React, { useState } from 'react';
import CourseAllocationDrawer from './CourseAllocationDrawer';
import useAuth from '../../auth/useAuth';

interface Props {
  allocation: {
    id: number;
    customer?: {
      firstName?: string | null;
      lastName?: string | null;
      kiteLevel?: number | null;
      ownGear?: boolean | null;
      shoeSize?: string | null;
      bodyHeight?: string | null;
      bodyWeight?: string | null;
    } | null;
    product?: {
      id: number;
      title: string;
    } | null;
  };
  onDelete: () => void;
  onChange: () => void;
}

const CourseAllocation = ({ allocation, onDelete, onChange }: Props) => {
  const { roles } = useAuth();
  const [editDrawerVisible, setEditDrawerVisible] = useState<boolean>(false);

  return (
    <>
      <Card
        size="small"
        style={{
          marginBottom: 20,
        }}
      >
        <Card.Meta
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>
                {allocation.customer?.firstName} {allocation.customer?.lastName}
              </span>
              {roles.includes('courseplanning') && (
                <Space direction="horizontal">
                  <Button size="small" onClick={() => setEditDrawerVisible(true)}>
                    <FontAwesomeIcon icon={faPencil} />
                  </Button>
                  <Button size="small" onClick={onDelete}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </Space>
              )}
            </div>
          }
          description={
            <Row>
              <Col sm={{ span: 24 }} md={{ span: 12 }}>
                <div>
                  <span style={{ width: 25, display: 'inline-block' }}>
                    <FontAwesomeIcon icon={faShoppingCart} />
                  </span>
                  {allocation.product ? (
                    allocation.product?.title
                  ) : (
                    <Badge
                      count="Produkt fehlt"
                      style={{
                        backgroundColor: '#EC6471',
                      }}
                    />
                  )}
                </div>
                {allocation.customer?.kiteLevel && (
                  <div>
                    <span style={{ width: 25, display: 'inline-block' }}>
                      <FontAwesomeIcon icon={faCertificate} />
                    </span>
                    Level {allocation.customer?.kiteLevel}
                  </div>
                )}
                <div>
                  <span style={{ width: 25, display: 'inline-block' }}>
                    <FontAwesomeIcon icon={faAnchor} />
                  </span>

                  {allocation.customer?.ownGear ? (
                    'Eigenes Material'
                  ) : (
                    <strong>Kein eigenes Material</strong>
                  )}
                </div>
              </Col>
              <Col sm={{ span: 24 }} md={{ span: 12 }}>
                <div>
                  <span style={{ width: 25, display: 'inline-block' }}>
                    <FontAwesomeIcon icon={faShoePrints} />
                  </span>
                  {allocation.customer?.shoeSize}
                </div>
                <div>
                  <span style={{ width: 25, display: 'inline-block' }}>
                    <FontAwesomeIcon icon={faRuler} />
                  </span>
                  {allocation.customer?.bodyHeight} cm
                </div>
                <div>
                  <span style={{ width: 25, display: 'inline-block' }}>
                    <FontAwesomeIcon icon={faWeightScale} />
                  </span>
                  {allocation.customer?.bodyWeight} kg
                </div>
              </Col>
            </Row>
          }
        />
      </Card>

      <CourseAllocationDrawer
        courseAllocation={allocation}
        visible={editDrawerVisible}
        onClose={() => setEditDrawerVisible(false)}
        onSave={onChange}
      />
    </>
  );
};

export default CourseAllocation;
