import { PaginationConfig } from 'antd/lib/pagination';
import { useEffect, useState } from 'react';

interface GenericResponse {
  data?: {
    [key: string]: {
      numberOfItems: number;
    };
  };
}

interface Variables {
  filter: {
    page: number;
    itemsPerPage: number;
    query: string;
    [key: string]: unknown;
  };
}

const usePagination = (
  fetch: (options: { variables: Variables }) => unknown,
  initialPageSize = 20,
) => {
  const [page, setPage] = useState<number>(1);
  const [numberOfItems, setNumberOfItems] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(initialPageSize);

  const [query, setQuery] = useState<string>('');

  const [debouncedQuery, setDebouncedQuery] = useState<string>('');
  const [debouncedQueryTimeout, setDebouncedQueryTimeout] = useState<any>();

  // Debounce query
  useEffect(() => {
    if (query === '') {
      // Clear query immediately
      setDebouncedQuery('');
      if (debouncedQueryTimeout) {
        clearTimeout(debouncedQueryTimeout);
        setDebouncedQueryTimeout(undefined);
      }
    } else {
      // Clear old timeout
      if (debouncedQueryTimeout) clearTimeout(debouncedQueryTimeout);

      // Set new value delayed by timeout
      setDebouncedQueryTimeout(
        setTimeout(() => {
          setDebouncedQuery(query);
          setDebouncedQueryTimeout(undefined);
        }, 1000),
      );
    }
  }, [query]);

  useEffect(() => {
    (async () => {
      const response = (await fetch({
        variables: {
          filter: {
            page,
            itemsPerPage,
            query: debouncedQuery,
          },
        },
      })) as GenericResponse;

      if (!response.data) return;

      // Get first element from response data
      const result = response.data[Object.keys(response.data)[0]];

      // Get total number of items from result
      if (result.numberOfItems === undefined)
        throw Error("Missing key 'numberOfItems' in filtered result");
      setNumberOfItems(result.numberOfItems);
    })();
  }, [fetch, page, itemsPerPage, debouncedQuery]);

  const paginationConfig: PaginationConfig = {
    position: 'bottom',
    onChange: (p, pageSize) => {
      setPage(p);
      setItemsPerPage(pageSize);
    },
    pageSize: itemsPerPage,
    current: page,
    total: numberOfItems,
  };

  return {
    pagination: paginationConfig,
    numberOfItems,
    page,
    setPage,
    itemsPerPage,
    setItemsPerPage,
    numberOfPages: Math.ceil(numberOfItems / itemsPerPage),
    query,
    setQuery,
  };
};

export default usePagination;
