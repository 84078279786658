import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { PageHeader, Space, Button, Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash } from '@fortawesome/pro-light-svg-icons';
import Page from '../layout/Page';
import Content from '../layout/Content';
import Fab from '../layout/Fab';
import confirmModal from '../../helper/confirmModal';

interface Props<FormFieldsType> {
  title: string;
  typeName: string;
  onSave: (fields: FormFieldsType) => void;
  onDelete?: () => void;
  children: ReactElement;
  extra?: ReactNode[];
  initialValues?: Partial<FormFieldsType>;
}

function GenericForm<FormFieldsType>({
  title,
  onSave,
  typeName,
  children,
  onDelete,
  extra,
  initialValues,
}: Props<FormFieldsType>) {
  const navigate = useNavigate();

  const [form] = Form.useForm<FormFieldsType>();
  const [changed, setChanged] = useState<boolean>(false);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues as any);
    }
  }, [initialValues]);

  const handleDelete = async () => {
    if (
      await confirmModal({
        title: `${typeName} löschen`,
        content: 'Bitte bestätige das Löschen',
        okText: `${typeName} löschen`,
        okType: 'danger',
        cancelText: 'Abbrechen',
      })
    ) {
      onDelete?.();
    }
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();

      onSave(values);
      setChanged(false);
    } catch (e) {
      notification.error({
        message: 'Fehler',
        description: 'Beim Speichern ist ein Fehler aufgetreten, bitte versuche es noch einmal.',
      });
      return false;
    }

    return true;
  };

  const handleBack = async () => {
    if (changed) {
      if (
        await confirmModal({
          title: 'Speichern?',
          content: 'Möchtest Du die vorgenommenen Änderungen speichern?',
          okText: 'Speichern',
          cancelText: 'Verwerfen',
        })
      ) {
        if (!(await handleSave())) return;
      }
    }
    navigate(-1);
  };

  const actions = [];
  if (extra) actions.push(...extra);

  if (onDelete) {
    actions.push(
      <Button danger onClick={handleDelete} icon={<FontAwesomeIcon icon={faTrash} />}>
        Löschen
      </Button>,
    );
  }

  return (
    <Page>
      <Fab icon={faCheck} title="Speichern" onClick={handleSave} />

      <PageHeader title={title} onBack={handleBack} extra={actions} />
      <Content>
        <Form layout="vertical" form={form} onFieldsChange={() => setChanged(true)}>
          {children}
        </Form>
      </Content>
    </Page>
  );
}

export default GenericForm;
