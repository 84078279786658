import React from 'react';
import { Moment } from 'moment';
import { Button, Col, DatePicker, Drawer, Form, Input, notification, Row, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/de_DE';
import { useCreateInstructorAvailabilityMutation } from '../../graphql/schema';

export interface Props {
  instructorId: number;
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
}

interface FormFieldsType {
  startDate: Moment;
  endDate: Moment;
  comment: string;
  type: string;
}

const InstructorAvailabilityDrawer = ({ instructorId, visible, onClose, onSave }: Props) => {
  const [form] = Form.useForm<FormFieldsType>();

  const [create] = useCreateInstructorAvailabilityMutation();

  const handleSave = async () => {
    try {
      const values = await form.validateFields();

      await create({
        variables: {
          instructorId,
          dto: {
            startDate: values.startDate.format('YYYY-MM-DD'),
            endDate: values.endDate.format('YYYY-MM-DD'),
            type: values.type,
            comment: values.comment,
          },
        },
      });

      form.resetFields();

      onSave();
      onClose();
    } catch (e) {
      notification.error({
        message: 'Fehler',
        description: 'Beim Speichern ist ein Fehler aufgetreten, bitte versuche es noch einmal.',
      });
    }
  };

  return (
    <Drawer title="Verfügbarkeit eintragen" visible={visible} onClose={onClose}>
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="type" label="Typ" rules={[{ required: true }]}>
              <Select
                placeholder="Typ"
                options={[
                  { label: 'Anwesend', value: 'present' },
                  { label: 'Abwesend', value: 'absent' },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="startDate" label="Von" rules={[{ required: true }]}>
              <DatePicker
                format="DD.MM.YYYY"
                placeholder="Datum auswählen"
                locale={locale}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="endDate" label="Bis" rules={[{ required: true }]}>
              <DatePicker
                format="DD.MM.YYYY"
                placeholder="Datum auswählen"
                locale={locale}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="comment" label="Kommentar" rules={[{ required: false }]}>
              <Input placeholder="Kommentar" />
            </Form.Item>
          </Col>
        </Row>
        <Button onClick={handleSave}>Speichern</Button>
      </Form>
    </Drawer>
  );
};

export default InstructorAvailabilityDrawer;
