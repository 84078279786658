import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCreditCard,
  faCashRegister,
  faBank,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import styles from './SelectPaymentMethod.module.less';

interface Props {
  amount: number;
  invoiceNumber: string;
  onSelect: (paymentMethod: string) => void;
}

interface PaymentMethod {
  id: string;
  label: string;
  icon: IconDefinition;
  generateLink?: () => string;
}

const SelectPaymentMethod = ({ onSelect, amount, invoiceNumber }: Props) => {
  const [selected, setSelected] = useState<string>();

  const handleSelect = (paymentMethod: PaymentMethod) => {
    return () => {
      onSelect(paymentMethod.id);
      setSelected(paymentMethod.id);
      if (paymentMethod.generateLink) {
        window.location.href = paymentMethod.generateLink();
      }
    };
  };

  const paymentMethods: PaymentMethod[] = [
    {
      id: 'sumUp',
      label: 'EC / Kreditkarte',
      icon: faCreditCard,
      generateLink: () => {
        const sumUpKey = 'dcf4a417-9539-4ac7-a30f-2355132f1edc';
        return `sumupmerchant://pay/1.0?amount=${amount}&currency=EUR&affiliate-key=${sumUpKey}&title=${invoiceNumber}&foreign-tx-id=${invoiceNumber}`;
      },
    },
    {
      id: 'cash',
      label: 'Bar',
      icon: faCashRegister,
    },
    {
      id: 'bankTransfer',
      label: 'Überweisung',
      icon: faBank,
    },
  ];

  return (
    <Row>
      {paymentMethods.map((paymentMethod) => (
        <Col xs={24} sm={8} key={paymentMethod.id} className={styles.container}>
          <Card
            className={`${styles.card} ${selected === paymentMethod.id ? styles.selected : ''}`}
            onClick={handleSelect(paymentMethod)}
          >
            <FontAwesomeIcon icon={paymentMethod.icon} size="3x" />
            <div className={styles.label}>{paymentMethod.label}</div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default SelectPaymentMethod;
