import React, { useState } from 'react';
import { Card, Input, List, PageHeader } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import { QueryTuple } from '@apollo/client/react/types/types';
import Page from '../layout/Page';
import Fab from '../layout/Fab';
import Content from '../layout/Content';
import usePagination from '../../helper/hooks/usePagination';
import Loading from '../base/Loading';
import GenericAddDrawer from '../drawer/GenericAddDrawer';
import GenericEditDrawer from '../drawer/GenericEditDrawer';

interface Props<FormFieldsType, ItemType, QueryType> {
  title: string;
  query: () => QueryTuple<QueryType, any>;
  dataKey: string;
  renderItem: (item: ItemType, onSelect: () => void, index: number) => React.ReactElement;
  editForm: () => JSX.Element;
  createForm?: () => JSX.Element;
  singleItemTitle: string;
  onCreate: (values: Partial<FormFieldsType>) => Promise<void>;
  onUpdate: (id: number, values: Partial<FormFieldsType>) => Promise<void>;
  onDelete: (id: number) => Promise<void>;
  prepareFormValues?: (values: ItemType) => FormFieldsType;
  showSearch?: boolean;
}

function GenericCrudList<FormFieldsType, ItemType, QueryType>({
  title,
  query,
  dataKey,
  renderItem,
  editForm,
  createForm,
  singleItemTitle,
  onCreate,
  onUpdate,
  onDelete,
  prepareFormValues,
  showSearch,
}: Props<FormFieldsType, ItemType, QueryType>) {
  const [getItems, { data, loading, refetch }] = query();
  const { pagination, query: searchQuery, setQuery } = usePagination(getItems);

  const [addDrawerVisible, setAddDrawerVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ItemType>();

  const handleSelect = (item: ItemType) => {
    return () => {
      setSelectedItem(item);
    };
  };

  const handleCreate = async (values: FormFieldsType) => {
    await onCreate(values);
    refetch();
  };

  const handleUpdate = async (values: FormFieldsType) => {
    await onUpdate((selectedItem as any).id, values);
    refetch();
  };

  const handleDelete = async () => {
    await onDelete((selectedItem as any).id);
    refetch();
  };

  if (loading) return <Loading />;

  return (
    <Page>
      <Fab
        icon={faPlus}
        onClick={() => setAddDrawerVisible(true)}
        title={`${singleItemTitle} anlegen`}
      />

      <PageHeader
        title={title}
        extra={
          showSearch
            ? [
                <Input
                  key={1}
                  value={searchQuery}
                  onChange={(e) => setQuery(e.target.value)}
                  prefix={<FontAwesomeIcon icon={faSearch} />}
                  allowClear
                />,
              ]
            : []
        }
      />
      <Content>
        <Card>
          <List
            pagination={pagination}
            itemLayout="horizontal"
            dataSource={data ? (data as any)[dataKey].items : undefined}
            renderItem={(item: ItemType, index) => renderItem(item, handleSelect(item), index)}
          />
        </Card>
      </Content>

      <GenericAddDrawer<FormFieldsType>
        title={`${singleItemTitle} anlegen`}
        visible={addDrawerVisible}
        onClose={() => setAddDrawerVisible(false)}
        onSave={handleCreate}
      >
        {createForm ? createForm() : editForm()}
      </GenericAddDrawer>

      <GenericEditDrawer<FormFieldsType>
        title={`${singleItemTitle} bearbeiten`}
        visible={!!selectedItem}
        onClose={() => setSelectedItem(undefined)}
        onSave={handleUpdate}
        onDelete={handleDelete}
        initialValues={
          selectedItem && prepareFormValues ? prepareFormValues(selectedItem) : selectedItem
        }
      >
        {editForm()}
      </GenericEditDrawer>
    </Page>
  );
}

export default GenericCrudList;
