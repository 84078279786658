import React, { useEffect } from 'react';
import { Button, Col, Form, Input, Row, Space, Typography } from 'antd';
import { PageProps } from '../lib/pageProps';
import { Page } from '../lib/pageEnum';

export interface FormFields {
  firstName?: string;
  lastName?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  city?: string;

  email?: string;
  voucherText?: string;
  voucherRecipient?: string;
}

const DetailsPage = ({ navigate, setState, state }: PageProps) => {
  const [form] = Form.useForm<FormFields>();

  useEffect(() => {
    form.setFieldsValue({
      email: state.email || '',
      firstName: state.firstName || '',
      lastName: state.lastName || '',
      street: state.street || '',
      streetNumber: state.streetNumber || '',
      zipCode: state.zipCode || '',
      city: state.city || '',
      voucherText: state.voucherText || '',
      voucherRecipient: state.voucherRecipient || '',
    });
  }, []);

  const handleNext = async () => {
    try {
      const values = await form.validateFields();

      await setState({
        email: values.email || '',
        firstName: values.firstName || '',
        lastName: values.lastName || '',
        street: values.street || '',
        streetNumber: values.streetNumber || '',
        zipCode: values.zipCode || '',
        city: values.city || '',
        voucherText: values.voucherText || '',
        voucherRecipient: values.voucherRecipient || '',
      });
      navigate(Page.checkout);
    } catch (e) {
      console.log('ERRR');
      // TODO
    }
  };

  return (
    <div>
      <Space direction="vertical" size="large">
        <Form layout="vertical" form={form}>
          <h1>Gutschein</h1>

          <Typography.Paragraph type="secondary">
            Bitte nenne uns den Namen der Person, für den Du den Gutschein bestellst. Du kannst
            außerdem noch einen kurzen Text angeben, der ebenfalls auf dem Gutschein erscheint.
          </Typography.Paragraph>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="voucherRecipient"
                label="Für wen ist der Gutschein?"
                rules={[{ required: true, message: 'Bitte gib den Namen des Empfängers ein' }]}
              >
                <Input placeholder="Name des Empfängers" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="voucherText" label="Gutscheintext">
                <Input.TextArea placeholder="z.B. Alles Gute zum Geburtstag lieber Max!" rows={6} />
              </Form.Item>
            </Col>
          </Row>

          <p>Rechnungsempfänger</p>
          <div>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="firstName"
                  label="Vorname"
                  rules={[{ required: true, message: 'Bitte gib Deinen Vornamen ein' }]}
                >
                  <Input placeholder="Dein Vorname" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="lastName"
                  label="Nachname"
                  rules={[{ required: true, message: 'Bitte gib Deinen Nachnamen ein' }]}
                >
                  <Input placeholder="Dein Nachname" />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item name="street" rules={[{ required: false }]}>
                  <Input placeholder="Straße" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="streetNumber" rules={[{ required: false }]}>
                  <Input placeholder="Hausnr." />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="zipCode" rules={[{ required: false }]}>
                  <Input placeholder="PLZ" />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item name="city" rules={[{ required: false }]}>
                  <Input placeholder="Ort" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="email"
                  label="E-Mail Adresse (für Rechnung & Gutschein)"
                  rules={[{ required: true, message: 'Bitte gib Deine E-Mail Adresse ein' }]}
                >
                  <Input placeholder="Deine E-Mail Adresse" type="email" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Space>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Button type="primary" onClick={handleNext}>
          Weiter
        </Button>
      </div>
    </div>
  );
};
export default DetailsPage;
