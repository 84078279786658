import React from 'react';
import { Col, Form, Input, InputNumber, Row } from 'antd';

export interface VoucherFormFields {
  title: string;
  recipient: string;
  text: string;
  price: number;
  invoiceFirstName: string;
  invoiceLastName: string;
}

const VoucherForm = () => {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <Form.Item name="title" label="Umfang des Gutscheins" rules={[{ required: true }]}>
          <Input placeholder="z.B. 1x Grundkurs 3 Std." />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="recipient" label="Empfänger" rules={[{ required: true }]}>
          <Input placeholder="Name des Empfängers" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="text" label="Text" rules={[{ required: true }]}>
          <Input.TextArea rows={5} placeholder="Text" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="price"
          label="Wert des Gutscheins (muss auf dem Gutschein nicht angezeigt werden)"
          rules={[{ required: true }]}
        >
          <InputNumber addonAfter="€" decimalSeparator="," precision={2} />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item name="invoiceFirstName" label="Rechnung Vorname">
          <Input placeholder="Vorname auf der Rechnung" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="invoiceLastName" label="Rechnung Nachname">
          <Input placeholder="Nachname auf der Rechnung" />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default VoucherForm;
