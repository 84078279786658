import React, { useState } from 'react';
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Space,
  Typography,
  DatePicker,
  Card,
  Avatar,
} from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';

interface Props {
  value?: number;
  onChange?: (value: number) => void;
}

export const kiteLevels = [
  {
    level: 0,
    title: 'Landratte',
    description: 'Ich habe noch keine Erfahrung im Kitesurfen',
  },
  {
    level: 1,
    title: 'Das ist lange her...',
    description:
      'Ich habe schon einmal einen Kitekurs gemacht, aber möchte die Basics noch einmal auffrischen',
  },

  {
    level: 2,
    title: 'Bodydrag & Wasserstart',
    description: 'Ich habe bereits Bodydrags gemacht und kann aus dem Wasser starten',
  },

  {
    level: 3,
    title: 'Wasserstart & >50m fahren',
    description: 'Ich kann aus dem Wasser starten und mehr als 50m fahren',
  },

  {
    level: 4,
    title: 'Höhe halten & Wenden',
    description: 'Ich kann Höhe halten und wenden...jetzt will ich springen ;-)',
  },

  {
    level: 6,
    title: 'Fly Me To The Moon!',
    description: 'Ich kann kiten und möchte an meiner Technik arbeiten oder neue Sprünge lernen',
  },
];

const KiteLevelSelect = ({ value, onChange }: Props) => {
  const handleSelectLevel = (level: number) => {
    return () => {
      onChange?.(level);
    };
  };

  return (
    <Space direction="vertical">
      {kiteLevels.map((level) => (
        <Card
          key={level.level}
          onClick={handleSelectLevel(level.level)}
          style={{
            backgroundColor: level.level === value ? '#D5F1F5' : 'inherit',
            cursor: 'pointer',
          }}
        >
          <Card.Meta title={level.title} description={level.description} />
        </Card>
      ))}
    </Space>
  );
};

export default KiteLevelSelect;
