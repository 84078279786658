import React, { useEffect } from 'react';
import { Button, Form, Space } from 'antd';
import { PageProps } from '../lib/pageProps';
import { Page } from '../lib/pageEnum';
import ContactInfo, {
  ContactInfoFormFields,
} from '../../../components/customerRegistration/contactInfo';

const ContactInfoPage = ({ navigate, setState, state }: PageProps) => {
  const [form] = Form.useForm<ContactInfoFormFields>();

  useEffect(() => {
    form.setFieldsValue({
      phone: state.customer?.phone || '',
      emergencyPhone: state.customer?.emergencyPhone || '',
    });
  }, []);

  const handleNext = async () => {
    try {
      const values = await form.validateFields();

      await setState({
        customer: {
          ...state.customer,
          phone: values.phone,
          emergencyPhone: values.emergencyPhone,
        },
      });
      navigate(Page.bodyInfo);
    } catch (e) {
      console.log('ERRR');
      // TODO
    }
  };

  return (
    <div>
      <Space direction="vertical" size="large">
        <ContactInfo form={form} hideEmail />
      </Space>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Button type="primary" onClick={handleNext}>
          Weiter
        </Button>
      </div>
    </div>
  );
};
export default ContactInfoPage;
