import React from 'react';
import { Moment } from 'moment';
import { Card, Col, DatePicker, Form, Input, Row, Select, Space } from 'antd';
import locale from 'antd/es/date-picker/locale/de_DE';
import { kiteLevels } from '../customerRegistration/kiteLevelSelect';

export interface FormFields {
  firstName?: string;
  lastName?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  city?: string;
  dateOfBirth?: Moment;
  email?: string;
  phone?: string;
  emergencyPhone?: string;
  shoeSize?: string;
  bodyHeight?: string;
  bodyWeight?: string;
  kiteLevel?: number;
  comment?: string;
  internalComment?: string;
}

const CustomerForm = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col
        md={{
          span: 12,
        }}
        sm={{
          span: 24,
        }}
        style={{
          width: '100%',
        }}
      >
        <Card>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="firstName" label="Vorname" rules={[{ required: false }]}>
                <Input placeholder="Vorname" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="lastName" label="Nachname" rules={[{ required: false }]}>
                <Input placeholder="Nachname" />
              </Form.Item>
            </Col>
          </Row>

          <p>Anschrift</p>
          <div>
            <Row gutter={12}>
              <Col span={16}>
                <Form.Item name="street" rules={[{ required: false }]}>
                  <Input placeholder="Straße" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="streetNumber" rules={[{ required: false }]}>
                  <Input placeholder="Hausnr." />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item name="zipCode" rules={[{ required: false }]}>
                  <Input placeholder="PLZ" />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item name="city" rules={[{ required: false }]}>
                  <Input placeholder="Ort" />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Form.Item name="dateOfBirth" label="Geburtsdatum" rules={[{ required: false }]}>
            <DatePicker
              format="DD.MM.YYYY"
              placeholder="Datum auswählen"
              locale={locale}
              style={{
                width: '50%',
              }}
            />
          </Form.Item>

          <Form.Item name="email" label="E-Mail" rules={[{ required: false }]}>
            <Input placeholder="E-Mail Adresse" type="email" />
          </Form.Item>

          <Form.Item name="phone" label="Handynummer" rules={[{ required: false }]}>
            <Input placeholder="Handynummer" />
          </Form.Item>

          <Form.Item
            name="emergencyPhone"
            label="Notfallkontakt Telefonnummer"
            rules={[{ required: false }]}
          >
            <Input placeholder="Notfallkontakt Telefonnummer" />
          </Form.Item>
        </Card>
      </Col>
      <Col
        md={{
          span: 12,
        }}
        sm={{
          span: 24,
        }}
        style={{
          width: '100%',
        }}
      >
        <Card>
          <Form.Item name="kiteLevel" label="Kite Level" rules={[{ required: false }]}>
            <Select>
              {kiteLevels.map((level) => (
                <Select.Option key={level.level} value={level.level}>
                  {level.level} - {level.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="shoeSize" label="Schuhgröße" rules={[{ required: false }]}>
            <Input placeholder="Schuhgröße (z.B. 43)" />
          </Form.Item>

          <Form.Item name="bodyHeight" label="Körpergröße" rules={[{ required: false }]}>
            <Input placeholder="Größe (z.B. 182)" suffix="cm" />
          </Form.Item>

          <Form.Item name="bodyWeight" label="Körpergewicht" rules={[{ required: false }]}>
            <Input placeholder="Gewicht...ungefähr" suffix="kg" />
          </Form.Item>

          <Form.Item name="comment" label="Kommentar des Kunden" rules={[{ required: false }]}>
            <Input.TextArea readOnly placeholder="" rows={6} />
          </Form.Item>
        </Card>
      </Col>
      <Col
        sm={{
          span: 24,
        }}
        style={{
          width: '100%',
        }}
      >
        <Card>
          <Form.Item
            name="internalComment"
            label="Interne Kommentare"
            rules={[{ required: false }]}
          >
            <Input.TextArea placeholder="" rows={6} />
          </Form.Item>
        </Card>
      </Col>
    </Row>
  );
};

export default CustomerForm;
