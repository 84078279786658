import React from 'react';
import { Checkbox, Col, Form, Input, List, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {
  GetSpotsQuery,
  SpotFragment,
  useCreateSpotMutation,
  useDeleteSpotMutation,
  useGetSpotsLazyQuery,
  useUpdateSpotMutation,
} from '../graphql/schema';
import GenericCrudList from '../components/crud/GenericCrudList';

interface FormFields {
  name: string;
  active: boolean;
  comment: string;
  address: string;
  directions: string;
  lat: number;
  lng: number;
}

const Spots = () => {
  const [create] = useCreateSpotMutation();
  const [update] = useUpdateSpotMutation();
  const [remove] = useDeleteSpotMutation();

  const handleCreate = async (values: Partial<FormFields>) => {
    await create({
      variables: {
        dto: {
          name: values.name || '',
          active: !!values.active,
          comment: values.comment || '',
          address: values.address || '',
          directions: values.directions || '',
          lat: values.lat || 0,
          lng: values.lng || 0,
        },
      },
    });
  };

  const handleUpdate = async (id: number, values: Partial<FormFields>) => {
    await update({
      variables: {
        id,
        dto: {
          name: values.name || '',
          active: !!values.active,
          comment: values.comment || '',
          address: values.address || '',
          directions: values.directions || '',
          lat: values.lat || 0,
          lng: values.lng || 0,
        },
      },
    });
  };

  const handleDelete = async (id: number) => {
    await remove({
      variables: {
        id,
      },
    });
  };

  const form = () => {
    return (
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="active" rules={[{ required: false }]} valuePropName="checked">
            <Checkbox>Spot ist aktiv</Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="comment" label="Kommentar (öffentlich)" rules={[{ required: false }]}>
            <TextArea placeholder="Kommentar" rows={4} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="address" label="Adresse (öffentlich)" rules={[{ required: false }]}>
            <TextArea placeholder="Adresse" rows={4} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="directions"
            label="Anfahrtsbeschreibung (öffentlich)"
            rules={[{ required: false }]}
          >
            <TextArea placeholder="Anfahrtsbeschreibung" rows={4} />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <GenericCrudList<FormFields, SpotFragment, GetSpotsQuery>
      title="Spots"
      singleItemTitle="Spot"
      query={useGetSpotsLazyQuery}
      dataKey="spots"
      editForm={form}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      renderItem={(item, onSelect) => (
        <List.Item>
          <List.Item.Meta
            title={<a onClick={onSelect}>{item.name}</a>}
            description={item.comment}
          />
        </List.Item>
      )}
    />
  );
};

export default Spots;
