import React from 'react';
import { Select } from 'antd';
import { useGetInstructorsForSelectQuery } from '../../../graphql/schema';

interface Props {
  value?: number;
  onChange?: (id: number) => void;
  placeholder: string;
}

const InstructorSelect = ({ value, onChange, placeholder }: Props) => {
  const { data, loading } = useGetInstructorsForSelectQuery();

  return (
    <Select
      placeholder={placeholder}
      onChange={onChange}
      loading={loading}
      value={value}
      options={data?.instructors.items.map((instructor) => {
        return {
          label: instructor.name,
          value: instructor.id,
        };
      })}
    />
  );
};

export default InstructorSelect;
