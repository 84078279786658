import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Checkbox, Col, Form, Row, Space } from 'antd';
import Meta from 'antd/es/card/Meta';
import { faCcMastercard, faCcVisa, faCcApplePay } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageProps } from '../lib/pageProps';
import {
  useCreateOrderMutation,
  useGetProductsForBookingQuery,
  useGetProductsForVoucherShopQuery,
  useRegisterCustomerMutation,
  useUpdateCustomerWithTokenMutation,
} from '../../../graphql/schema';
import Loading from '../../../components/base/Loading';
import formatAmount from '../../../helper/formatAmount';
import checkboxValidator from '../../../helper/checkboxValidator';
import { Page } from '../lib/pageEnum';
import { ProductWrapper } from './Product';

interface FormFields {
  terms?: boolean;
  privacy?: boolean;
}

enum PaymentMethod {
  online,
}

const CheckoutPage = ({ navigate, setState, state }: PageProps) => {
  const [form] = Form.useForm<FormFields>();
  const { loading: productsLoading, data } = useGetProductsForVoucherShopQuery();

  const [createOrder] = useCreateOrderMutation();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!state.productId) return;

    try {
      await form.validateFields();

      setLoading(true);

      const redirectURL = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}?page=${Page.payment}`;

      const response = (
        await createOrder({
          variables: {
            dto: {
              firstName: state.firstName || '',
              lastName: state.lastName || '',
              street: state.street || '',
              streetNumber: state.streetNumber || '',
              zipCode: state.zipCode || '',
              city: state.city || '',
              email: state.email || '',
              positions: [
                {
                  productId: state.productId,
                  quantity: 1,
                  data: JSON.stringify({
                    voucherText: state.voucherText,
                    voucherRecipient: state.voucherRecipient,
                  }),
                },
              ],
              redirectURL,
            },
          },
        })
      ).data?.createOrder;

      await localStorage.setItem('paymentId', (response as any).paymentId);
      window.location.href = (response as any).paymentLink;
    } catch (e) {
      console.log('ERRR');
      // TODO
    }
    setLoading(false);
  };

  if (productsLoading || loading) return <Loading />;

  const product = data?.publicProductsForVoucherShop.find((p) => p.id === state.productId);
  if (!product) return <Loading />;

  return (
    <div>
      <Row>
        <Col xs={20} sm={8} offset={2}>
          <ProductWrapper discount={data?.voucherDiscount}>
            <Card cover={<img alt={product.title} src={product.image} />}>
              <Meta title={`Gutschein: ${product.title}`} description={product.description} />
              <div
                style={{
                  fontSize: 20,
                  paddingTop: 10,
                }}
              >
                {(data?.voucherDiscount || 0) > 0 && (
                  <>
                    <small
                      style={{
                        textDecoration: 'line-through',
                      }}
                    >
                      {formatAmount(product.price)}
                    </small>
                    <br />
                  </>
                )}
                {formatAmount(product.price - (product.price * (data?.voucherDiscount || 0)) / 100)}
              </div>
            </Card>
          </ProductWrapper>
          <br />
        </Col>

        <Col xs={24} sm={12} offset={2}>
          <h1>Deine Bestellung</h1>
          <h2>Rechnungsempfänger</h2>
          <Row>
            {[
              {
                label: 'Vorname',
                value: state.firstName,
              },
              {
                label: 'Nachname',
                value: state.lastName,
              },
              {
                label: 'Straße/Hausnr.',
                value: `${state.street} ${state.streetNumber}`,
              },
              {
                label: 'PLZ/Ort',
                value: `${state.zipCode} ${state.city}`,
              },
              {
                label: 'E-Mail',
                value: state.email,
              },
            ].map(({ label, value }) => (
              <>
                <Col
                  sm={8}
                  xs={24}
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 5,
                  }}
                >
                  {label}:
                </Col>
                <Col
                  xs={24}
                  sm={16}
                  style={{
                    marginBottom: 5,
                  }}
                >
                  {value}
                </Col>
              </>
            ))}
          </Row>
          <br />
          <h2>Gutschein</h2>
          <Row>
            {[
              {
                label: 'Empfänger',
                value: state.voucherRecipient,
              },
              {
                label: 'Text',
                value: state.voucherText,
              },
            ].map(({ label, value }) => (
              <>
                <Col
                  sm={8}
                  xs={24}
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 5,
                  }}
                >
                  {label}:
                </Col>
                <Col
                  xs={24}
                  sm={16}
                  style={{
                    marginBottom: 5,
                  }}
                >
                  {value}
                </Col>
              </>
            ))}
          </Row>

          <br />
          <Form layout="vertical" form={form}>
            <Form.Item
              name="privacy"
              rules={[
                {
                  required: true,
                  message:
                    'Um Deine Buchung abzuschließen, musst Du unsere Datenschutzinformationen akzeptieren',
                  validator: checkboxValidator,
                },
              ]}
              valuePropName="checked"
            >
              <Checkbox>
                Ich habe die{' '}
                <a
                  rel="noreferrer"
                  href="https://rueckenwind-kitesurf.de/datenschutz"
                  target="_blank"
                >
                  Datenschutzinformationen
                </a>{' '}
                gelesen und akzeptiere sie.
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="terms"
              rules={[
                {
                  required: true,
                  message: 'Bitte akzeptiere unsere AGB',
                  validator: checkboxValidator,
                },
              ]}
              valuePropName="checked"
            >
              <Checkbox>
                Ich erkläre mich mit den{' '}
                <a rel="noreferrer" href="https://rueckenwind-kitesurf.de/agb" target="_blank">
                  Allgemeinen Geschäftsbedingungen
                </a>{' '}
                einverstanden
              </Checkbox>
            </Form.Item>
          </Form>
          <div
            style={{
              textAlign: 'left',
            }}
          >
            <Button type="primary" size="large" onClick={handleSubmit}>
              Jetzt kostenpflichtig bestellen
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default CheckoutPage;
