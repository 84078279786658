import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { PageHeader, List, Input, Card, Space, Calendar as CalendarComponent, Badge } from 'antd';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import 'moment/locale/de';
import Page from '../components/layout/Page';
import Content from '../components/layout/Content';
import {
  useGetCoursesInDateRangeLazyQuery,
  useGetCustomerAttendancesLazyQuery,
  useGetInstructorAvailabilitiesLazyQuery,
} from '../graphql/schema';
import booking from '../widgets/Booking';

interface CalendarEvent {
  title: string;
  allDay: boolean;
  start: string;
  end: string;
  backgroundColor: string;
  borderColor: string;
  textColor: string;
}

const Calendar = () => {
  const [getInstructorAvailabilities] = useGetInstructorAvailabilitiesLazyQuery();
  const [getCustomerAttendances] = useGetCustomerAttendancesLazyQuery();
  const [getCourses] = useGetCoursesInDateRangeLazyQuery();

  const fetchEvents = useMemo(() => {
    return (
      fetchInfo: { startStr: string; endStr: string },
      successCallback: (e: CalendarEvent[]) => void,
    ) => {
      (async () => {
        const newEvents: CalendarEvent[] = [];

        const instructorAvailabilities = await getInstructorAvailabilities({
          variables: {
            startDate: fetchInfo.startStr,
            endDate: fetchInfo.endStr,
          },
        });

        instructorAvailabilities.data?.instructorAvailabilities.forEach((a) => {
          newEvents.push({
            title: `${a.instructor.name} ${a.comment ? `(${a.comment})` : ''}`,
            start: a.startDate,
            end: moment(a.startDate).isSame(moment(a.endDate), 'day')
              ? a.endDate
              : moment(a.endDate).add(1, 'day').format('YYYY-MM-DD'),
            allDay: true,
            backgroundColor: a.type === 'present' ? a.instructor.color || '#222' : '#D61C4E',
            borderColor: a.type === 'present' ? a.instructor.color || '#222' : '#D61C4E',
            textColor: '#fff',
          });
        });

        const customerAttendances = await getCustomerAttendances({
          variables: {
            startDate: fetchInfo.startStr,
            endDate: fetchInfo.endStr,
          },
        });

        customerAttendances.data?.customerAttendances.forEach((a) => {
          newEvents.push({
            title: `${a.customer.firstName} ${a.customer.lastName} ${
              a.desiredProduct ? `(${a.desiredProduct.title})` : ''
            }${a.booking && !a.booking?.paid ? ' (Zahlung fehlgeschlagen!)' : ''}`,
            start: a.startDate,
            end: moment(a.startDate).isSame(moment(a.endDate), 'day')
              ? a.endDate
              : moment(a.endDate).add(1, 'day').format('YYYY-MM-DD'),
            allDay: true,
            backgroundColor: a.booking && a.booking.paid ? '#8CC966' : '#eadd63',
            borderColor: a.booking && a.booking.paid ? '#8CC966' : '#eadd63',
            textColor: a.booking && a.booking.paid ? '#fff' : '#222',
          });
        });

        const courses = await getCourses({
          variables: {
            startDate: fetchInfo.startStr,
            endDate: fetchInfo.endStr,
          },
        });

        courses.data?.coursesInDateRange.forEach((a) => {
          newEvents.push({
            title: a.title,
            start: a.startDate,
            end: a.endDate,
            allDay: false,
            backgroundColor: '#ccc',
            borderColor: '#ccc',
            textColor: '#222',
          });
        });

        successCallback(newEvents);
      })();
    };
  }, []);

  return (
    <Page>
      <PageHeader title="Kalender" />
      <Content>
        <Card>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridWeek"
            locale="de"
            firstDay={1}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'dayGridDay,dayGridWeek,dayGridMonth',
            }}
            eventSources={[fetchEvents]}
            buttonText={{
              today: 'Heute',
              day: 'Tag',
              week: 'Woche',
              month: 'Monat',
            }}
          />
        </Card>
      </Content>
    </Page>
  );
};

export default Calendar;
