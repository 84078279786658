import React, { useEffect } from 'react';
import { Button, Form, Space } from 'antd';
import { PageProps } from '../lib/pageProps';
import { Page } from '../lib/pageEnum';
import ContactInfo, {
  ContactInfoFormFields,
} from '../../../components/customerRegistration/contactInfo';
import BodyInfo, { BodyInfoFormFields } from '../../../components/customerRegistration/bodyInfo';

const BodyInfoPage = ({ navigate, setState, state }: PageProps) => {
  const [form] = Form.useForm<BodyInfoFormFields>();

  useEffect(() => {
    form.setFieldsValue({
      shoeSize: state.customer?.shoeSize || '',
      bodyHeight: state.customer?.bodyHeight || '',
      bodyWeight: state.customer?.bodyWeight || '',
      ownGear: state.customer?.ownGear || false,
    });
  }, []);

  const handleNext = async () => {
    try {
      const values = await form.validateFields();

      await setState({
        customer: {
          ...state.customer,
          shoeSize: values.shoeSize,
          bodyHeight: values.bodyHeight,
          bodyWeight: values.bodyWeight,
          ownGear: values.ownGear,
        },
      });
      navigate(Page.kiteLevel);
    } catch (e) {
      console.log('ERRR');
      // TODO
    }
  };

  return (
    <div>
      <Space direction="vertical" size="large">
        <BodyInfo form={form} />
      </Space>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Button type="primary" onClick={handleNext}>
          Weiter
        </Button>
      </div>
    </div>
  );
};
export default BodyInfoPage;
