import React from 'react';
import { Affix, Button, Menu, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import styles from './Fab.module.less';

interface Props {
  icon: IconDefinition;
  title?: string;
  onClick?: () => void;
}

const Fab = ({ icon, onClick, title }: Props) => {
  return (
    <div className={styles.container}>
      <Button type="primary" shape={title ? 'round' : 'circle'} onClick={onClick} size="large">
        <Space>
          <FontAwesomeIcon icon={icon} />
          {title && <span>{title}</span>}
        </Space>
      </Button>
    </div>
  );
};

export default Fab;
