import React, { useState } from 'react';
import { PageHeader, List, Input, Card, Space, Button } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTicket,
  faCalendar,
  faPlus,
  faSearch,
  faEuro,
  faFilePdf,
} from '@fortawesome/pro-light-svg-icons';
import Page from '../components/layout/Page';
import Content from '../components/layout/Content';
import Fab from '../components/layout/Fab';
import {
  useCreateInvoiceForVoucherMutation,
  useGetVouchersLazyQuery,
  useUseVoucherMutation,
  Voucher,
  VoucherFragment,
} from '../graphql/schema';
import usePagination from '../helper/hooks/usePagination';
import Loading from '../components/base/Loading';
import formatAmount from '../helper/formatAmount';
import confirmModal from '../helper/confirmModal';
import AddVoucherDrawer from '../components/voucher/AddVoucherDrawer';
import EditVoucherDrawer from '../components/voucher/EditVoucherDrawer';

const Vouchers = () => {
  const [getVouchers, { data, loading, refetch }] = useGetVouchersLazyQuery();
  const { pagination, query, setQuery } = usePagination(getVouchers);

  const [addVoucherDrawerVisible, setAddVoucherDrawerVisible] = useState<boolean>(false);
  const [selectedItemForEditDrawer, setSelectedItemForEditDrawer] = useState<VoucherFragment>();

  const [markVoucherUsed] = useUseVoucherMutation();

  const [createInvoice] = useCreateInvoiceForVoucherMutation();

  const handleOpenVoucher = (voucher: Voucher) => {
    return () => {
      window.open(
        `${
          (window as { apiURL?: string }).apiURL || process.env.REACT_APP_SERVER_URI
        }/voucher/file/${voucher.code}/default/Gutschein.pdf`,
      );
    };
  };

  const handleCreateInvoice = (voucher: Voucher) => {
    return async () => {
      if (voucher.invoiceCreated) return;

      if (
        await confirmModal({
          title: 'Rechnung erstellen',
          content: 'Sicher, dass für diesen Gutschein eine Rechnung erstellt werden soll?',
          cancelText: 'Abbrechen',
          okText: 'Rechnung erstellen',
        })
      ) {
        await createInvoice({
          variables: {
            id: voucher.id,
          },
        });
        refetch();
      }
    };
  };

  const handleUseVoucher = (voucher: Voucher) => {
    return async () => {
      if (
        await confirmModal({
          title: 'Gutschein einlösen',
          content: (
            <Space direction="vertical">
              <Space>
                <strong>Für:</strong>
                <span>{voucher.recipient}</span>
              </Space>
              <Space>
                <strong>Umfang:</strong>
                <span>{voucher.title}</span>
              </Space>
            </Space>
          ),
          okText: 'Einlösen',
          cancelText: 'Abbrechen',
        })
      ) {
        await markVoucherUsed({
          variables: {
            id: voucher.id,
          },
        });
        refetch();
      }
    };
  };

  const handleSelect = (voucher: VoucherFragment) => {
    return () => {
      setSelectedItemForEditDrawer(voucher);
    };
  };

  if (loading) return <Loading />;

  return (
    <Page>
      <Fab
        icon={faPlus}
        onClick={() => setAddVoucherDrawerVisible(true)}
        title="Gutschein erstellen"
      />

      <PageHeader
        title="Gutscheine"
        extra={[
          <Input
            key={1}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            allowClear
          />,
        ]}
      />
      <Content>
        <Card>
          <List
            pagination={pagination}
            itemLayout="horizontal"
            dataSource={data?.vouchers.items}
            renderItem={(item: VoucherFragment) => (
              <List.Item
                key={item.id}
                style={{
                  alignItems: 'flex-start',
                  textDecoration: item.used ? 'line-through' : 'none',
                }}
              >
                <List.Item.Meta
                  title={
                    <a onClick={handleSelect(item)}>
                      {item.title} für {item.recipient}
                    </a>
                  }
                  description={
                    <Space size={24}>
                      <Space>
                        <FontAwesomeIcon icon={faTicket} />
                        <span>{item.code.substring(0, 14)}...</span>
                      </Space>

                      {!item.used ? (
                        <Space>
                          <FontAwesomeIcon icon={faCalendar} />
                          {moment(item.createdAt).format('DD.MM.YYYY')}
                        </Space>
                      ) : (
                        <Space>
                          <FontAwesomeIcon icon={faCalendar} />
                          Genutzt am {moment(item.usedAt).format('DD.MM.YYYY')}
                        </Space>
                      )}

                      <Space>
                        <FontAwesomeIcon icon={faEuro} />
                        {formatAmount(item.price)}
                      </Space>
                    </Space>
                  }
                />

                <Space>
                  <Button onClick={handleOpenVoucher(item)}>
                    <Space>
                      <FontAwesomeIcon icon={faFilePdf} />
                      Anzeigen
                    </Space>
                  </Button>
                  {!item.invoiceCreated && (
                    <Button onClick={handleCreateInvoice(item)}>
                      <Space>
                        <FontAwesomeIcon icon={faEuro} />
                        Rechnung erstellen
                      </Space>
                    </Button>
                  )}
                  {!item.used && (
                    <Button onClick={handleUseVoucher(item)}>
                      <Space>
                        <FontAwesomeIcon icon={faTicket} />
                        Einlösen
                      </Space>
                    </Button>
                  )}
                </Space>
              </List.Item>
            )}
          />
        </Card>
      </Content>

      <AddVoucherDrawer
        visible={addVoucherDrawerVisible}
        onClose={() => setAddVoucherDrawerVisible(false)}
        onSave={refetch}
      />

      <EditVoucherDrawer
        visible={!!selectedItemForEditDrawer}
        onClose={() => setSelectedItemForEditDrawer(undefined)}
        onSave={refetch}
        voucher={selectedItemForEditDrawer}
      />
    </Page>
  );
};

export default Vouchers;
