import React, { useState } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, Space, Typography, DatePicker } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';

export interface ContactInfoFormFields {
  email?: string;
  phone?: string;
  emergencyPhone?: string;
}

interface Props {
  form: FormInstance<ContactInfoFormFields>;
  hideEmail?: boolean;
}

const ContactInfo = ({ form, hideEmail }: Props) => {
  return (
    <Form layout="vertical" form={form}>
      <h1>Wie können wir Dich erreichen?</h1>

      <Typography.Paragraph type="secondary">
        Bitte nenne uns Deine E-Mail Adresse, damit wir Dir Deine Buchungsinfos zuschicken können -
        keine Sorge, wir schicken Dir keine Newsletter zu! Für Rücksprachen und kurzfristige
        Änderungen melden wir uns telefonisch bei Dir.
      </Typography.Paragraph>

      {!hideEmail && (
        <Form.Item
          name="email"
          label="E-Mail"
          rules={[{ required: true, message: 'Bitte gib Deine E-Mail Adresse ein' }]}
        >
          <Input placeholder="Deine E-Mail Adresse" type="email" />
        </Form.Item>
      )}

      <Form.Item
        name="phone"
        label="Handynummer"
        rules={[{ required: true, message: 'Bitte gib Deine Handynummer ein' }]}
      >
        <Input placeholder="Deine Handynummer" />
      </Form.Item>

      <Form.Item
        name="emergencyPhone"
        label="Telefonnummer Notfallkontakt"
        extra="Nur für den Fall der Fälle - Sollen wir uns telefonisch bei jemandem melden, falls Dir etwas passiert?"
        rules={[{ required: false }]}
      >
        <Input placeholder="Telefonnummer" />
      </Form.Item>
    </Form>
  );
};

export default ContactInfo;
