import React, { useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button, Card, List, Space, Tabs, message, Empty } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faTrash, faCashRegister } from '@fortawesome/pro-light-svg-icons';
import GenericForm from '../components/form/GenericForm';
import CustomerForm, { FormFields } from '../components/customer/CustomerForm';
import {
  useDeleteCustomerAttendanceMutation,
  useDeleteCustomerMutation,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from '../graphql/schema';
import Loading from '../components/base/Loading';
import useRouteParamAsNumber from '../helper/hooks/useParamAsNumber';
import CustomerAttendanceDrawer from '../components/customer/CustomerAttendanceDrawer';
import confirmModal from '../helper/confirmModal';

const Customer = () => {
  const id = useRouteParamAsNumber('id');
  const navigate = useNavigate();

  const { data, loading, refetch } = useGetCustomerQuery({
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });

  const [update] = useUpdateCustomerMutation();
  const [deleteAttendance] = useDeleteCustomerAttendanceMutation();
  const [deleteCustomer] = useDeleteCustomerMutation();

  const [createAttendanceDrawerVisible, setCreateAttendanceDrawerVisible] =
    useState<boolean>(false);

  const handleSave = async (values: FormFields) => {
    await update({
      variables: {
        id,
        dto: values,
      },
    });
    message.success('Die Änderungen wurden gespeichert.');
  };

  const handleDelete = async () => {
    if (
      await confirmModal({
        title: `Kunden löschen`,
        content:
          'Bitte bestätige das Löschen. Dieser Schritt kann nicht rückgängig gemacht werden!',
        okText: `Kunden löschen`,
        okType: 'danger',
        cancelText: 'Abbrechen',
      })
    ) {
      await deleteCustomer({
        variables: {
          id,
        },
      });
      navigate('/admin/customers');
    }
  };

  const handleDeleteAttendance = (attendanceId: number) => {
    return async () => {
      if (
        await confirmModal({
          title: `Anwesenheit löschen`,
          content: 'Bitte bestätige das Löschen',
          okText: `Anwesenheit löschen`,
          okType: 'danger',
          cancelText: 'Abbrechen',
        })
      ) {
        await deleteAttendance({
          variables: {
            customerId: id,
            id: attendanceId,
          },
        });
        refetch();
      }
    };
  };

  if (loading || !data) return <Loading />;

  return (
    <>
      <GenericForm
        title={`${data.customer.firstName || ''} ${data.customer.lastName || ''}`}
        onSave={handleSave}
        onDelete={handleDelete}
        typeName="Kunde"
        extra={[
          <Button
            key="attendance"
            onClick={() => navigate(`/admin/openPayments/${id}`)}
            icon={<FontAwesomeIcon icon={faCashRegister} />}
          >
            Abrechnung erstellen
          </Button>,
          <Button
            key="attendance"
            onClick={() => setCreateAttendanceDrawerVisible(true)}
            icon={<FontAwesomeIcon icon={faCalendar} />}
          >
            Anwesenheit eintragen
          </Button>,
        ]}
        initialValues={{
          firstName: data.customer.firstName || '',
          lastName: data.customer.lastName || '',
          street: data.customer.street || '',
          streetNumber: data.customer.streetNumber || '',
          zipCode: data.customer.zipCode || '',
          city: data.customer.city || '',
          dateOfBirth: data.customer.dateOfBirth ? moment(data.customer.dateOfBirth) : undefined,
          email: data.customer.email || '',
          phone: data.customer.phone || '',
          emergencyPhone: data.customer.emergencyPhone || '',
          shoeSize: data.customer.shoeSize || '',
          bodyHeight: data.customer.bodyHeight || '',
          bodyWeight: data.customer.bodyWeight || '',
          kiteLevel: data.customer.kiteLevel || 0,
          comment: data.customer.comment || '',
          internalComment: data.customer.internalComment || '',
        }}
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Stammdaten" key="1">
            <CustomerForm />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Anwesenheiten" key="2">
            <Card>
              <List
                locale={{
                  emptyText: <Empty description="Keine Anwesenheiten gefunden" />,
                }}
                itemLayout="horizontal"
                dataSource={data.customer.attendances}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button key="delete" danger onClick={handleDeleteAttendance(item.id)}>
                        <Space>
                          <FontAwesomeIcon icon={faTrash} />
                          Löschen
                        </Space>
                      </Button>,
                    ]}
                  >
                    <Space>
                      <span>{moment(item.startDate).format('DD.MM.YYYY')}</span>
                      <span>-</span>
                      <span>{moment(item.endDate).format('DD.MM.YYYY')}</span>
                    </Space>
                  </List.Item>
                )}
              />
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Kurse" key="3">
            <Card>
              <List
                locale={{
                  emptyText: <Empty description="Keine Kurse gefunden" />,
                }}
                itemLayout="horizontal"
                dataSource={data.customer.courseAllocations}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={`${moment(item.course.startDate).format('DD.MM.YYYY')}: ${
                        item.course.title
                      }`}
                      description={item.comment}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Tabs.TabPane>
        </Tabs>
      </GenericForm>

      <CustomerAttendanceDrawer
        customerId={id}
        visible={createAttendanceDrawerVisible}
        onClose={() => setCreateAttendanceDrawerVisible(false)}
        onSave={refetch}
      />
    </>
  );
};

export default Customer;
