import React, { useState } from 'react';
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Space,
  Typography,
  DatePicker,
  Card,
  Avatar,
} from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import KiteLevelSelect from './kiteLevelSelect';

export interface KiteLevelFormFields {
  kiteLevel?: number;
}

interface Props {
  form: FormInstance<KiteLevelFormFields>;
}

const KiteLevel = ({ form }: Props) => {
  return (
    <Form layout="vertical" form={form}>
      <h1>Kannst Du schon kiten?</h1>
      <Typography.Paragraph type="secondary">
        Bitte hilf uns kurz Dein aktuelles Kitelevel einzuschätzen:
      </Typography.Paragraph>
      <Form.Item name="kiteLevel">
        <KiteLevelSelect />
      </Form.Item>
    </Form>
  );
};

export default KiteLevel;
