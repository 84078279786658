import React, { useState } from 'react';
import { PageHeader, List, Input, Card, Space, Statistic } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBaby, faCheck, faMapMarker, faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import Page from '../components/layout/Page';
import Content from '../components/layout/Content';
import { useGetCustomersWithOpenPaymentsQuery } from '../graphql/schema';
import usePagination from '../helper/hooks/usePagination';
import Loading from '../components/base/Loading';
import formatAmount from '../helper/formatAmount';

const OpenPayments = () => {
  const { data, loading } = useGetCustomersWithOpenPaymentsQuery({
    fetchPolicy: 'no-cache',
  });
  const [query, setQuery] = useState<string>('');

  if (loading) return <Loading />;

  return (
    <Page>
      <PageHeader title="Offene Abrechnungen" />
      <Content>
        <Card>
          <List
            pagination={false}
            itemLayout="horizontal"
            dataSource={data?.customersWithOpenPayments.filter(
              (item) =>
                query === '' ||
                (item.firstName &&
                  item.firstName.toLowerCase().indexOf(query.toLowerCase()) > -1) ||
                (item.lastName && item.lastName.toLowerCase().indexOf(query.toLowerCase()) > -1),
            )}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Link to={`/admin/openPayments/${item.id}`}>
                      {item.firstName} {item.lastName}
                    </Link>
                  }
                />
                <div>
                  {formatAmount(
                    item.courseAllocations.map((a) => a.amount || 0).reduce((sum, a) => sum + a, 0),
                  )}
                </div>
              </List.Item>
            )}
          />
        </Card>
      </Content>
    </Page>
  );
};

export default OpenPayments;
