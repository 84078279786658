import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import RegisterCustomer from './pages/RegisterCustomer';
import Customers from './pages/Customers';
import Customer from './pages/Customer';
import Error404 from './pages/Error404';
import CreateCustomer from './pages/CreateCustomer';
import OpenPayments from './pages/OpenPayments';
import OpenPayment from './pages/OpenPayment';
import Calendar from './pages/Calendar';
import Vouchers from './pages/Vouchers';
import Products from './pages/Products';
import CoursePlanning from './pages/CoursePlanning';
import Users from './pages/Users';
import useAuth from './auth/useAuth';
import Loading from './components/base/Loading';
import Login from './pages/Login';
import VerifyEmailAddress from './pages/VerifyEmailAddress';
import ActiveCustomers from './pages/ActiveCustomers';
import Instructors from './pages/Instructors';
import Instructor from './pages/Instructor';
import Spots from './pages/Spots';
import Contact from './widgets/Contact';
import Booking from './widgets/Booking';
import Dashboard from './pages/Dashboard';
import CreateInstructor from './pages/CreateInstructor';
import ProductCategories from './pages/ProductCategories';
import UseVoucher from './pages/UseVoucher';
import VoucherShop from './widgets/VoucherShop';
import Reviews from './widgets/Reviews';

function App() {
  const { authIsLoading, isAuthenticated } = useAuth();

  if (authIsLoading) return <Loading />;

  return (
    <Routes>
      <Route path="admin">
        {isAuthenticated ? (
          <Route element={<Layout />}>
            <Route element={<Dashboard />} />
            <Route element={<Customer />} path="customer/:id" />
            <Route element={<Customers />} path="customers" />
            <Route element={<ActiveCustomers />} path="" />
            <Route element={<CreateCustomer />} path="createCustomer" />
            <Route element={<OpenPayment />} path="openPayments/:id" />
            <Route element={<OpenPayments />} path="openPayments" />
            <Route element={<Calendar />} path="calendar" />
            <Route element={<Vouchers />} path="vouchers" />
            <Route element={<Products />} path="products" />
            <Route element={<ProductCategories />} path="productCategories" />
            <Route element={<Users />} path="users" />
            <Route element={<Instructors />} path="instructors" />
            <Route element={<Instructor />} path="instructor/:id" />
            <Route element={<CreateInstructor />} path="createInstructor" />
            <Route element={<Spots />} path="spots" />
            <Route element={<CoursePlanning />} path="coursePlanning" />
            <Route element={<Error404 />} path="*" />
          </Route>
        ) : (
          <>
            <Route element={<Login />} path="" />
            <Route element={<Login />} path="*" />
          </>
        )}
      </Route>
      <Route element={<RegisterCustomer />} path="registerCustomer" />
      <Route element={<UseVoucher />} path="voucher/:code" />
      <Route element={<VerifyEmailAddress />} path="verifyEmailAddress" />
      <Route path="widget">
        <Route element={<Contact />} path="contact" />
        <Route element={<Booking />} path="booking" />
        <Route element={<VoucherShop />} path="voucherShop" />
        <Route element={<Reviews />} path="reviews" />
      </Route>
    </Routes>
  );
}

export default App;
