import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericForm from '../components/form/GenericForm';
import InstructorForm, { FormFields } from '../components/instructor/InstructorForm';
import { useCreateInstructorMutation } from '../graphql/schema';

const CreateInstructor = () => {
  const navigate = useNavigate();
  const [create] = useCreateInstructorMutation();

  const handleSave = async (values: FormFields) => {
    const customer = await create({
      variables: {
        dto: values,
      },
    });
    navigate(`/admin/instructor/${customer.data?.createInstructor.id}`);
  };

  return (
    <GenericForm<FormFields> title="Neuer Lehrer" onSave={handleSave} typeName="Lehrer">
      <InstructorForm />
    </GenericForm>
  );
};

export default CreateInstructor;
