import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../components/layout/Page';
import Loading from '../components/base/Loading';

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/admin/customers');
  }, []);

  return (
    <Page>
      <Loading />
    </Page>
  );
};

export default Dashboard;
