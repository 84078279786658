import React, { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Form, Result, Row, Space } from 'antd';
import { PageProps } from '../lib/pageProps';

const ConfirmationPage = ({ navigate, setState, state }: PageProps) => {
  return (
    <div>
      <Result
        status="success"
        title="Willkommen an Bord & vielen Dank für Deine Buchung!"
        subTitle={
          <div>
            Wir melden uns in Kürze bei Dir mit weiteren Informationen.
            <br />
            Wenn Du in der Zwischenzeit Fragen hast, melde Dich gerne jederzeit bei uns!
          </div>
        }
      />
    </div>
  );
};
export default ConfirmationPage;
