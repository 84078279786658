import React from 'react';
import { Layout } from 'antd';
import styles from './BasicLayout.module.less';
import Logo from './Logo';

interface Props {
  children?: React.ReactNode;
}

const BasicLayout = ({ children }: Props) => {
  return (
    <Layout className={styles.container}>
      <Logo styling="primary" />

      <Layout.Content className={styles.content}>{children}</Layout.Content>
    </Layout>
  );
};

export default BasicLayout;
