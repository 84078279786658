import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Checkbox, Col, Form, Row, Space } from 'antd';
import Meta from 'antd/es/card/Meta';
import { faCcMastercard, faCcVisa, faCcApplePay } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageProps } from '../lib/pageProps';
import {
  useGetProductsForBookingQuery,
  useRegisterCustomerMutation,
  useUpdateCustomerWithTokenMutation,
} from '../../../graphql/schema';
import Loading from '../../../components/base/Loading';
import formatAmount from '../../../helper/formatAmount';
import checkboxValidator from '../../../helper/checkboxValidator';
import { Page } from '../lib/pageEnum';

interface FormFields {
  terms?: boolean;
  privacy?: boolean;
  newsletter?: boolean;
}

enum PaymentMethod {
  online,
  offline,
}

const CheckoutPage = ({ navigate, setState, state }: PageProps) => {
  const [form] = Form.useForm<FormFields>();
  const { loading: productsLoading, data } = useGetProductsForBookingQuery();

  const [registerCustomer] = useRegisterCustomerMutation();
  const [updateCustomer] = useUpdateCustomerWithTokenMutation();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.offline);

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('customerToken');

      const values = await form.validateFields();

      setLoading(true);

      const customerData = {
        firstName: state.customer?.firstName || '',
        lastName: state.customer?.lastName || '',
        street: state.customer?.street || '',
        streetNumber: state.customer?.streetNumber || '',
        zipCode: state.customer?.zipCode || '',
        city: state.customer?.city || '',
        phone: state.customer?.phone || '',
        emergencyPhone: state.customer?.emergencyPhone || '',
        shoeSize: state.customer?.shoeSize || '',
        bodyHeight: state.customer?.bodyHeight || '',
        bodyWeight: state.customer?.bodyWeight || '',
        comment: state.customer?.comment || '',
        dateOfBirth: state.customer?.dateOfBirth || '',
        ownGear: state.customer?.ownGear || false,
        newsletter: values.newsletter || false,
      };

      let response = null;

      const redirectURL = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}?page=${Page.payment}`;

      if (state.customer.id && token) {
        // Existing customer
        response = (
          await updateCustomer({
            variables: {
              dto: {
                ...customerData,
                attendance: {
                  startDate: state.startDate,
                  endDate: state.endDate,
                  desiredProductId: state.productId,
                },
                createBooking: paymentMethod !== PaymentMethod.offline,
                redirectURL,
              },
              token,
            },
          })
        ).data?.updateCustomerWithToken;
      } else {
        // New customer
        response = (
          await registerCustomer({
            variables: {
              dto: {
                email: state.email || '',
                kiteLevel: state.customer?.kiteLevel || 0,
                ...customerData,
                attendance: {
                  startDate: state.startDate,
                  endDate: state.endDate,
                  desiredProductId: state.productId,
                },
                createBooking: paymentMethod !== PaymentMethod.offline,
                redirectURL,
              },
            },
          })
        ).data?.registerCustomer;

        if (response?.customerToken) localStorage.setItem('customerToken', response.customerToken);
        if (response?.customerId)
          setState({
            customer: {
              ...state.customer,
              id: response.customerId,
            },
          });
      }

      if (paymentMethod !== PaymentMethod.offline) {
        localStorage.setItem('paymentId', (response as any).paymentId);
        window.location.href = (response as any).paymentLink;
        return;
      }

      navigate(Page.confirmation);
    } catch (e) {
      console.log('ERRR');
      // TODO
    }
    setLoading(false);
  };

  const getPaymentMethodContainer = (method: { badge?: string }, children: any) => {
    if (method.badge)
      return (
        <Badge.Ribbon text={method.badge} color="green">
          {children}
        </Badge.Ribbon>
      );
    return children;
  };

  if (productsLoading || loading) return <Loading />;

  const product = data?.publicProductsForBooking.find((p) => p.id === state.productId);
  if (!product) return <Loading />;

  return (
    <div>
      <Row>
        <Col xs={20} sm={8} offset={2}>
          <Card cover={<img alt={product.title} src={product.image} />}>
            <Meta title={product.title} description={product.description} />
            <div
              style={{
                fontSize: 20,
                paddingTop: 10,
              }}
            >
              {formatAmount(product.price)}
            </div>
          </Card>
          <br />
        </Col>

        <Col xs={24} sm={12} offset={2}>
          <h1>Deine Buchung</h1>
          <Row>
            {[
              {
                label: 'Vorname',
                value: state.customer?.firstName,
              },
              {
                label: 'Nachname',
                value: state.customer?.lastName,
              },
              {
                label: 'Straße/Hausnr.',
                value: `${state.customer?.street} ${state.customer?.streetNumber}`,
              },
              {
                label: 'PLZ/Ort',
                value: `${state.customer?.zipCode} ${state.customer?.city}`,
              },
              {
                label: 'E-Mail',
                value: state.email,
              },
              {
                label: 'Telefon',
                value: state.customer?.phone,
              },
            ].map(({ label, value }) => (
              <>
                <Col
                  sm={8}
                  xs={24}
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 5,
                  }}
                >
                  {label}:
                </Col>
                <Col
                  xs={24}
                  sm={16}
                  style={{
                    marginBottom: 5,
                  }}
                >
                  {value}
                </Col>
              </>
            ))}
          </Row>

          <br />
          <h2>Wie möchtest Du bezahlen?</h2>

          <Row
            style={{
              justifyContent: 'space-between',
            }}
          >
            {[
              {
                paymentMethod: PaymentMethod.online,
                label: 'Jetzt online bezahlen',
                description: (
                  <Space
                    style={{
                      fontSize: 20,
                    }}
                  >
                    <FontAwesomeIcon icon={faCcMastercard as any} />
                    <FontAwesomeIcon icon={faCcVisa as any} />
                    <FontAwesomeIcon icon={faCcApplePay as any} />
                  </Space>
                ),
                badge: '10% Rabatt',
              },
              {
                paymentMethod: PaymentMethod.offline,
                label: 'Vor Ort',
                description: 'EC- & Kreditkarten möglich',
              },
            ].map((method) => (
              <Col
                xs={12}
                key={method.paymentMethod}
                style={{
                  paddingRight: 20,
                  flex: 1,
                  display: 'flex',
                  width: '50%',
                }}
              >
                {getPaymentMethodContainer(
                  method,
                  <Card
                    style={{
                      border:
                        method.paymentMethod === paymentMethod ? '1px solid #179AB4' : undefined,
                      cursor: 'pointer',
                      width: '100%',
                    }}
                    onClick={() => setPaymentMethod(method.paymentMethod)}
                  >
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                      }}
                    >
                      {method.label}
                    </div>
                    <div
                      style={{
                        color: '#666',
                        height: 25,
                      }}
                    >
                      {method.description}
                    </div>
                  </Card>,
                )}
              </Col>
            ))}
          </Row>

          <br />
          <br />
          <Form layout="vertical" form={form}>
            <Form.Item name="newsletter" rules={[{ required: false }]} valuePropName="checked">
              <Checkbox>
                Ich möchte per E-Mail über Neuigkeiten zu Rückenwind Kitesurf & Kiteevents
                informiert werden
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="privacy"
              rules={[
                {
                  required: true,
                  message:
                    'Um Deine Buchung abzuschließen, musst Du unsere Datenschutzinformationen akzeptieren',
                  validator: checkboxValidator,
                },
              ]}
              valuePropName="checked"
            >
              <Checkbox>
                Ich habe die{' '}
                <a
                  rel="noreferrer"
                  href="https://rueckenwind-kitesurf.de/datenschutz"
                  target="_blank"
                >
                  Datenschutzinformationen
                </a>{' '}
                gelesen und akzeptiere sie.
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="terms"
              rules={[
                {
                  required: true,
                  message: 'Bitte akzeptiere unsere AGB',
                  validator: checkboxValidator,
                },
              ]}
              valuePropName="checked"
            >
              <Checkbox>
                Ich erkläre mich mit den{' '}
                <a rel="noreferrer" href="https://rueckenwind-kitesurf.de/agb" target="_blank">
                  Allgemeinen Geschäftsbedingungen
                </a>{' '}
                einverstanden
              </Checkbox>
            </Form.Item>
          </Form>
          <div
            style={{
              textAlign: 'left',
            }}
          >
            <Button type="primary" size="large" onClick={handleSubmit}>
              Jetzt kostenpflichtig buchen
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default CheckoutPage;
