import React, { ReactElement } from 'react';
import { Button, Drawer, Form, notification } from 'antd';

export interface Props<FormFieldsType> {
  visible: boolean;
  onClose: () => void;
  onSave: (values: FormFieldsType) => void;
  title: string;
  children: ReactElement;
}

function GenericAddDrawer<FormFieldsType>({
  visible,
  onClose,
  onSave,
  title,
  children,
}: Props<FormFieldsType>) {
  const [form] = Form.useForm<FormFieldsType>();

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      await onSave(values);
      onClose();
      form.resetFields();
    } catch (e) {
      notification.error({
        message: 'Fehler',
        description: 'Beim Speichern ist ein Fehler aufgetreten, bitte versuche es noch einmal.',
      });
    }
  };

  return (
    <Drawer title={title} visible={visible} onClose={onClose}>
      <Form layout="vertical" form={form}>
        {children}
      </Form>
      <Button type="primary" onClick={handleSave}>
        Anlegen
      </Button>
    </Drawer>
  );
}

export default GenericAddDrawer;
