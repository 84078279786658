import React from 'react';
import { Col, Form, Input, List, Row } from 'antd';
import {
  GetProductCategoriesQuery,
  ProductCategoryFragment,
  useCreateProductCategoryMutation,
  useDeleteProductCategoryMutation,
  useGetProductCategoriesLazyQuery,
  useUpdateProductCategoryMutation,
} from '../graphql/schema';
import GenericCrudList from '../components/crud/GenericCrudList';

interface FormFields {
  name: string;
  uid: string;
}

const ProductCategories = () => {
  const [create] = useCreateProductCategoryMutation();
  const [update] = useUpdateProductCategoryMutation();
  const [remove] = useDeleteProductCategoryMutation();

  const handleCreate = async (values: Partial<FormFields>) => {
    await create({
      variables: {
        dto: {
          name: values.name || '',
          uid: values.uid || '',
        },
      },
    });
  };

  const handleUpdate = async (id: number, values: Partial<FormFields>) => {
    await update({
      variables: {
        id,
        dto: {
          name: values.name || '',
          uid: values.uid || '',
        },
      },
    });
  };

  const handleDelete = async (id: number) => {
    await remove({
      variables: {
        id,
      },
    });
  };

  const form = () => {
    return (
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="uid" label="Eindeutige ID" rules={[{ required: true }]}>
            <Input placeholder="ID" />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <GenericCrudList<FormFields, ProductCategoryFragment, GetProductCategoriesQuery>
      title="Produktkategorien"
      singleItemTitle="Produktkategorie"
      query={useGetProductCategoriesLazyQuery}
      dataKey="productCategories"
      editForm={form}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      renderItem={(item, onSelect) => (
        <List.Item>
          <List.Item.Meta title={<a onClick={onSelect}>{item.name}</a>} />
        </List.Item>
      )}
    />
  );
};

export default ProductCategories;
