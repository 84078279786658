import React from 'react';
import { Moment } from 'moment';
import { Button, Col, DatePicker, Drawer, Form, notification, Row } from 'antd';
import locale from 'antd/es/date-picker/locale/de_DE';
import { useCreateCustomerAttendanceMutation } from '../../graphql/schema';

export interface Props {
  customerId: number;
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
}

interface FormFieldsType {
  startDate: Moment;
  endDate: Moment;
}

const CustomerAttendanceDrawer = ({ customerId, visible, onClose, onSave }: Props) => {
  const [form] = Form.useForm<FormFieldsType>();

  const [create] = useCreateCustomerAttendanceMutation();

  const handleSave = async () => {
    try {
      const values = await form.validateFields();

      await create({
        variables: {
          id: customerId,
          dto: {
            startDate: values.startDate,
            endDate: values.endDate,
          },
        },
      });

      form.resetFields();

      onSave();
      onClose();
    } catch (e) {
      notification.error({
        message: 'Fehler',
        description: 'Beim Speichern ist ein Fehler aufgetreten, bitte versuche es noch einmal.',
      });
    }
  };

  return (
    <Drawer title="Anwesenheit eintragen" visible={visible} onClose={onClose}>
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="startDate" label="Von" rules={[{ required: true }]}>
              <DatePicker
                format="DD.MM.YYYY"
                placeholder="Datum auswählen"
                locale={locale}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="endDate" label="Bis" rules={[{ required: true }]}>
              <DatePicker
                format="DD.MM.YYYY"
                placeholder="Datum auswählen"
                locale={locale}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button onClick={handleSave}>Speichern</Button>
      </Form>
    </Drawer>
  );
};

export default CustomerAttendanceDrawer;
