import React from 'react';

export enum LoginResult {
  successful,
  failed,
}

interface AuthContextProps {
  authIsLoading: boolean;
  isAuthenticated: boolean;
  getAuthToken: () => Promise<string>;
  signIn: (username: string, password: string) => Promise<LoginResult>;
  logout: () => void;
  roles: string[];
}

export const AuthContext = React.createContext<AuthContextProps>({
  authIsLoading: true,
  isAuthenticated: false,
  signIn: async () => LoginResult.failed,
  logout: () => null,
  getAuthToken: async () => '',
  roles: [],
});
