import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericForm from '../components/form/GenericForm';
import CustomerForm, { FormFields } from '../components/customer/CustomerForm';
import { useCreateCustomerMutation } from '../graphql/schema';

const CreateCustomer = () => {
  const navigate = useNavigate();
  const [create] = useCreateCustomerMutation();

  const handleSave = async (values: FormFields) => {
    const customer = await create({
      variables: {
        dto: values,
      },
    });
    navigate(`/admin/customer/${customer.data?.createCustomer.id}`);
  };

  return (
    <GenericForm<FormFields> title="Neuer Kunde" onSave={handleSave} typeName="Kunde">
      <CustomerForm />
    </GenericForm>
  );
};

export default CreateCustomer;
